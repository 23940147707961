import { ReactComponent as FolderIcon } from '../icons/folder.svg';
import { ReactComponent as PlusIcon } from '../icons/plus.svg';
import { ReactComponent as QuestionIcon } from '../icons/question.svg';
import Project from './Project';
import { useEffect, useState, useRef, useContext } from 'react';
import { SERVER_URL } from '../url.config';
import axios from 'axios';
import PresentationsList from './PresentationsList';
import MainNav from './MainNav';
import { UserContext } from '../App';
import { toast } from 'react-toastify';
import Notification from './notifications/Notification';
import { notificationIcons } from './notifications/notificationIcons';
import WelcomeMessage from './WelcomeMessage';

const Dashboard = () => {
  const { user } = useContext(UserContext);
  const [projects, setProjects] = useState([]);
  const [addNewProjectVisible, setAddNewProjectVisible] = useState(false);
  const newProjectInputRef = useRef();
  const [newProjectTitle, setNewProjectTitle] = useState('');
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedStack, setSelectedStack] = useState(undefined);
  const [freePlan, setFreePlan] = useState(undefined);
  const [stackId, setStackId] = useState(undefined);
  const [freePlanProjectsSet, setFreePlanProjectsSet] = useState(false);
  const [isNotAllowed, setIsNotAllowed] = useState(false);
  const [projectAdded, setProjectAdded] = useState(false);
  const [newProjectNotAllowed, setNewProjectNotAllowed] = useState(false);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
  const [showWelcomeMessageOnClick, setShowWelcomeMessageOnClick] = useState(false);
  const [newProjectTooltipVisible, setNewProjectTooltipVisible] = useState(false);
  const [userAccessed, setUserAccessed] = useState(false);

  //check user's subscription plan
  useEffect(() => {
    if (user && !user.endDateSubscription) {
      setFreePlan(true);
    }

    if (user && user.endDateSubscription < new Date()) {
      setFreePlan(false);
    }

    if (user && user.endDateSubscription === 'null') {
      setFreePlan(true);
    }
  }, [user]);

  //check for showing welcome message only once per session
  useEffect(() => {
    const accessed = sessionStorage.getItem('accessed');
    if (accessed === 'yes') {
      setUserAccessed(true);
    } else setUserAccessed(false);
  }, []);

  // useEffect(() => {
  //     if (projectAdded && freePlan) {
  //         setNewProjectNotAllowed(true);
  //     }
  // }, [projectAdded, freePlan]);

  useEffect(async () => {
    await getSelectedStack(user.id).then((r) => {
      // set initial selected Project for initial opening
      projects?.map((item, index) => {
        item?.stack?.map((x, i) => {
          if (freePlanProjectsSet && r?.stackId === x?._id) {
            setSelectedProject(item);
          }

          if (r?.stackId === x?._id) {
            setSelectedProject(item);
          }
        });
      });
    });
  }, [user, freePlanProjectsSet]);

  useEffect(() => {
    return () => {
      getSelectedStack(user.id);
    };
  }, []);

  const getSelectedStack = async (id) => {
    if (id) {
      try {
        const res = await axios.get(`${SERVER_URL}/selected-stack/${id}`);
        const item = res.data.data;
        setStackId(item.stackId);
        setSelectedStack(item);
        localStorage.setItem('selectedStack', JSON.stringify(item));
        localStorage.setItem('selectedProjectId', JSON.stringify(res.data.projectId));
        if (res.data.data.data.title === 'My First Presentation' && user.numberOfLogs < 2) {
          // if (res.data.data.data.title === 'My First Presentation') {
          setShowWelcomeMessageOnClick((previous) => !previous);
        }
        return item;
      } catch (err) {}
    }
  };

  const fetchAllProjects = async () => {
    try {
      const res = await axios.get(`${SERVER_URL}/projects/projects-all/${user.id}`);
      //check if it's still First autogenerated project
      if (res.data.length === 0) {
        setShowWelcomeMessageOnClick((previous) => !previous);
      }

      if (res.data.length === 1 && res.data[0].data.title === 'My First Project') {
        // setShowWelcomeMessageOnClick(true)
      }

      //check user's plan
      //if plan is free
      //then show only last updated project
      if (freePlan && res.data.length >= 1) {
        setProjectAdded(true);
      }

      if (freePlan && stackId) {
        const array = [];
        res.data.map((item) => {
          item.stack.map((i) => {
            if (stackId === i._id) {
              item.stack.map((x) => {
                if (stackId === x._id) {
                  array.push(item);
                }
              });
            }
          });
        });

        setProjects(array);
        setFreePlanProjectsSet(true);
        //show toast message only once per session
        // if (!userAccessed) {
        //   toast.error(
        //     <Notification message='Free plan allows you to create only one project. Please upgrade your plan to PRO to get full access.' />,
        //     {
        //       icon: notificationIcons.error,
        //       closeButton: notificationIcons.close,
        //       toastId: 'custom-id-free-plan-notification',
        //       onClose: handleOnClose
        //     }
        //   );

        // setTimeout(() => {
        //     toast.dismiss('custom-id-free-plan-notification'); // Dismiss the toast by its ID
        // }, 2000);
        // }
      } else {
        //set projects if plan is pro
        setProjects(res.data);
      }
    } catch (err) {}
  };

  //show toast message only once per session
  const handleOnClose = () => {
    sessionStorage.setItem('accessed', 'yes');
  };

  const addNewProject = async () => {
    if (!newProjectTitle) {
      return setAddNewProjectVisible(false);
    }
    try {
      await axios.post(`${SERVER_URL}/projects/${user.id}`, { data: { title: newProjectTitle } });
      setNewProjectTitle('');
      setAddNewProjectVisible(false);
      setProjectAdded(true);
      fetchAllProjects();
    } catch (err) {
      toast.error(<Notification message={err.response.data.message} />, {
        icon: notificationIcons.error,
        closeButton: notificationIcons.close,
        toastId: 'custom-id-free-plan-notification',
        onClose: handleOnClose
      });

      setNewProjectTitle('');
      setProjectAdded(false);
      fetchAllProjects();

      setTimeout(() => {
        toast.dismiss('custom-id-free-plan-notification'); // Dismiss the toast by its ID
      }, 2000);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addNewProject();
  };

  useEffect(() => {
    fetchAllProjects();
  }, [user, freePlan, stackId]);

  useEffect(() => {
    if (addNewProjectVisible && newProjectInputRef.current) {
      newProjectInputRef.current.focus();
    }
  }, [addNewProjectVisible]);

  const handleSubmitNewProjectOnClickOutside = (e) => {
    if (newProjectInputRef.current && !newProjectInputRef.current.contains(e.target)) {
      addNewProject();
    }
  };

  useEffect(() => {
    if (addNewProjectVisible) {
      document.addEventListener('click', handleSubmitNewProjectOnClickOutside);
      document.addEventListener('contextmenu', handleSubmitNewProjectOnClickOutside);

      return () => {
        document.removeEventListener('click', handleSubmitNewProjectOnClickOutside);
        document.removeEventListener('contextmenu', handleSubmitNewProjectOnClickOutside);
      };
    }
  });

  //this isNotAllowed state is set for not displaying action buttons on dashboard page
  useEffect(() => {
    if (freePlan && freePlanProjectsSet && projects.length >= 1) {
      setIsNotAllowed(true);
    }
  }, [projects, freePlanProjectsSet, freePlan]);

  const clickShoWelcomeMessage = () => {
    sessionStorage.removeItem('accessed');
    setShowWelcomeMessageOnClick(true);
    // setShowWelcomeMessageOnClick(previous => !previous)
  };

  //check for showing welcome message only once per session
  useEffect(() => {
    const accessed = sessionStorage.getItem('accessed');
    if (accessed === 'yes') {
      setUserAccessed(true);
    } else setUserAccessed(false);
  }, [showWelcomeMessageOnClick]);

  return (
    <div className='dashboard'>
      <MainNav />
      <div className='dashboard__content'>
        <div className='left-sidebar'>
          <div className='left-sidebar__header'>
            <FolderIcon />
            <span>Projects</span>
          </div>
          <div className='left-sidebar__content'>
            <div className='left-sidebar__projects'>
              {projects.map((project) => (
                <Project
                  key={project._id}
                  project={project}
                  selectedProject={selectedProject}
                  setSelectedProject={setSelectedProject}
                  selectedStack={selectedStack}
                  setSelectedStack={setSelectedStack}
                  SERVER_URL={SERVER_URL}
                  setProjects={setProjects}
                  freePlan={freePlan}
                  isNotAllowed={isNotAllowed}
                  newProjectNotAllowed={newProjectNotAllowed}
                  setShowWelcomeMessage={setShowWelcomeMessage}
                />
              ))}
              {addNewProjectVisible && (
                <form className='left-sidebar__project-title-form' onSubmit={handleSubmit}>
                  <input
                    ref={newProjectInputRef}
                    type='text'
                    placeholder='Enter project title'
                    className='left-sidebar__project-title-input p-s-bold'
                    value={newProjectTitle}
                    onChange={(e) => setNewProjectTitle(e.target.value)}
                  />
                </form>
              )}
            </div>
            <div
              className={
                newProjectNotAllowed
                  ? 'left-sidebar__add-new-project left-sidebar__add-new-project--disabled'
                  : 'left-sidebar__add-new-project'
              }
              onClick={() => {
                if (!newProjectNotAllowed) {
                  setAddNewProjectVisible(true);
                }
              }}
              onMouseEnter={() => {
                if (newProjectNotAllowed) setNewProjectTooltipVisible(true);
              }}
              onMouseLeave={() => setNewProjectTooltipVisible(false)}
              key={Math.random()}>
              <PlusIcon />
              <span>Add new project</span>
              {newProjectTooltipVisible && (
                <div className='tooltip-container'>
                  <div className='tooltip-content'>
                    <p>
                      You can’t add more projects because you are on Free plan and it grants one
                      project at time.
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='main-content'>
          {showWelcomeMessageOnClick && freePlan && !userAccessed ? (
            <WelcomeMessage
              showWelcomeMessageOnClick={showWelcomeMessageOnClick}
              setShowWelcomeMessageOnClick={setShowWelcomeMessageOnClick}
              isClosableOnClick={true}
            />
          ) : showWelcomeMessage && selectedStack === undefined && freePlan && !userAccessed ? (
            <WelcomeMessage
              showWelcomeMessageOnClick={showWelcomeMessageOnClick}
              setShowWelcomeMessageOnClick={setShowWelcomeMessageOnClick}
              isClosableOnClick={true}
            />
          ) : (
            selectedStack && (
              <>
                <PresentationsList
                  SERVER_URL={SERVER_URL}
                  stackId={selectedStack ? selectedStack._id : ''}
                  selected={selectedStack ? selectedStack : {}}
                  isNotAllowed={isNotAllowed}
                  newProjectNotAllowed={newProjectNotAllowed}
                  showWelcomeMessage={showWelcomeMessage}
                  setShowWelcomeMessage={setShowWelcomeMessage}
                />

                <div className='main-content__footer'>
                  <div className='btn btn--rect-lg' onClick={clickShoWelcomeMessage}>
                    <div className='btn__content'>
                      <QuestionIcon />
                    </div>
                  </div>
                </div>
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
