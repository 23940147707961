import React, { useState, useEffect, useCallback } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import SlideContent from "./SlideContent";

const Slide = React.forwardRef(({ 
    setEditingShapeElement,
    setZoom, 
    initialZoom, 
    screenSize, 
    // insertMenuCoords, 
    // setInsertMenuCoords,  
    slides, 
    selectedSlide, 
    setSelectedSlide ,
    dispatch,
    selectedElement,
    selectedElements,
    setSelectedElement,
    setSelectedElements,
    target,
    setTarget,
    targets,
    setTargets,
    addElement,
    editorStyles,
    setEditorStyles,
    updateEditorStyles,
    deleteEditorStyles
}, ref) => {

    const [isMovable, setIsMovable] = useState(false)  
    const [zoomDisabled, setZoomDisabled] = useState(false) 
    useEffect(() => {
        if (ref.current) {
            ref.current.resetTransform()
            // ref.current.setTransform(0, 0, initialZoom)
            // ref.current.centerView(initialZoom)
        }
        
    }, [initialZoom])

    // OVAJ BUTTON ZA SADA NIJE U IGRI
    // const handleClickOnAddBtn = (e) => {
    //     if (insertMenuCoords) {
    //         setInsertMenuCoords(null)
    //     } else {
    //         // POSTAVITI INSERTMENUCOORDS SAMO JE PITANJE GDE TACNO ???

    //         // console.log(slideRef.current.getBoundingClientRect())
    //         // const slideRect = slideRef.current.getBoundingClientRect()
    //         // const x = (e.clientX - slideRect.left) * 1 / ref.current.state.scale
    //         // const y = (e.clientY - slideRect.top) * 1 / ref.current.state.scale
    //         // setInsertMenuCoords([x, y])
    //     }
    // }

    const setIsMovableMemoed = useCallback((value) => setIsMovable(value), [])
    return (  
        <>
        {initialZoom &&
        
        <TransformWrapper
            ref={ref}
            initialScale={initialZoom}
            initialPositionX={0}
            initialPositionY={0}
            panning={{ disabled: isMovable }}
            minScale={0.2}
            wheel={{ disabled: false, step: 0.02, activationKeys: ['Shift'] }}
            zoomIn={{ step: 0.02 }}
            zoomOut={{ step: 0.02 }}
            onZoom={(ReactZoomPanPinchRef) => {
                setZoom(ReactZoomPanPinchRef.state.scale)
            }}
            // centerOnInit={true}
            centerZoomedOut={true}
            doubleClick={{ disabled: true }}
            disabled={zoomDisabled}
        >
            
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                <>
                    <TransformComponent
                        wrapperStyle={{ width: `${initialZoom * screenSize.width}px`, height: `${initialZoom * screenSize.height}px`, overflow: 'visible'}}
                    >
                        {slides && slides.length > 0 && slides.map((slide, index) => {
                            return (
                                <SlideContent
                                    key={slide.id}
                                    slide={slide}
                                    screenSize={screenSize}
                                    selectedSlide={selectedSlide}
                                    setSelectedSlide={setSelectedSlide}
                                    dispatch={dispatch}
                                    isMovable={isMovable}
                                    setIsMovableMemoed={setIsMovableMemoed}
                                    // setIsMovable={setIsMovable}
                                    setEditingShapeElement={setEditingShapeElement}
                                    selectedElement={selectedElement}
                                    setSelectedElement={setSelectedElement}
                                    setSelectedElements={setSelectedElements}
                                    selectedElements={selectedElements}
                                    target={target}
                                    setTarget={setTarget}
                                    targets={targets}
                                    setTargets={setTargets}
                                    setZoomDisabled={setZoomDisabled}
                                    addElement={addElement}
                                    editorStyles={editorStyles}
                                    setEditorStyles={setEditorStyles}
                                    updateEditorStyles={updateEditorStyles}
                                    deleteEditorStyles={deleteEditorStyles}
                                />
                            )
                        })}
                        
                    </TransformComponent>
                </>
            )}
            
        </TransformWrapper>
        }
        </>
    );
})
 
export default Slide;