import { useState, useRef, useEffect } from "react";
import  { ReactComponent as TrashIcon } from '../icons/trash-2.svg'

const RemoveCustomFormatBtn = ({ format, handleDelete }) => {
    const [confirmVisible, setConfirmVisible] = useState(false);
    const popupRef = useRef()

    useEffect(() => {
        if (confirmVisible) {
            const handleClickOutside = (e) => {
                if (popupRef.current && !popupRef.current.contains(e.target)) {
                    setConfirmVisible(false)
                }
            }

            document.addEventListener('click', handleClickOutside)
            return () => document.removeEventListener('click', handleClickOutside)
        }
    })

    return (  
        <div 
            className="unsplash-modal__remove-format-btn"
            onClick={() => setConfirmVisible(true)}    
        >
            <TrashIcon className="unsplash-modal__remove-format-btn-text" />
            {confirmVisible &&
                <div
                    ref={popupRef} 
                    className="unsplash-modal__confirm-remove">
                    <span>Delete {format.title} ?</span>
                    <div className="unsplash-modal__confirm-remove-buttons">
                        <span 
                            className="unsplash-modal__confirm-remove-btn"
                            onClick={(e) => {
                                e.stopPropagation()
                                setConfirmVisible(false)}}    
                        >
                            Cancel
                        </span>
                        <span 
                            className="unsplash-modal__confirm-remove-btn"
                            onClick={(e) => {
                                e.stopPropagation()
                                handleDelete(format.title)
                                setConfirmVisible(false)
                            }}
                        >
                            Ok
                        </span>
                    </div>
                </div>
            }
        </div> 
    );
}
 
export default RemoveCustomFormatBtn;