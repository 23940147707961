import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { ReactComponent as CloseIcon } from '../icons/close-ins.svg';
import  { ReactComponent as EmailIcon } from '../icons/at-sign-2-1.svg';
import  { ReactComponent as UserIcon } from '../icons/user-1.svg';
import  { ReactComponent as WorldIcon } from '../icons/world-1.svg';
import  { ReactComponent as StripeIcon } from '../icons/securePayment.svg';
import  { ReactComponent as Logo } from '../icons/logo-icon.svg'
import { useForm } from 'react-hook-form';
import { getNames } from 'country-list';
import axios from 'axios';
import LoadingSpinner from './spinner/LoadingSpinner';
import Notification from './notifications/Notification';
import { notificationIcons } from './notifications/notificationIcons';

const CARD_OPTIONS = {
	iconStyle: "solid",
	style: {
		base: {
			iconColor: "#7881A7",
			color: '#000',
			fontWeight: 100,
			fontFamily: 'Mulish, sans-serif',
			fontSize: "16px",
			fontSmoothing: "antialiased",
			":-webkit-autofill": { color: "#fce883" },
			"::placeholder": { color: "#7881A7", fontWeight: 100 }
		},
		invalid: {
			iconColor: "#ff0000",
			color: "#ff0000"
		}
	}
}

const ACCOUNT_PLANS = {
  ACCOUNT_PLAN_FREE: 'free',
  ACCOUNT_PLAN_PRO: 'pro'
}

const PAYMENT_TERMS = {
  PAYMENT_TERM_MONTH: 'monthly',
  PAYMENT_TERM_YEARLY: 'yearly'
}

const PAYMENT_AMOUNT = {
  PAYMENT_AMOUNT_MONTH: 4,
  PAYMENT_AMOUNT_YEARLY: 43,
}

const PaymentForm = ({isPaymentModalOpen, setIsPaymentModalOpen, accountPlan, paymentTerm, setSubEndDate}) => {
  const countries = getNames()
  const stripe = useStripe();
  const elements = useElements();
  const [costOfSubscription, setCostOfSubscription] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  let user = sessionStorage.getItem('user');
  user = JSON.parse(user);

  useEffect(() => {
    if (accountPlan === ACCOUNT_PLANS.ACCOUNT_PLAN_PRO) {
      if (paymentTerm === PAYMENT_TERMS.PAYMENT_TERM_MONTH) {
        setCostOfSubscription(Number(PAYMENT_AMOUNT.PAYMENT_AMOUNT_MONTH));
      } else {
        setCostOfSubscription(Number(PAYMENT_AMOUNT.PAYMENT_AMOUNT_YEARLY));
      }
    }
   }, [accountPlan, paymentTerm]);

  const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm();

  const onSubmit = async (formData, e) => {
    e.preventDefault();
    setIsLoading(true)
    const cardElement = elements?.getElement(CardElement);

    if (!stripe || !elements || !cardElement) {
      setIsLoading(false)
      toast.error(<Notification message='Stripe error!' />, {
        icon: notificationIcons.error,
        autoDismissTimeout: 1000,
        closeButton: notificationIcons.close
      })
      return;
    }

    try {
      const stripeResponse = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement
      });
      
      const { error, paymentMethod } = stripeResponse;

      if (error || !paymentMethod) {
        setIsLoading(false)
        toast.error(<Notification message={error.message} />, {
          icon: notificationIcons.error,
          closeButton: notificationIcons.close
        })
        return;
      }

      const paymentMethodId = paymentMethod.id;
      const data = {
        paymentMethodId,
        amount: costOfSubscription * 100,
        userStripe: user.stripeCustomerId,
        accountPlan: accountPlan,
        paymentTerm: paymentTerm,
        userId: user.id,
      };
      try {
        const res = await axios.post(`${process.env.REACT_APP_API_PROD_URL}/stripe`, {
              // const res = await axios.post(`${process.env.REACT_APP_API_DEV_URL}/stripe`, {
              data
            },
            // { withCredentials: true, auth: user.token,
            //   // headers: {
            //   //   'Access-Control-Allow-Origin': '*',
            //   //   'Content-Type': 'application/json',
            //   // },
            // }
            )
        const endDate = new Date(res.data.endDateSubscription).toDateString()

        setIsLoading(false)
        handleCloseForm();
        //set paid plan flag in cookie
        let user1 = JSON.parse(sessionStorage.getItem('user'));
        user1.endDateSubscription = new Date(res.data.endDateSubscription).getTime();
        sessionStorage.setItem('user', JSON.stringify(user1));
        setSubEndDate(endDate)
      } catch (err) {
        console.log('errrr', err)
        toast.error(<Notification message={err.message} />, {
          icon: notificationIcons.error,
          autoDismissTimeout: 1000,
          closeButton: notificationIcons.close
        })
      }
    } catch (error) {
      console.log('error', error)
      setIsLoading(false)
      toast.error(<Notification message={error.message} />, {
        icon: notificationIcons.error,
        autoDismissTimeout: 1000,
        closeButton: notificationIcons.close
      })
    }
  };

  const handleCloseForm = (e) => {
    setIsPaymentModalOpen(!isPaymentModalOpen)
  }

  return (
    <div className="payment-overlay">
      <div className="payment-modal">
        <form onSubmit={handleSubmit(onSubmit)} className="form-container">
          <CloseIcon onClick={handleCloseForm} className="close-icon" />
          <Logo className="ss-logo" style={{width: '25px', height: 'auto'}} />
            <div className="form-title">Pay with card</div>
            {/* <div className="form-section">
              <div className="form-subtitle">Email:</div>
              <div style={{position: 'relative'}}>
              <input
                {...register('email', { 
                  required: 'This field cannot be empty',
                  pattern: {
                      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: 'Please enter a valid email'
                  }
              
                })}
                type='text'
                className={errors.email ? 'form__input form__input--error' : 'form__input'}
                />
                <EmailIcon className='onboarding__input-icon'/>
                {errors.email?.message && 
                  <span className="onboarding__error-msg">{errors.email.message}</span>
                }
                </div>
            </div> */}
            <div className="form-section">
              <div className="form-subtitle">Name on card:</div>
              <div style={{position: 'relative'}}>
              <input
                {...register('cardName', { required: 'This field cannot be empty' })}
                type='text'
                className={errors.cardName ? 'form__input form__input--error' : 'form__input'}
                />
                <UserIcon className='onboarding__input-icon'/>
                {errors.cardName?.message && 
                <span className="onboarding__error-msg">{errors.cardName.message}</span>
                }
                </div>
            </div>
            <div className="form-section">
              <div className="form-subtitle">Card information:</div>
              <div className='form-frame'>
              <CardElement options={CARD_OPTIONS} />
              </div>
            </div>
            <div className="form-section">
              <select
                  {...register('country', { required: 'This field cannot be empty' })}
                  className={errors.country ? 'onboarding__input onboarding__input--error' : 'onboarding__input'}
                  >
                  <option value="" hidden>Country</option>
                  {countries?.length > 0 && countries.map(country => (
                  <option value={country} key={country}>{country}</option>
                  ))}
                  </select>
                  <WorldIcon className='onboarding__input-icon'/>
                  {errors.country?.message && 
                  <span className="onboarding__error-msg">{errors.country.message}</span>
                  }
            </div>
            {/*<div className="form-section">*/}
            {/*  <input name="acceptTerms" type="checkbox" {...register('acceptTerms')} id="acceptTerms" />*/}
            {/*  <label htmlFor="acceptTerms" className="form-check-label">Save my info for secure 1-click checkout</label>*/}
            {/*</div>*/}
            <button className="form-button">Pay now</button>
            <StripeIcon className='stripe-icon' />
        </form>
        {isLoading && 
          <div className="payment-modal__spinner-wrapper">
            <LoadingSpinner />
          </div>
        }
      </div>
    </div>
  );
};

export default PaymentForm;