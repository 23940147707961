import { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form'
import MainNav from '../MainNav';
import  { ReactComponent as BillIcon } from '../../icons/bill.svg'
import  { ReactComponent as SettingsIcon } from '../../icons/settings.svg'
import  { ReactComponent as UsersIcon } from '../../icons/users.svg'
import MembersSection from './MembersSection';
import BillingSection from './BillingSection';
import UserProfile from './UserProfile';
import { UserContext } from '../../App'
import { SERVER_URL } from '../../url.config';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import Notification from '../notifications/Notification';
import { notificationIcons } from '../notifications/notificationIcons';
// import {success} from "react-toastify/dist/core/toast";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const UserAccount = ({ stripe }) => {
    const { user } = useContext(UserContext)
    const [section, setSection] = useState('settings');
    const [image, setImage] = useState(null);
    const [key, setKey] = useState(undefined);
    const [passwordChangeClicked, setPasswordChangeClicked] = useState(false);

    const fetchUser = async () => {
        try {
            const res = await axios.get(`${SERVER_URL}/users/${user.id}`)

            reset({userData: {
                firstName: res.data.firstName,
                lastName: res.data.lastName,
                email: res.data.email,
                country: res.data.country,
                userAvatar: res.data.userAvatar,
            }})
        } catch (err) {
        }
    }

  const fetchAvatar = async () => {
    try {
      const res = await axios.get(`${SERVER_URL}/avatar/${user.id}`);
      if (res && res.data && res.data.data) {
        setImage(res.data.data);
      } else setImage(null);
    } catch (err) {
    }
  }

  const updateUserAvatar = async (e) => {
    const convertToBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
          resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    };

    const file = e.target.files[0];
    const acceptedImageTypes = ['image/jpeg', 'image/png']
    
    if (!acceptedImageTypes.includes(file.type)) return
    if (file.size > 5 * 1024 * 1024) return

    const base64 = await convertToBase64(file);
    setImage(base64);
      try {
           await axios.post(`${SERVER_URL}/avatar/${user.id}`, { base64 });
        } catch (err) {
        }
    }

    const updateUserData = async (data) => {
        try {
            const res = await axios.patch(`${SERVER_URL}/users/${user.id}`, { data })

            reset({userData: {
                firstName: res.data.firstName,
                lastName: res.data.lastName,
                email: res.data.email,
                country: res.data.country,
                userAvatar: res.data.userAvatar,
            }})
        } catch (err) {
            toast.error(<Notification message={err.response.data.message} />, {
        	icon: notificationIcons.error,
            autoDismissTimeout: 1000,
        	closeButton: notificationIcons.close
      		})
        }
    }

    const passwordChange = async () => {
        setPasswordChangeClicked(true);
        const data = {
            email: user.email
        }
        try {
            await axios.post(`${SERVER_URL}/auth/forgot-password`, { data })
                .then((val) => {
                    toast.success(<Notification message={"Please check your email and follow instructions described there"} />, {
                        icon: notificationIcons.success,
                        autoDismissTimeout: 1000,
                        closeButton: notificationIcons.close
                    })
                })
        } catch (err) {
            toast.error(<Notification message={err.response.data.message} />, {
        	icon: notificationIcons.error,
            autoDismissTimeout: 1000,
        	closeButton: notificationIcons.close
      		})
        }
    }

    useEffect(() => {
        fetchUser();
        fetchAvatar();
    }, []);

    const removeAvatar = async() => {
        try {
            await axios.post(`${SERVER_URL}/avatar/${user.id}`, {})
                .then((val) => {
                    setImage(null);
                    setKey(Math.random())
                });
        } catch (err) {
        }
    }

    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        reset,
        getValues
    } = useForm()

    return (  
        <div className='user-account'>
            <MainNav
                userPage={true}
            />
            <div className="user-account__main">
                <div className="user-account__header">
                    <div >
                        <h5 className="user-account__title">Account</h5>
                        <p className="user-account__subtitle">Manage all account options here.</p>
                    </div>
                    <div className="user-account__sections">
                        <div 
                            className={section === 'settings' ? "user-account__section-tab user-account__section-tab--active" : "user-account__section-tab"}
                            onClick={() => setSection('settings')}
                        >
                            <SettingsIcon />
                            <span>Settings</span>
                        </div>
                        <div
                            className={section === 'members' ? "user-account__section-tab user-account__section-tab--active" : "user-account__section-tab"}
                            onClick={() => setSection('members')}
                        >
                            <UsersIcon />
                            <span>Members</span>
                        </div>
                        <div
                            className={section === 'billing' ? "user-account__section-tab user-account__section-tab--active" : "user-account__section-tab"}
                            onClick={() => setSection('billing')}
                        >
                            <BillIcon />
                            <span>Billing</span>
                        </div>
                    </div>
                </div>

                {section === 'settings' &&
                   <UserProfile 
                    register={register}
                    handleSubmit={handleSubmit}
                    errors={errors}
                    isDirty={isDirty}
                    getValues={getValues}
                    updateUserData={updateUserData}
                    passwordChange={passwordChange}
                    user={user}
                    updateUserAvatar={updateUserAvatar}
                    image={image}
                    removeAvatar={removeAvatar}
                    key={key}
                    passwordChangeClicked={passwordChangeClicked}
                   />
                }
                {section === 'members' &&
                    <MembersSection user={user} />
                }
                {section === 'billing' && stripePromise &&
                  <Elements stripe={stripePromise}>
                      {/*<PaymentForm />*/}
                      <BillingSection />
                  </Elements>

                }
            </div>
        </div>
    );
}
 
export default UserAccount;