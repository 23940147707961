import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL } from '../../url.config';
import { ReactComponent as Logo } from '../../icons/logo-horizontal master.svg';
import { ReactComponent as EmailIcon } from '../../icons/at-sign-2-1.svg';
import { ReactComponent as KeyIcon } from '../../icons/key-1.svg';
import { ReactComponent as ChatIcon } from '../../icons/chat.svg';
import HidePass from '../../icons/hide-pass.png';
import ShowPass from '../../icons/show-pass.png';
import { toast } from 'react-toastify';
import Notification from '../notifications/Notification';
import { notificationIcons } from '../notifications/notificationIcons';
import { useState } from 'react';

const customFormats = [
  { title: 'Text - 24px', block: 'p', styles: { 'font-size': '24px', 'line-height': '1.1' } },
  { title: 'Title - 56px', block: 'h1', styles: { 'font-size': '56px', 'line-height': '1.1' } },
  { title: 'H1 - 48px', block: 'h1', styles: { 'font-size': '48px', 'line-height': '1.1' } },
  { title: 'H2 - 32px', block: 'h2', styles: { 'font-size': '32px', 'line-height': '1.1' } },
  { title: 'Body - 16px', inline: 'span', styles: { 'font-size': '16px', 'line-height': '1.1' } }
];

const Login = () => {
  const [visible, setVisibility] = useState(false);
  const toggleBtn = () => {
    setVisibility((prevState) => !prevState);
  };
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const loginUser = async (data) => {
    try {
      const res = await axios.post(`${SERVER_URL}/auth/login`, { data });
      const user = {
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        token: res.data.user,
        email: res.data.email,
        id: res.data.userId,
        stripeCustomerId: res.data.stripeCustomerId,
        role: res.data.role,
        userAvatar: res.data.userAvatar,
        endDateSubscription: res.data.endDateSubscription,
        numberOfLogs: res.data.numberOfLogs
      };
      sessionStorage.setItem('user', JSON.stringify(user));
      sessionStorage.setItem('customFormats', JSON.stringify(customFormats));
      window.location.reload();
    } catch (err) {
      toast.error(<Notification message={err.response.data.message} />, {
        icon: notificationIcons.error,
        autoDismissTimeout: 1000,
        closeButton: notificationIcons.close
      });
    }
  };

  return (
    <div className='onboarding'>
      <div className='onboarding__form-wrap'>
        <div className='onboarding__form-box'>
          <Logo className='onboarding__logo' />
          <div className='onboarding__title'>
            <h3 className='onboarding__heading'>Welcome back</h3>
            <p className='onboarding__subtitle'>Sign in to continue</p>
          </div>
          <form
            className='onboarding__form'
            onSubmit={handleSubmit((data) => {
              loginUser(data);
            })}>
            <div className='onboarding__form-row'>
              <input
                {...register('email', {
                  required: 'This field cannot be empty',
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Please enter a valid email'
                  }
                })}
                type='text'
                className={
                  errors.email ? 'onboarding__input onboarding__input--error' : 'onboarding__input'
                }
                placeholder='Email address'
              />
              <EmailIcon className='onboarding__input-icon' />
              {errors.email?.message && (
                <span className='onboarding__error-msg'>{errors.email.message}</span>
              )}
            </div>
            <div className='onboarding__form-row'>
              <input
                {...register('password', {
                  required: 'This field cannot be empty',
                  minLength: {
                    value: 6,
                    message: 'Minimum length is 6 characters'
                  }
                })}
                type={visible ? 'text' : 'password'}
                className='onboarding__input'
                placeholder='Password'
              />
              <div style={{ position: 'absolute', right: '5%', top: '30%' }} onClick={toggleBtn}>
                {visible && <img src={HidePass} width='20px'></img>}{' '}
                {!visible && <img src={ShowPass} width='20px'></img>}
              </div>
              <KeyIcon className='onboarding__input-icon' />
              {errors.password?.message && (
                <span className='onboarding__error-msg'>{errors.password.message}</span>
              )}
            </div>
            <div className='onboarding__form-row'>
              <Link to='/recover-password' className='onboarding__help-link'>
                Forgot password?
              </Link>
            </div>
            <button className='onboarding__submit'>Sign in</button>
            <div style={{ position: 'relative', marginTop: '-10px' }}></div>
          </form>
          <p className='onboarding__notification'>
            <span>OR</span>
          </p>
          <Link to='/signup' className='onboarding__link'>
            <span>Create account</span>
          </Link>
        </div>
        <div className='onboarding__legal'>
          <a
            href='https://www.stackslides.com/legal/privacy-policy'
            style={{ color: '#FFFFFF', textDecoration: 'none' }}>
            <span>Privacy Policy</span>
          </a>
          <a
            href='https://www.stackslides.com/legal/terms-of-service'
            style={{ color: '#FFFFFF', textDecoration: 'none' }}>
            <span>Terms &amp; Conditions</span>
          </a>
        </div>
      </div>
      {/*<ChatIcon className='onboarding__chat' />*/}
    </div>
  );
};

export default Login;
