import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { SERVER_URL } from '../../url.config'
import  { ReactComponent as Logo } from '../../icons/logo-horizontal master.svg'
import  { ReactComponent as ChatIcon } from '../../icons/chat.svg'
import  { ReactComponent as EmailIcon } from '../../icons/at-sign-2-1.svg'
import  { ReactComponent as DoneIcon } from '../../icons/done_all_black_24dp.svg'

const RecoverPassword = () => {
	const [emailSent, setEmailSent] = useState(false);

    const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm()

	const sendEmail = async (data) => {
		try {
            const res = await axios.post(`${SERVER_URL}/auth/forgot-password`, { data })
            setEmailSent(true)
        } catch (err) {
        }
	}


    return (  
        <div className="onboarding">
			<div className="onboarding__form-wrap">
				<div className="onboarding__form-box">
                    <Logo className='onboarding__logo' />
					{!emailSent &&
						<>
							<div className="onboarding__title">
								<h3 className="onboarding__heading onboarding__heading--wide">Recover your password</h3>
								<p className="onboarding__subtitle">Enter email used with this account.</p>
							</div>
							<form 
								className="onboarding__form"
								onSubmit={handleSubmit((data) => {
									sendEmail(data)
								})}
							>
								<div className="onboarding__form-row">
									<input
										{...register('email', { 
											required: 'This field cannot be empty',
											pattern: {
												value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
												message: 'Please enter a valid email'
											}
										
										})}
										type='text'
										className={errors.email ? 'onboarding__input onboarding__input--error' : 'onboarding__input'}
										placeholder='Email address'
									/>
									<EmailIcon className='onboarding__input-icon'/>
									{errors.email?.message && 
										<span className="onboarding__error-msg">{errors.email.message}</span>
									}
								</div>
								{/* <div className="onboarding__form-row">
									<Link 
										to='#'
										className="onboarding__help-link"	
									>
										Get in touch with support
									</Link>
								</div> */}
								<button className='onboarding__submit'>Recover</button>
							</form>
							<p className="onboarding__notification">
								<span>Remembered your password?</span>
							</p>
							<Link 
								to='/login'
								className="onboarding__link"
							>
								<span>Sign in</span>
							</Link>
						</>
					}
					{emailSent &&
						<>
							<DoneIcon />
                            <div className="onboarding__title">
                                <h3 className="onboarding__heading">Email sent!</h3>
                                <p className="onboarding__subtitle">Email has been sent with password recovery link, please follow instructions in your email to set up new password.</p>
                            </div>
						</>
					}
					
                </div>
                <div className="onboarding__legal">
                    <a href='https://www.stackslides.com/legal/privacy-policy' style={{color: "#FFFFFF", textDecoration: "none"}}>
                        <span>Privacy Policy</span>
                    </a>
                    <a href='https://www.stackslides.com/legal/terms-of-service' style={{color: "#FFFFFF", textDecoration: "none"}}>
                        <span>Terms &amp; Conditions</span>
                    </a>
                </div>
            </div>
            {/*<ChatIcon className='onboarding__chat' />*/}
        </div>
    );
}
 
export default RecoverPassword;