import { useEffect, useLayoutEffect, useState, useRef, useCallback } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { SERVER_URL } from '../url.config';
import { ReactComponent as StopIcon } from '../icons/stop-icon.svg';
import { ReactComponent as PlayIcon } from '../icons/play-icon.svg';
import { ReactComponent as RestartIcon } from '../icons/restart-icon.svg';
import { ReactComponent as PreviousIcon } from '../icons/previous-icon.svg';
import { ReactComponent as NextIcon } from '../icons/next-icon.svg';
import { ReactComponent as CloseIcon } from '../icons/close-icon.svg';
import LoadingSpinner from './spinner/LoadingSpinner';

const PlaySlideshow = ({ screenSize, setScreenSize }) => {
  const { presentationId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [presentation, setPresentation] = useState(null);
  const [slideNum, setSlideNum] = useState(0);
  const [start, setStart] = useState(false);
  const controlsRef = useRef();
  const [isMouseMoving, setIsMouseMoving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const fromEditing = location.state?.fromEditing;

  const getPresentation = async () => {
    setIsLoading(true);
    try {
      const res = await axios.get(`${SERVER_URL}/presentations/${presentationId}`);
      setIsLoading(false);
      setPresentation(res.data);
    } catch (err) {
      setIsLoading(false);
      setIsError(true);
      console.log(err);
    }
  };

  useEffect(() => {
    if (screenSize && start) {
      const docEl = document.documentElement;

      if (docEl.requestFullscreen) {
        docEl.requestFullscreen();
      } else if (docEl.webkitRequestFullscreen) {
        docEl.webkitRequestFullscreen();
      } else if (docEl.msRequestFullscreen) {
        docEl.msRequestFullscreen();
      } else if (docEl.mozRequestFullScreen) {
        docEl.mozRequestFullScreen();
      }
    }
  }, [screenSize, start]);
  // useLayoutEffect(() => {
  //     if (window.innerWidth  < 768) {
  //         setScreenSize({ width: 360, height: 640, type: 'mobile' })
  //     } else if (window.innerWidth < 1440) {
  //         setScreenSize({ width: 768, height: 1024, type: 'tablet' })
  //     } else if (window.innerWidth < 1920) {
  //         setScreenSize({ width: 1440, height: 900, type: 'laptop' })
  //     } else {
  //         setScreenSize({ width: 1920, height: 1080, type: 'desktop' })
  //     }
  // }, [])

  useEffect(() => {
    getPresentation();
  }, [presentationId]);

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowRight') {
      if (slideNum === presentation.data.slides.length - 1) {
        return;
      } else {
        setSlideNum((previous) => previous + 1);
      }
    } else if (e.key === 'ArrowLeft') {
      if (slideNum === 0) {
        return;
      } else {
        setSlideNum((previous) => previous - 1);
      }
    } else if (e.shiftKey && e.key === 'ArrowLeft') {
      setSlideNum(0);
    } else if (e.key === ' ') {
      if (!start) {
        setStart(true);
      } else {
        setStart(false);
        exitFullscreenMode();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => document.removeEventListener('keydown', handleKeyDown);
  });

  const handleFullScreenChange = (e) => {
    const FSElement =
      document.fullscreenElement ||
      document.msFullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullscreenElement;

    if (!FSElement) {
      setStart(false);
    }
  };

  useEffect(() => {
    document.documentElement.addEventListener('fullscreenchange', handleFullScreenChange);

    return () =>
      document.documentElement.removeEventListener('fullscreenchange', handleFullScreenChange);
  });

  const debounce = (cb, delay) => {
    let timeout;

    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        cb(...args);
      }, delay);
    };
  };

  const mouseStop = useCallback(
    debounce(() => setIsMouseMoving(false), 2000),
    []
  );

  const handleMouseMove = () => {
    if (controlsRef.current) {
      if (!isMouseMoving) {
        setIsMouseMoving(true);
      }
      mouseStop();
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);

    return () => document.removeEventListener('mousemove', handleMouseMove);
  });

  let slide;

  if (presentation && screenSize && slideNum < presentation?.data?.slides.length) {
    slide = presentation?.data?.slides[slideNum][`${screenSize.type}`];
  }

  const exitFullscreenMode = () => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  const stopPresentation = () => {
    exitFullscreenMode();

    setStart(false);
    if (fromEditing) {
      navigate(`/slideshow/${presentationId}`);
    }
  };

  const playSlideshow = async () => {
    const elements = document.querySelectorAll('.play-slideshow');

    setInterval(() => {
      do {
        setTimeout(() => {
          elements[0].style.transition = 'opacity 0.5s ease-in-out';
          elements[0].style.opacity = 0.2; // Reset opacity to 1
        }, 2500);
      } while (start && slideNum < presentation?.data?.slides.length);

      setSlideNum((previous) => previous + 1); // Move to the next slide

      elements[0].style.transition = 'opacity 0.5s ease-in-out';
      elements[0].style.opacity = 1; // Reset opacity to 1
    }, 3000);

    setTimeout(() => {
      elements[0].style.transition = 'opacity 0.5s ease-in-out';
      elements[0].style.opacity = 0.2; // Reset opacity to 1
    }, 2500);
  };

  if (start && slideNum == presentation?.data?.slides.length) {
    stopPresentation();
  }

  return (
    <>
      {isLoading && (
        <div className='play-slideshow-spinner-wrapper'>
          <LoadingSpinner />
        </div>
      )}
      {!isError && !isLoading && presentation && (
        <div className='play-slideshow-bg-container'>
          {screenSize && (
            <div
              className='play-slideshow'
              style={{
                width: screenSize ? screenSize.width : '100vw',
                height: screenSize ? screenSize.height : '100vh',
                backgroundColor: presentation?.data?.slides[slideNum]?.backgroundColor
              }}>
              {slide &&
                slide.length > 0 &&
                slide.map((element) => {
                  if (element.type === 'img') {
                    return (
                      <img
                        key={element.id}
                        src={element.img}
                        style={{
                          position: 'absolute',
                          width: element.width,
                          height: element.height,
                          opacity: element.imgOpacity,
                          top: 0,
                          left: 0,
                          transform: `translate(${element.translate[0]}px, ${element.translate[1]}px) rotate(${element.rotate}deg)`
                        }}
                      />
                    );
                  } else {
                    return (
                      <div
                        key={element.id}
                        style={{
                          position: 'absolute',
                          width: element.width,
                          height: element.height,
                          top: 0,
                          left: 0,
                          transform: `translate(${
                            element?.translate ? element?.translate[0] : 0
                          }px, ${element?.translate ? element?.translate[1] : 0}px) rotate(${
                            element?.rotate
                          }deg)`,
                          padding:
                            element.type === 'text' || element.type === 'embed' ? '18px' : '',
                          opacity:
                            element.type === 'text'
                              ? element.textOpacity
                              : element.type === 'img'
                              ? element.imgOpacity
                              : element.type === 'shape'
                              ? element.shapeOpacity
                              : ''
                        }}>
                        {element.type === 'text' && (
                          <div dangerouslySetInnerHTML={{ __html: element.textContent }}></div>
                        )}
                        {element.type === 'shape' && (
                          <div
                            style={{
                              height: '100%',
                              backgroundColor: element.shapeColor,
                              opacity: element.shapeOpacity,
                              border:
                                element.shapeType === 'triangle'
                                  ? 'none'
                                  : element.shapeBorderThickness
                                  ? `${element.shapeBorderThickness} ${element.shapeBorderType} ${element.shapeBorderColor}`
                                  : 'none',
                              borderRadius:
                                element.shapeType === 'triangle'
                                  ? ''
                                  : element.shapeType === 'ellipse'
                                  ? '50%'
                                  : `${element.shapeBorderRadius}%`,
                              clipPath:
                                element.shapeType === 'triangle'
                                  ? 'polygon(50% 0%, 0% 100%, 100% 100%)'
                                  : ''
                            }}></div>
                        )}
                        {element.type === 'embed' && (
                          <div
                            style={{ width: '100%', height: '100%' }}
                            dangerouslySetInnerHTML={{ __html: element.embedSrc }}></div>
                        )}
                      </div>
                    );
                  }
                })}
              <div
                ref={controlsRef}
                className={
                  isMouseMoving
                    ? 'play-slideshow__controls play-slideshow__controls--show'
                    : 'play-slideshow__controls'
                }>
                <div
                  className={
                    start
                      ? 'play-slideshow__controls-btn'
                      : 'play-slideshow__controls-btn play-slideshow__controls-btn--disabled'
                  }
                  onClick={() => {
                    if (start) {
                      setStart(false);
                      exitFullscreenMode();
                    }
                  }}>
                  <StopIcon />
                </div>
                <div
                  className={
                    start
                      ? 'play-slideshow__controls-btn play-slideshow__controls-btn--disabled'
                      : 'play-slideshow__controls-btn'
                  }
                  onClick={async () => {
                    if (!start) {
                      setStart(true);
                    }
                    playSlideshow();
                  }}>
                  <PlayIcon />
                </div>
                <div className='play-slideshow__controls-btn' onClick={() => setSlideNum(0)}>
                  <RestartIcon />
                </div>
                <div
                  className={
                    slideNum === 0
                      ? 'play-slideshow__controls-btn play-slideshow__controls-btn--disabled'
                      : 'play-slideshow__controls-btn'
                  }
                  onClick={() => {
                    if (slideNum === 0) {
                      return;
                    } else {
                      setSlideNum((previous) => previous - 1);
                    }
                  }}>
                  <PreviousIcon />
                </div>
                {/* <div 
                                    className="play-slideshow__controls-btn"
                                    onClick={stopPresentation}
                                >
                                    Stop presentation
                                </div> */}
                <div
                  className={
                    slideNum === presentation.data.slides.length - 1
                      ? 'play-slideshow__controls-btn play-slideshow__controls-btn--disabled'
                      : 'play-slideshow__controls-btn'
                  }
                  onClick={() => {
                    if (slideNum === presentation.data.slides.length - 1) {
                      return;
                    } else {
                      setSlideNum((previous) => previous + 1);
                    }
                  }}>
                  <NextIcon />
                </div>
              </div>
              <div
                className={
                  isMouseMoving
                    ? 'play-slideshow__controls play-slideshow__controls--top play-slideshow__controls--show'
                    : 'play-slideshow__controls play-slideshow__controls--top'
                }>
                <div className='play-slideshow__controls-btn' onClick={stopPresentation}>
                  <CloseIcon />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {!isLoading && isError && (
        <div>
          <h2>Something went wrong :(</h2>
        </div>
      )}
    </>
  );
};

export default PlaySlideshow;
