import {
  useState,
  useLayoutEffect,
  useEffect,
  useReducer,
  useContext,
  useCallback,
  useRef
} from 'react';
import { toast } from 'react-toastify';
import { ReactComponent as Logo } from '../icons/logo-icon.svg';
import { ReactComponent as Home } from '../icons/home.svg';
import { ReactComponent as PCIcon } from '../icons/pc.svg';
import { ReactComponent as LaptopIcon } from '../icons/laptop.svg';
import { ReactComponent as TabletIcon } from '../icons/tablet.svg';
import { ReactComponent as PhoneIcon } from '../icons/phone.svg';
import { ReactComponent as SaveIcon } from '../icons/save.svg';
import { ReactComponent as PlayIcon } from '../icons/play.svg';
import { ReactComponent as ShareIcon } from '../icons/share.svg';
import { ReactComponent as PlusIcon } from '../icons/plus-outlined.svg';
import { ReactComponent as MinusIcon } from '../icons/minus-outlined.svg';
import { ReactComponent as DownArrow } from '../icons/chevron-down.svg';
import { ReactComponent as SliderIcon } from '../icons/slider-1.svg';
import CustomSwitch from './CustomSwitch';
import Slide from './Slide';
import { Link, useParams } from 'react-router-dom';
import InsertMenu from './InsertMenu';
import axios from 'axios';
import { SERVER_URL, SITE_URL } from '../url.config';
import presentationReducer from '../reducers/presentation/presentationReducer';
import {
  ADD_ELEMENT,
  DELETE_ELEMENT,
  DELETE_SLIDE,
  EDIT_PRESENTATION,
  EDIT_SLIDE_PROPERTY,
  SET_PRESENTATION,
  ADD_SLIDE,
  UNDO,
  REDO
} from '../reducers/presentation/actionTypes';
import { v4 as uuidv4 } from 'uuid';
import LayersList from './LayersList';
import UnsplashModal from './UnsplashModal';
import { ReactComponent as DotsIcon } from '../icons/dots-vertical.svg';
import { UserContext } from '../App';
import ShapesToolbar from './elements/ShapesToolbar';
import EmbedModal from './EmbedModal';
import ImageToolbar from './elements/ImageToolbar';
import DeleteDialog from './DeleteModal';
import { ReactComponent as CheckedIcon } from '../icons/c-check.svg';
import { debounce } from '../utils/utils';

const Slideshow = ({ screenSize, setScreenSize, responsiveVisible, setResponsiveVisible }) => {
  const initState = {
    presentation: null,
    presentationHistory: [],
    presentationFuture: []
  };

  const { user } = useContext(UserContext);

  const [state, dispatch] = useReducer(presentationReducer, initState);
  const { presentation, presentationHistory, presentationFuture } = state;
  const { presentationId } = useParams();
  const [saved, setSaved] = useState(false);
  const [editorStyles, setEditorStyles] = useState(undefined);
  const [editorStyleId, setEditorStyleId] = useState(undefined);
  const [screenTooSmall, setScreenTooSmall] = useState(false);

  const [presentationSaved, setPresentationSaved] = useState(false);

  // console.log('screeeeeeen size', screenSize);

  useEffect(() => {
    fetchEditorStyles();
  }, [user]);

  console.log('user: ', user);

  //fetch custom editor styles from database
  const fetchEditorStyles = async () => {
    try {
      const res = await axios.get(`${SERVER_URL}/editorStyles/${user.id}`);
      setEditorStyles(res.data.styles);
      setEditorStyleId(res.data._id);
    } catch (err) {
      // throw new Error(err);
      console.log('error in fetchEditorStyles: ', err);
    }
  };

  //update custom editor styles
  const updateEditorStyles = async (data) => {
    try {
      const res = await axios.patch(`${SERVER_URL}/editorStyles/${editorStyleId}`, { data });
      setEditorStyles(res.data.styles);
    } catch (err) {
      // throw new Error(err);
      console.log('error in updateEditorStyles: ', err);
    }
  };

  //delete custom editor styles
  //TODO data here should be an index of an object that has to be deleted
  //TODO e.g. if we want to delete 6th object from the list of custom styles, we send here number 5
  const deleteEditorStyles = async (data) => {
    let data1 = {
      data
    };

    try {
      await axios
        .patch(`${SERVER_URL}/editorStyles/delete/${editorStyleId}`, { data: data1 })
        .then((val) => {
          // fetchEditorStyles();
        });
    } catch (err) {
      // throw new Error(err);
      console.log('error in deleteEditorStyles: ', err);
    }
  };

  const getPresentation = async () => {
    try {
      const res = await axios.get(`${SERVER_URL}/presentations/${presentationId}`);

      dispatch({
        type: SET_PRESENTATION,
        payload: {
          ...res.data
        }
      });
    } catch (err) {
      // throw new Error(err);
      console.log('error in getPresentation: ', err);
    }
  };

  const savePresentation = async (submitData, manuallySaved, userId) => {
    try {
      submitData.userId = userId;
      await axios.patch(`${SERVER_URL}/presentations/${presentationId}`, submitData);
      if (manuallySaved) {
        toast('Presentation saved');
      }
      setTimeout(() => {
        setSaved(false);
      }, 1000);
    } catch (err) {
      // throw new Error(err);
      console.log('error in savePresentation: ', err);
      // toast.error(<Notification message='Something went wrong during saving data...please try later.' />, {
      //     icon: notificationIcons.error,
      //     closeButton: notificationIcons.close,
      //     toastId: 'custom-id-free-plan-notification',
      // })
    }
  };

  //set autosave
  useEffect(() => {
    if (user) {
      setTimeout(() => {
        setSaved(true);
      }, 300);
      savePresentation(presentation, false, user.id);
      setPresentationSaved(true);
    }
  }, [presentation, user]);

  useEffect(() => {
    if (presentationSaved) {
      getPresentation();
      setPresentationSaved(false);
    }
  }, []);

  const deletePresentation = async (submitData) => {
    try {
      await axios.delete(`${SERVER_URL}/presentations/${presentationId}`, submitData);
      window.location.reload();
    } catch (err) {
      console.log('error in deletePresentation: ', err);
    }
  };

  useEffect(() => {
    getPresentation();
  }, [presentationId]);

  const addElement = (type, elementProps) => {
    const elementWidth =
      type === 'shape' ? 150 : type === 'embed' ? 360 : type === 'img' ? elementProps.width : null;
    const elementHeight =
      type === 'shape' ? 150 : type === 'embed' ? 300 : type === 'img' ? elementProps.height : null;

    const newElement = {
      id: uuidv4(),
      type,
      name: type,
      width:
        type === 'shape' || type === 'embed'
          ? elementWidth
          : type === 'img'
          ? elementProps.width
          : null,
      height:
        type === 'shape' || type === 'embed'
          ? elementHeight
          : type === 'img'
          ? elementProps.height
          : null,
      textContent:
        type === 'text'
          ? '<p style="font-family: roboto; font-size: 24px;"><span>Text in a field</span></p>'
          : null,
      translate: copiedElement?.translate,
      rotate: 0,
      isNew: true,
      img: type === 'img' ? elementProps.src : null,
      imgOpacity: type === 'img' ? elementProps.opacity : null,
      textOpacity:
        type === 'text' && elementProps && elementProps.textOpacity
          ? elementProps.textOpacity
          : 100,
      shapeType: type === 'shape' ? 'rectangle' : null,
      shapeColor: type === 'shape' ? '#D9D9D9' : null,
      shapeOpacity: type === 'shape' ? '1' : null,
      shapeBorderThickness: type === 'shape' ? '1px' : null,
      shapeBorderType: type === 'shape' ? 'solid' : null,
      shapeBorderColor: type === 'shape' ? '#000000' : null,
      shapeBorderRadius: type === 'shape' ? 0 : null,
      embedSrc: type === 'embed' ? elementProps.src : null
    };

    // setInsertMenuCoords(null)
    dispatch({
      type: ADD_ELEMENT,
      payload: {
        slideId: selectedSlide.id,
        screenSize: screenSize.type,
        element: newElement
      }
    });
  };

  //***********DUPLICATE ELEMENT***********//

  const [copiedElement, setCopiedElement] = useState({});
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    if (selectedElement) {
      const handleCopyKeyEvent = (e) => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
          e.preventDefault();
          setCopiedElement(
            presentation.data.slides
              .find((slide) => slide.id === selectedSlide.id)
              [`${screenSize.type}`].find((element) => element.id === selectedElement)
          );
          setIsCopied(true);
        }
      };
      document.addEventListener('keydown', handleCopyKeyEvent);
      return () => document.removeEventListener('keydown', handleCopyKeyEvent);
    }
  });

  // select multiple elements

  const [selectedElements, setSelectedElements] = useState([]);

  useEffect(() => {
    if (selectedElement) {
      const handleCopyKeyEvent = (e) => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
          e.preventDefault();
          setCopiedElement(
            presentation.data.slides
              .find((slide) => slide.id === selectedSlide.id)
              [`${screenSize.type}`].find((element) => element.id === selectedElement)
          );
          setIsCopied(true);
        }
      };
      document.addEventListener('keydown', handleCopyKeyEvent);
      return () => document.removeEventListener('keydown', handleCopyKeyEvent);
    }
  });

  useEffect(() => {
    if (copiedElement) {
      if (embedModalVisible || unsplashModalVisible) {
        setIsCopied(false);
      }
      const handlePasteKeyEvent = (e) => {
        if ((e.ctrlKey || e.metaKey) && e.key === 'v' && selectedSlide && isCopied) {
          e.preventDefault();

          const newElement = { ...copiedElement, id: uuidv4() };
          dispatch({
            type: ADD_ELEMENT,
            payload: {
              slideId: selectedSlide.id,
              screenSize: screenSize.type,
              element: newElement
            }
          });

          setSelectedElement(newElement.id);
          setTarget(document.querySelector(`.target-${newElement.id}`));
        }
      };
      document.addEventListener('keydown', handlePasteKeyEvent);
      return () => document.removeEventListener('keydown', handlePasteKeyEvent);
    }
  });

  const [slideTitleInputVisible, setSlideTitleInputVisible] = useState(false);
  const [slideTitleDropdownVisible, setSlideTitleDropdownVisible] = useState(false);
  const slideTitleDropdownRef = useRef();
  const slideTitleInputRef = useRef();
  const [selectedSlide, setSelectedSlide] = useState(null);

  const [zoom, setZoom] = useState(null);
  const transformComponentRef = useRef();
  // const [screenSize, setScreenSize] = useState({ width: 1920, height: 1080, type: 'desktop' })
  const [initialZoom, setInitialZoom] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteSlideModalVisible, setDeleteSlideModalVisible] = useState(false);

  const slideContainerRef = useRef();
  const presentationDropdownRef = useRef();
  const [presentationDropdownVisible, setPresentationDropdownVisible] = useState(false);
  const [presentationTitleInputVisible, setPresentationTitleInputVisible] = useState(false);
  const presentationTitleInputRef = useRef();

  const [shareUrl, setShareUrl] = useState(null);
  const sharePopupRef = useRef();

  const [unsplashModalVisible, setUnsplashModalVisible] = useState(false);
  const [embedModalVisible, setEmbedModalVisible] = useState(false);

  const [editingShapeElement, setEditingShapeElement] = useState(null);
  const [selectedElement, setSelectedElement] = useState(null);
  const [target, setTarget] = useState(null);
  const zoomInputRef = useRef();
  const [zoomInputVisible, setZoomInputVisible] = useState(false);
  const [zoomInputValue, setzoomInputValue] = useState('');

  const [targets, setTargets] = useState([]);

  const handleClickOutsideDropdown = (e) => {
    if (presentationDropdownRef.current && !presentationDropdownRef.current.contains(e.target)) {
      setPresentationDropdownVisible(false);
    }
  };

  const handleClickDelete = (id) => {
    console.log(id);
  };

  useEffect(() => {
    if (presentationDropdownVisible) {
      document.addEventListener('click', handleClickOutsideDropdown);
      document.addEventListener('contextmenu', handleClickOutsideDropdown);

      return () => {
        document.removeEventListener('click', handleClickOutsideDropdown);
        document.removeEventListener('contextmenu', handleClickOutsideDropdown);
      };
    }
  });

  useEffect(() => {
    if (presentationTitleInputVisible && presentationTitleInputRef.current) {
      presentationTitleInputRef.current.focus();
    }
  }, [presentationTitleInputVisible]);

  const closeTitleInput = (e) => {
    if (!presentation.data.title) return;

    if (
      presentationTitleInputRef.current &&
      !presentationTitleInputRef.current.contains(e.target)
    ) {
      setPresentationTitleInputVisible(false);
    }
  };

  useEffect(() => {
    if (presentationTitleInputVisible && presentationTitleInputRef.current) {
      document.addEventListener('click', closeTitleInput);
      document.addEventListener('contextmenu', closeTitleInput);

      return () => {
        document.removeEventListener('click', closeTitleInput);
        document.removeEventListener('contextmenu', closeTitleInput);
      };
    }
  });

  const handleClickOutsideSlideTitleDropdown = (e) => {
    if (slideTitleDropdownRef.current && !slideTitleDropdownRef.current.contains(e.target)) {
      setSlideTitleDropdownVisible(false);
    }
  };

  useEffect(() => {
    if (slideTitleDropdownVisible) {
      document.addEventListener('click', handleClickOutsideSlideTitleDropdown);
      document.addEventListener('contextmenu', handleClickOutsideSlideTitleDropdown);

      return () => {
        document.removeEventListener('click', handleClickOutsideSlideTitleDropdown);
        document.removeEventListener('contextmenu', handleClickOutsideSlideTitleDropdown);
      };
    }
  });

  useEffect(() => {
    if (slideTitleInputVisible && slideTitleInputRef.current) {
      slideTitleInputRef.current.focus();
    }
  }, [slideTitleInputVisible]);

  const closeSlideTitleInput = (e) => {
    if (!editingSlide.title) return;

    if (slideTitleInputRef.current && !slideTitleInputRef.current.contains(e.target)) {
      setSlideTitleInputVisible(false);
    }
  };

  useEffect(() => {
    if (slideTitleInputVisible && slideTitleInputRef.current) {
      document.addEventListener('click', closeSlideTitleInput);
      document.addEventListener('contextmenu', closeSlideTitleInput);

      return () => {
        document.removeEventListener('click', closeSlideTitleInput);
        document.removeEventListener('contextmenu', closeSlideTitleInput);
      };
    }
  });

  const handleClickOutsideSharePopup = (e) => {
    if (sharePopupRef.current && !sharePopupRef.current.contains(e.target)) {
      setShareUrl(null);
    }
  };

  useEffect(() => {
    if (shareUrl) {
      document.addEventListener('click', handleClickOutsideSharePopup);
      document.addEventListener('contextmenu', handleClickOutsideSharePopup);

      return () => {
        document.removeEventListener('click', handleClickOutsideSharePopup);
        document.removeEventListener('contextmenu', handleClickOutsideSharePopup);
      };
    }
  });

  const zoomIn = () => {
    if (transformComponentRef.current) {
      const { zoomIn } = transformComponentRef.current;

      zoomIn(0.1);
      setZoom((previous) => previous + 0.1);
    }
  };
  const zoomOut = () => {
    if (transformComponentRef.current) {
      const { zoomOut } = transformComponentRef.current;

      zoomOut(0.1);
      setZoom((previous) => previous - 0.1);
    }
  };
  const resetZoom = () => {
    // resetTransform() vraca na initialScale.
    // mozda ima vise smisla da ovo radi custom funkcija koja ce izracunati scale na osnovu dimenzije
    // kontejnera kao u useLayoutEffect prilikom 1.rendera za slucaj da je window resizovan u medjuvremenu
    if (transformComponentRef.current) {
      const { resetTransform } = transformComponentRef.current;

      resetTransform();
      setZoom(initialZoom);
    }
  };

  useLayoutEffect(() => {
    if (slideContainerRef.current) {
      const containerWidth = slideContainerRef.current.clientWidth;
      const containerHeight = slideContainerRef.current.clientHeight;

      let scaleThatFits;
      const getZoomScale = (scale) => {
        scaleThatFits = scale;
        const newScale = scale - 0.05;

        if (
          scaleThatFits * screenSize.width >= containerWidth - 48 ||
          scaleThatFits * screenSize.height >= containerHeight
        ) {
          getZoomScale(newScale);
        }
      };
      getZoomScale(1);
      setInitialZoom(scaleThatFits);
      setZoom(scaleThatFits);
      // getPresentation()
    }
  }, [screenSize.width, screenSize.height]);

  const handleUndoRedoKeyEvent = (e) => {
    if (e.key === 'z' && (e.ctrlKey || e.metaKey) && presentationHistory.length > 0) {
      dispatch({
        type: UNDO
      });
      if (selectedElement) {
        setSelectedElement(null);
      }
      if (editingShapeElement) {
        setEditingShapeElement(null);
      }
    } else if (e.key === 'y' && (e.ctrlKey || e.metaKey) && presentationFuture.length > 0) {
      dispatch({
        type: REDO
      });
      if (selectedElement) {
        setSelectedElement(null);
      }
      if (editingShapeElement) {
        setEditingShapeElement(null);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleUndoRedoKeyEvent);
    return () => document.removeEventListener('keydown', handleUndoRedoKeyEvent);
  });

  const handleDeleteElementKeyEvent = (e) => {
    if (
      selectedElement &&
      (e.key === 'Delete' || e.key === 'Backspace') &&
      document.activeElement.tagName !== 'INPUT'
    ) {
      setSelectedElement(null);
      setEditingShapeElement(null);
      dispatch({
        type: DELETE_ELEMENT,
        payload: {
          slideId: selectedSlide.id,
          elementId: selectedElement
        }
      });
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleDeleteElementKeyEvent);
    return () => document.removeEventListener('keydown', handleDeleteElementKeyEvent);
  });

  useEffect(() => {
    if (zoomInputVisible) {
      setzoomInputValue(String(Math.round(zoom * 100)));
      zoomInputRef.current.focus();
    }
  }, [zoomInputVisible]);

  const handleSubmitZoomInput = () => {
    const { setTransform } = transformComponentRef.current;

    if (!zoomInputValue) {
      setZoomInputVisible(false);
      setzoomInputValue('');
      return;
    } else if (parseInt(zoomInputValue) < 20) {
      setTransform(
        transformComponentRef.current.state.positionX,
        transformComponentRef.current.state.positionY,
        0.2
      );
      setZoom(0.2);
      setzoomInputValue('');
      setZoomInputVisible(false);
    } else {
      setTransform(
        transformComponentRef.current.state.positionX,
        transformComponentRef.current.state.positionY,
        parseInt(zoomInputValue) / 100
      );
      setZoom(parseInt(zoomInputValue) / 100);
      setzoomInputValue('');
      setZoomInputVisible(false);
    }
  };

  useEffect(() => {
    if (zoomInputVisible) {
      const handleClickOutside = (e) => {
        if (zoomInputRef.current && !zoomInputRef.current.contains(e.target)) {
          handleSubmitZoomInput();
        }
      };

      document.addEventListener('click', handleClickOutside);
      return () => document.removeEventListener('click', handleClickOutside);
    }
  });

  let editingSlide;
  if (selectedSlide) {
    editingSlide = presentation.data.slides.find((slide) => slide.id === selectedSlide.id);
  }

  // const slidesContainerRef = useRef(null);

  // Scroll the slides container to the bottom
  const scrollToBottom = () => {
    if (slideContainerRef.current) {
      slideContainerRef.current.scrollTop = slideContainerRef.current.scrollHeight;
    }
  };

  const addNewSlide = () => {
    const newSlide = {
      id: uuidv4(),
      title: `Slide ${presentation?.data.slides.length + 1}`,
      desktop: [],
      laptop: [],
      tablet: [],
      mobile: [],
      layoutNotChanged: ['laptop', 'tablet', 'mobile'],
      backgroundColor: '#fff',
      showGrid: true
    };

    dispatch({
      type: ADD_SLIDE,
      payload: {
        slide: newSlide
      }
    });

    setSelectedSlide(newSlide);

    setTimeout(scrollToBottom, 100);
  };

  const shareLink = () => {
    const el = document.createElement('input');
    el.value = shareUrl;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    setShareUrl(null);
    toast.success('Link copied to your clipboard');
  };

  const handleGridOffClick = (id, value) => {
    setSlideTitleDropdownVisible(false);
    // setGridState(!gridState);
    // setCurrentSlideId(id);

    dispatch({
      type: EDIT_SLIDE_PROPERTY,
      payload: {
        slideId: id,
        updates: {
          showGrid: value
        }
      }
    });
  };

  const checkWindowSize = useCallback(
    debounce(() => {
      if (window.innerWidth < 1024) {
        setScreenTooSmall(true);
      } else if (window.innerWidth >= 1024) {
        setScreenTooSmall(false);
      }
    }, 200),
    []
  );

  useEffect(() => {
    window.addEventListener('resize', checkWindowSize);
    return () => window.removeEventListener('resize', checkWindowSize);
  });

  useLayoutEffect(() => {
    if (window.innerWidth < 1024) {
      setScreenTooSmall(true);
    }
  }, []);

  const handleClickOutsideSlide = (e) => {
    if (selectedSlide && e.target.className === 'slideshow__preview-container')
      setSelectedSlide(null);
  };

  return (
    <>
      <div className='slideshow'>
        <header className='slideshow__header'>
          <div className='slideshow__header-section'>
            <div className='slideshow__header-navigation'>
              <Logo />
              <div className='homeLink'>
                <span className='tooltiptext'>Back to home</span>
                <Link to='/'>
                  <Home className='slideshow__header-home-link' />
                </Link>
              </div>
              {presentationTitleInputVisible ? (
                <input
                  ref={presentationTitleInputRef}
                  className='slideshow__header-presentation-input'
                  value={presentation.data.title}
                  onChange={(e) => {
                    dispatch({
                      type: EDIT_PRESENTATION,
                      payload: {
                        updates: {
                          title: e.target.value
                        }
                      }
                    });
                  }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      setPresentationTitleInputVisible(false);
                    }
                  }}
                />
              ) : (
                <>
                  <span
                    className='slideshow__header-presentation-input'
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setPresentationDropdownVisible(true);
                    }}
                    onDoubleClick={(e) => {
                      setPresentationTitleInputVisible(true);
                      dispatch({
                        type: EDIT_PRESENTATION,
                        payload: {
                          updates: {
                            title: e.target.value
                          }
                        }
                      });
                    }}>
                    {presentation?.data.title}
                  </span>
                  <DotsIcon
                    onClick={(e) => {
                      e.preventDefault();
                      setPresentationDropdownVisible(true);
                    }}
                  />
                </>
              )}

              {presentationDropdownVisible && (
                <div ref={presentationDropdownRef} className='slideshow__header-dropdown'>
                  <span
                    className='p-s'
                    onClick={() => {
                      setPresentationTitleInputVisible(true);
                      setPresentationDropdownVisible(false);
                    }}>
                    Rename
                  </span>
                  <span
                    className='p-s'
                    onClick={() => {
                      setPresentationDropdownVisible(false);
                      setDeleteModalVisible(true);
                    }}>
                    Delete
                  </span>
                </div>
              )}
              {deleteModalVisible && (
                <DeleteDialog
                  message={'Are you sure you want to delete'}
                  itemName={presentation.data.title}
                  setModalVisible={setDeleteModalVisible}
                  handleDelete={deletePresentation}></DeleteDialog>
              )}
            </div>
          </div>
          <div className='slideshow__header-section'>
            <div className='slideshow__responsive-options'>
              <div
                className={
                  screenSize.width === 1920
                    ? 'slideshow__desktop-btn responsive-btn responsive-btn--active'
                    : 'slideshow__desktop-btn responsive-btn'
                }
                onClick={() => setScreenSize({ width: 1920, height: 1080, type: 'desktop' })}>
                <PCIcon />
                <span>16:9</span>
              </div>
              {responsiveVisible && (
                <div className='slideshow__small-screens-btns'>
                  <div
                    className={
                      screenSize.width === 1440
                        ? 'responsive-btn responsive-btn--active'
                        : 'responsive-btn'
                    }
                    onClick={() => setScreenSize({ width: 1440, height: 900, type: 'laptop' })}>
                    <LaptopIcon />
                    <span>1440</span>
                  </div>
                  <div
                    className={
                      screenSize.width === 768
                        ? 'responsive-btn responsive-btn--active'
                        : 'responsive-btn'
                    }
                    onClick={() => setScreenSize({ width: 768, height: 1024, type: 'tablet' })}>
                    <TabletIcon />
                    <span>768</span>
                  </div>
                  <div
                    className={
                      screenSize.width === 360
                        ? 'responsive-btn responsive-btn--active'
                        : 'responsive-btn'
                    }
                    onClick={() => setScreenSize({ width: 360, height: 640, type: 'mobile' })}>
                    <PhoneIcon />
                    <span>360</span>
                  </div>
                </div>
              )}

              <div className='slideshow__responsive-toggle'>
                <CustomSwitch
                  checked={responsiveVisible}
                  callback={() => setResponsiveVisible((previous) => !previous)}
                  onClick={() => setScreenSize({ width: 1920, height: 1080, type: 'desktop' })}
                />
                <span>Responsive</span>
              </div>
            </div>
          </div>
          <div className='slideshow__header-section'>
            <div className='slideshow__action-btns'>
              <div className='slideshow__zoom-controls'>
                <div className='slideshow__zoom-btn' onClick={zoomOut}>
                  <MinusIcon />
                </div>
                <div className='slideshow__zoom-btn' onClick={zoomIn}>
                  <PlusIcon />
                </div>
                <div
                  className='slideshow__zoom-display'
                  onClick={() => {
                    if (!zoomInputVisible) {
                      setZoomInputVisible(true);
                    }
                  }}>
                  <span>{Math.round(zoom * 100)} %</span>
                  <DownArrow />
                  {zoomInputVisible && (
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmitZoomInput();
                      }}>
                      <input
                        ref={zoomInputRef}
                        className='slideshow__zoom-input'
                        title='min 20; max 800'
                        value={zoomInputValue}
                        onChange={(e) => {
                          if (
                            !e.target.value.match(/(^[0-9]+$|^$)/) ||
                            parseInt(e.target.value) > 800
                          ) {
                            return;
                          } else {
                            setzoomInputValue(e.target.value);
                          }
                        }}
                      />
                      <span className='slideshow__zoom-input-suffix'>%</span>
                    </form>
                  )}
                </div>
              </div>

              <div className='btn btn--square' onClick={() => savePresentation(presentation, true)}>
                <div className='btn__content'>
                  <span className='tooltiptext'>Save</span>
                  {saved ? <CheckedIcon className='checked-icon' /> : <SaveIcon />}
                </div>
              </div>
              <div
                className='btn btn--square'
                onClick={() => setShareUrl(SITE_URL + '/play-slideshow/' + presentationId)}>
                <div className='btn__content'>
                  <span className='tooltiptext'>Copy&nbsp;link</span>
                  <ShareIcon />
                </div>
              </div>
              <Link to={`/play-slideshow/${presentation?._id}`} state={{ fromEditing: true }}>
                <div className='btn btn--blue btn--square'>
                  <div className='btn__content btn__content--last'>
                    <span className='tooltiptext'>Play&nbsp;presentation</span>
                    <PlayIcon />
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </header>
        <div className='slideshow__content'>
          <div className='slideshow__sidebar-container'>
            {editingSlide && (
              <>
                <div className='slideshow__layers-sidebar'>
                  <div
                    className='slideshow__layers-heading'
                    onContextMenu={(e) => {
                      e.preventDefault();
                      setSlideTitleDropdownVisible(true);
                    }}>
                    <SliderIcon />
                    {slideTitleInputVisible ? (
                      <input
                        ref={slideTitleInputRef}
                        className='slideshow__layers-heading-input'
                        value={editingSlide.title}
                        onChange={(e) => {
                          dispatch({
                            type: EDIT_SLIDE_PROPERTY,
                            payload: {
                              slideId: editingSlide.id,
                              updates: {
                                title: e.target.value
                              }
                            }
                          });
                        }}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            setSlideTitleInputVisible(false);
                          }
                        }}
                      />
                    ) : (
                      <>
                        <div
                          className='slideshow__layers-heading-title'
                          onDoubleClick={(e) => {
                            setSlideTitleInputVisible(true);
                            dispatch({
                              type: EDIT_SLIDE_PROPERTY,
                              payload: {
                                slideId: editingSlide.id,
                                updates: {
                                  title: e.target.value
                                }
                              }
                            });
                          }}>
                          {editingSlide.title}
                        </div>
                        <DotsIcon
                          onClick={(e) => {
                            e.preventDefault();
                            setSlideTitleDropdownVisible(true);
                          }}
                        />
                      </>
                    )}

                    {slideTitleDropdownVisible && (
                      <div
                        ref={slideTitleDropdownRef}
                        className='slideshow__layers-heading-dropdown'>
                        <span
                          className='p-s'
                          onClick={() => {
                            setSlideTitleInputVisible(true);
                            setSlideTitleDropdownVisible(false);
                          }}>
                          Rename
                        </span>
                        {presentation.data.slides.length > 1 && (
                          <span
                            className='p-s'
                            onClick={() => {
                              setSlideTitleDropdownVisible(false);
                              setDeleteSlideModalVisible(true);
                            }}>
                            Delete
                          </span>
                        )}
                        <span
                          className='p-s'
                          onClick={() => {
                            handleGridOffClick(editingSlide.id, !editingSlide.showGrid);
                          }}>
                          Grid on/off
                        </span>
                      </div>
                    )}
                    {deleteSlideModalVisible && (
                      <DeleteDialog
                        message={'Are you sure you want to delete'}
                        itemName={editingSlide.title}
                        setModalVisible={setDeleteSlideModalVisible}
                        handleDelete={() => {
                          dispatch({
                            type: DELETE_SLIDE,
                            payload: {
                              slideId: editingSlide.id
                            }
                          });
                          setSelectedSlide(null);
                        }}></DeleteDialog>
                    )}
                  </div>
                  <LayersList
                    slides={presentation.data.slides}
                    dispatch={dispatch}
                    slideId={selectedSlide.id}
                    screenSize={screenSize.type}
                    selectedElement={selectedElement}
                    selectedElements={selectedElements}
                  />
                </div>
                <div className='btn btn--block slideshow__add-new-slide-btn' onClick={addNewSlide}>
                  <div className='btn__content'>
                    <PlusIcon />
                    <span>Add new slide</span>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className='slideshow__main'>
            <div
              className='slideshow__preview-container'
              ref={slideContainerRef}
              onClick={(e) => handleClickOutsideSlide(e)}>
              <Slide
                setEditingShapeElement={setEditingShapeElement}
                setZoom={setZoom}
                ref={transformComponentRef}
                initialZoom={initialZoom}
                screenSize={screenSize}
                slides={presentation?.data.slides}
                selectedSlide={selectedSlide}
                setSelectedSlide={setSelectedSlide}
                dispatch={dispatch}
                selectedElement={selectedElement}
                setSelectedElement={setSelectedElement}
                selectedElements={selectedElements}
                setSelectedElements={setSelectedElements}
                target={target}
                setTarget={setTarget}
                targets={targets}
                setTargets={setTargets}
                addElement={addElement}
                editorStyles={editorStyles}
                setEditorStyles={setEditorStyles}
                updateEditorStyles={updateEditorStyles}
                deleteEditorStyles={deleteEditorStyles}
              />
            </div>

            {selectedSlide && (
              <InsertMenu
                addElement={addElement}
                setUnsplashModalVisible={setUnsplashModalVisible}
                setEmbedModalVisible={setEmbedModalVisible}
                slide={editingSlide}
                screenSize={screenSize}
                dispatch={dispatch}
              />
            )}
          </div>
        </div>
        {shareUrl && (
          <div ref={sharePopupRef} className='slideshow__share-url-popup'>
            <span className='slideshow__share-url' style={{ fontSize: '12px' }}>
              {shareUrl}
            </span>
            <span className='p-s slideshow__copy-btn' onClick={shareLink}>
              Copy URL
            </span>
          </div>
        )}
        {unsplashModalVisible && (
          <UnsplashModal
            unsplashModalVisible={unsplashModalVisible}
            setUnsplashModalVisible={setUnsplashModalVisible}
            addElement={addElement}
          />
        )}
        {embedModalVisible && (
          <EmbedModal
            embedModalVisible={embedModalVisible}
            setEmbedModalVisible={setEmbedModalVisible}
            addElement={addElement}
          />
        )}
        {screenTooSmall && (
          <div className='small-screenSize-overlay'>
            <div className='small-screenSize-overlay__notification'>
              <h3>Resolution notice</h3>
              <p>
                We are working on the product to make it available for editing on smaller screens,
                but at the moment we can support editing slides on screens with resolutions larger
                than 1024px.
              </p>
              <p>Expand your screenSize to get rid of this message.</p>
            </div>
          </div>
        )}
      </div>
      {editingShapeElement && (
        <>
          {editingShapeElement.type === 'shape' && (
            <ShapesToolbar
              element={presentation.data.slides
                .find((slide) => slide.id === editingShapeElement.slideId)
                [`${editingShapeElement.screenSize}`].find(
                  (element) => element.id === editingShapeElement.elementId
                )}
              dispatch={dispatch}
              setEditingShapeElement={setEditingShapeElement}
              screenSize={screenSize}
              editingShapeElement={editingShapeElement}
              target={target}
              // setTarget={setTarget}
              deleteHandler={() => {
                dispatch({
                  type: DELETE_ELEMENT,
                  payload: {
                    slideId: selectedSlide.id,
                    elementId: selectedElement
                  }
                });
                setSelectedElement(null);
                setSelectedElements([]);
                setEditingShapeElement(null);
              }}
            />
          )}
          {editingShapeElement.type === 'img' && (
            <ImageToolbar
              element={presentation.data.slides
                .find((slide) => slide.id === editingShapeElement.slideId)
                [`${editingShapeElement.screenSize}`].find(
                  (element) => element.id === editingShapeElement.elementId
                )}
              dispatch={dispatch}
              setEditingShapeElement={setEditingShapeElement}
              screenSize={screenSize}
              editingShapeElement={editingShapeElement}
              target={target}
              deleteHandler={() => {
                dispatch({
                  type: DELETE_ELEMENT,
                  payload: {
                    slideId: selectedSlide.id,
                    elementId: selectedElement
                  }
                });
                setSelectedElement(null);
                setSelectedElements([]);
                setEditingShapeElement(null);
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default Slideshow;
