import { useState, useRef, useEffect, useLayoutEffect } from "react";
import { EDIT_ELEMENT_ON_ALL_SCREENS } from "../../reducers/presentation/actionTypes";
import  { ReactComponent as DownArrowIcon } from '../../icons/backicon.svg'
import  { ReactComponent as SolidIcon } from '../../icons/border-solid.svg'
import  { ReactComponent as DottedIcon } from '../../icons/border-dotted.svg'
import  { ReactComponent as DashedIcon } from '../../icons/border-dashed.svg'
import  { ReactComponent as CornerIcon } from '../../icons/cornerRadius.svg'
import  { ReactComponent as TrashIcon } from '../../icons/trash-2.svg'
import ColorPicker from "../ColorPicker";
import { ChromePicker } from "react-color";
import { SHAPETYPES } from "../../utils/shapes";

const BORDER_ICONS = {
    solid: <SolidIcon />,
    dotted: <DottedIcon />,
    dashed: <DashedIcon />
}

const SHAPETYPES_ARRAY = Object.entries(SHAPETYPES).map(([k, v]) => ({ name: v.name, clipPath: v.clipPath }))

const ShapesToolbar = ({ element, dispatch, setEditingShapeElement, screenSize, editingShapeElement, target, deleteHandler }) => {

    const toolbarRef = useRef()
    const [dropdownVisible, setDropdownVisible] = useState(null);

    const handleClickOutside = (e) => {
        const moveControl = document.querySelector('.moveable-control-box')
        if (toolbarRef.current && (!toolbarRef.current.contains(e.target) || target.contains(e.target) || moveControl.contains(e.target))) {
            setEditingShapeElement(null)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)

        return () => document.removeEventListener('mousedown', handleClickOutside)
    })

    const editElement = (updates) => {
        dispatch({
            type: EDIT_ELEMENT_ON_ALL_SCREENS,
            payload: {
                slideId: editingShapeElement.slideId,
                screenSize: screenSize.type,
                elementId: element.id,
                updates
            }
        })
    }

    useLayoutEffect(() => {
        if (toolbarRef.current) {
            const targetRect = toolbarRef.current.getBoundingClientRect()
            const windowWidth = window.innerWidth

            let x = targetRect.x
            if (x + targetRect.width > windowWidth) {
                x = windowWidth - targetRect.width
                setEditingShapeElement(previous => ({ ...previous, x }))
            }
        }
    }, [element.shapeType])

    return (  
        <div
            ref={toolbarRef}
            className="shapes-toolbar"
            style={{
                top: editingShapeElement.y,
                left: editingShapeElement.x  
            }}    
        >
            <div 
                className={dropdownVisible && dropdownVisible.typeDropdown ? "shapes-toolbar__menu-dropdown shapes-toolbar__menu-dropdown--active shape-type" : "shapes-toolbar__menu-dropdown shape-type"}
                onClick={() => {
                    if (dropdownVisible && dropdownVisible.typeDropdown) {
                        setDropdownVisible(null)
                    } else {
                        setDropdownVisible({ typeDropdown: true })
                    }
                }}
            >
                <div
                    className="shapes-toolbar__menu-dropdown-item"
                >
                    <div
                        style={{
                            clipPath: SHAPETYPES[element.shapeType].clipPath,
                            width: '20px',
                            height: '20px',
                            backgroundColor: '#fff',
                        }}
                    >
                    </div>
                </div>
                
                <DownArrowIcon />
                {dropdownVisible && dropdownVisible.typeDropdown &&
                    <div className="shapes-toolbar__options-container shapes-toolbar__options-container--shape-types">
                        <div className="shapes-toolbar__options">
                            {SHAPETYPES_ARRAY.map(shapeType => (
                                <div
                                    key={shapeType.name}
                                    className={element.shapeType === shapeType.name ? "shapes-toolbar__shape-type-option shapes-toolbar__shape-type-option--selected" : "shapes-toolbar__shape-type-option"}
                                    onClick={() => editElement({ shapeType: shapeType.name })}
                                >
                                    <div
                                        style={{
                                            clipPath: shapeType.clipPath
                                        }}
                                    >
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                }
            </div>
            <div 
                className={dropdownVisible && dropdownVisible.backgroundColorDropdown ? "shapes-toolbar__color-btn shapes-toolbar__color-btn--active" : "shapes-toolbar__color-btn"}
                onClick={() => {
                    if (!dropdownVisible?.backgroundColorDropdown) {
                        setDropdownVisible({ backgroundColorDropdown: true })
                    }
                }}
            >
                <div 
                    className="shapes-toolbar__color-btn-field"
                    style={{
                        backgroundColor: element.shapeColor
                    }}
                >
                </div>
                {dropdownVisible && dropdownVisible.backgroundColorDropdown &&
                    <div className="shapes-toolbar__color-picker-dropdown-container">
                        <div className="color-picker-container">
                            <ChromePicker
                                color={element.shapeColor ?? 'FFF'}
                                onChange={(color) => {
                                    editElement({ shapeColor: color.hex })
                                }}
                            />
                            <span 
                                className="clear-color-btn"
                                onClick={() => editElement({ shapeColor: null })}
                            >
                                Clear color
                            </span>
                        </div>
                    </div>
                }
            </div>
            <div 
                className={dropdownVisible && dropdownVisible.opacityDropdown ? "shapes-toolbar__menu-dropdown shapes-toolbar__menu-dropdown--active slider-display" : "shapes-toolbar__menu-dropdown slider-display"}
                onClick={() => {
                    if (dropdownVisible && dropdownVisible.opacityDropdown) {
                        setDropdownVisible(null)
                    } else {
                        setDropdownVisible({ opacityDropdown: true })
                    }
                }}
            >
                <span className="shapes-toolbar__menu-dropdown-item">{Math.round(element.shapeOpacity * 100)}%</span>
                <DownArrowIcon />
                {dropdownVisible && dropdownVisible.opacityDropdown &&
                    <div className="shapes-toolbar__border-radius-options-container">
                        <div className="shapes-toolbar__slider-container">
                            <span className="shapes-toolbar__slider-minmax">0</span>
                            <input type="range" 
                                min={0}
                                max={100}
                                step={1}
                                value={element.shapeOpacity * 100}
                                onChange={(e) => editElement({ shapeOpacity: e.target.value / 100 })}
                            />
                            <span className="shapes-toolbar__slider-minmax">100</span>
                        </div>
                    </div>
                }
            </div>
            
            {(element.shapeType === 'rectangle' || element.shapeType === 'ellipse') &&
                <>
                    <div className="shapes-toolbar__separator"></div>
                    <div 
                        className={dropdownVisible && dropdownVisible.borderThicknessDropdown ? "shapes-toolbar__menu-dropdown shapes-toolbar__menu-dropdown--active border-thickness" : "shapes-toolbar__menu-dropdown border-thickness"}
                        onClick={() => {
                            if (dropdownVisible && dropdownVisible.borderThicknessDropdown) {
                                setDropdownVisible(null)
                            } else {
                                setDropdownVisible({ borderThicknessDropdown: true })
                            }
                        }}
                    >
                        <span className="shapes-toolbar__menu-dropdown-label">Stroke</span>
                        <span className="shapes-toolbar__menu-dropdown-item">{element.shapeBorderThickness || 'None'}</span>
                        <DownArrowIcon />
                        {dropdownVisible && dropdownVisible.borderThicknessDropdown &&
                            <div className="shapes-toolbar__border-thickness-options-container">
                                <div className="shapes-toolbar__border-thickness-options">
                                    <span 
                                        className="shapes-toolbar__option"
                                        onClick={() => editElement({ shapeBorderThickness: null })}
                                    >
                                        None
                                    </span>
                                    <span 
                                        className="shapes-toolbar__option"
                                        onClick={() => editElement({ shapeBorderThickness: '1px' })}
                                    >
                                        1px
                                    </span>
                                    <span 
                                        className="shapes-toolbar__option"
                                        onClick={() => editElement({ shapeBorderThickness: '2px' })}
                                    >
                                        2px
                                    </span>
                                    <span 
                                        className="shapes-toolbar__option"
                                        onClick={() => editElement({ shapeBorderThickness: '3px' })}
                                    >
                                        3px
                                    </span>
                                    <span 
                                        className="shapes-toolbar__option"
                                        onClick={() => editElement({ shapeBorderThickness: '4px' })}
                                    >
                                        4px
                                    </span>
                                    <span 
                                        className="shapes-toolbar__option"
                                        onClick={() => editElement({ shapeBorderThickness: '5px' })}
                                    >
                                        5px
                                    </span>
                                    <span 
                                        className="shapes-toolbar__option"
                                        onClick={() => editElement({ shapeBorderThickness: '6px' })}
                                    >
                                        6px
                                    </span>
                                    <span 
                                        className="shapes-toolbar__option"
                                        onClick={() => editElement({ shapeBorderThickness: '7px' })}
                                    >
                                        7px
                                    </span>
                                    <span 
                                        className="shapes-toolbar__option"
                                        onClick={() => editElement({ shapeBorderThickness: '8px' })}
                                    >
                                        8px
                                    </span>
                                </div>
                            </div>
                        }
                    </div>
                    <div 
                        className={dropdownVisible && dropdownVisible.borderColorDropdown ? "shapes-toolbar__color-btn shapes-toolbar__color-btn--active" : "shapes-toolbar__color-btn"}
                        onClick={() => {
                            if (!dropdownVisible?.borderColorDropdown) {
                                setDropdownVisible({ borderColorDropdown: true })
                            }
                        }}
                    >
                        <div 
                            className="shapes-toolbar__color-btn-field"
                            style={{
                                backgroundColor: element.shapeBorderColor
                            }}
                        >
                        </div>
                        {dropdownVisible && dropdownVisible.borderColorDropdown &&
                            <div className="shapes-toolbar__color-picker-dropdown-container">
                                <ChromePicker
                                    color={element.shapeBorderColor}
                                    onChange={(color) => {
                                        editElement({ shapeBorderColor: color.hex })
                                    }}
                                />
                            </div>
                        }
                    </div>
                    <div 
                        className={dropdownVisible && dropdownVisible.borderTypeDropdown ? "shapes-toolbar__menu-dropdown shapes-toolbar__menu-dropdown--active border-type" : "shapes-toolbar__menu-dropdown border-type"}
                        onClick={() => {
                            if (dropdownVisible && dropdownVisible.borderTypeDropdown) {
                                setDropdownVisible(null)
                            } else {
                                setDropdownVisible({ borderTypeDropdown: true })
                            }
                        }}
                    >
                        {BORDER_ICONS[`${element.shapeBorderType}`]}
                        {dropdownVisible && dropdownVisible.borderTypeDropdown &&
                            <div className="shapes-toolbar__border-type-options-container">
                                <div className="shapes-toolbar__border-type-options">
                                    <div 
                                        className={element.shapeBorderType === 'solid' ? "shapes-toolbar__border-type-option shapes-toolbar__border-type-option--active" : "shapes-toolbar__border-type-option"}
                                        onClick={() => editElement({ shapeBorderType: 'solid' })}
                                    >
                                        <SolidIcon />
                                    </div>
                                    <div 
                                        className={element.shapeBorderType === 'dashed' ? "shapes-toolbar__border-type-option shapes-toolbar__border-type-option--active" : "shapes-toolbar__border-type-option"}
                                        onClick={() => editElement({ shapeBorderType: 'dashed' })}
                                    >
                                        <DashedIcon />
                                    </div>
                                    <div 
                                        className={element.shapeBorderType === 'dotted' ? "shapes-toolbar__border-type-option shapes-toolbar__border-type-option--active" : "shapes-toolbar__border-type-option"}
                                        onClick={() => editElement({ shapeBorderType: 'dotted' })}
                                    >
                                        <DottedIcon />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    {element.shapeType === 'rectangle' &&
                        <>
                            <div className="shapes-toolbar__separator"></div>
                            <CornerIcon />
                            <div 
                                className={dropdownVisible && dropdownVisible.borderRadiusDropdown ? "shapes-toolbar__menu-dropdown shapes-toolbar__menu-dropdown--active slider-display" : "shapes-toolbar__menu-dropdown slider-display"}
                                onClick={() => {
                                    if (dropdownVisible && dropdownVisible.borderRadiusDropdown) {
                                        setDropdownVisible(null)
                                    } else {
                                        setDropdownVisible({ borderRadiusDropdown: true })
                                    }
                                }}
                            >
                                <span className="shapes-toolbar__menu-dropdown-item">{element.shapeBorderRadius * 4}%</span>
                                <DownArrowIcon />
                                {dropdownVisible && dropdownVisible.borderRadiusDropdown &&
                                    <div className="shapes-toolbar__border-radius-options-container">
                                        <div className="shapes-toolbar__slider-container">
                                            <span className="shapes-toolbar__slider-minmax">0</span>
                                            <input type="range" 
                                                min={0}
                                                max={25}
                                                step={1}
                                                value={element.shapeBorderRadius}
                                                onChange={(e) => editElement({ shapeBorderRadius: e.target.value })}
                                            />
                                            <span className="shapes-toolbar__slider-minmax">100</span>
                                        </div>
                                    </div>
                                }
                            </div>
                    </>
                    }
                </>
            }
            <div className="shapes-toolbar__separator"></div>
            <TrashIcon 
                className="shapes-toolbar__icon"
                onClick={deleteHandler}
            />
        </div>
    );
}
 
export default ShapesToolbar;