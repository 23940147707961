const ColorPicker = ({ colors, callback }) => {
    let counter = 0;

    return (  
        <div className="color-picker">
            <div className="color-picker__colors">
                {colors && colors.length > 0 && colors.map(color => (
                    <div 
                        className="color-picker__color-field"
                        style={{
                            backgroundColor: color  
                        }}    
                        onClick={() => callback(color)}
                        key={counter++}
                    >

                    </div>
                ))}
            </div>
            <div className="color-picker__divider"></div>
            <div className="color-picker__black">
                <div 
                    className="color-picker__color-field-big"
                    onClick={() => callback('#000000')}
                >

                </div>
                <div className="color-picker__black-title">
                    <span className="color-picker__black-hex">#000000</span>
                    <span className="color-picker__black-name">Black</span>
                </div>
            </div>
        </div>
    );
}
 
export default ColorPicker;