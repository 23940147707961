import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import PresentationCard from './PresentationCard';
import { ReactComponent as PlusIcon } from '../icons/plus.svg';
import { ReactComponent as ShareIcon } from '../icons/share.svg';
import { UserContext } from '../App';

const PresentationsList = ({
  SERVER_URL,
  newProjectNotAllowed,
  stackId,
  selected,
  setShowWelcomeMessage
}) => {
  const { user } = useContext(UserContext);
  const [presentations, setPresentations] = useState([]);
  const [addNew, setAddNew] = useState(false);
  const [newStackIsNotAllowed, setNewStackIsNotAllowed] = useState(false);
  const [newPresentationIsNotAllowed, setNewPresentationIsNotAllowed] = useState(false);
  const [onlyOnePresentation, setOnlyOnePresentation] = useState(undefined);
  const [selectedStack, setSelectedStack] = useState(undefined);
  const [tooltipVisible, setTooltipVisible] = useState(false);

  //   useEffect(() => {
  //     if (presentations && selectedStack && newProjectNotAllowed) {
  //       presentations.map((item) => {
  //         if (item._id === selectedStack._id) {
  //           setOnlyOnePresentation(item);
  //         }
  //       });
  //     }
  //   }, [presentations, selectedStack, newProjectNotAllowed]);

  //   useEffect(() => {
  //     if (newProjectNotAllowed && presentations.length > 0) {
  //       setNewPresentationIsNotAllowed(true);
  //     }

  //     if (addNew && newProjectNotAllowed && presentations.length > 0) {
  //       setNewPresentationIsNotAllowed(true);
  //     }
  //   }, [addNew, newProjectNotAllowed, presentations]);

  //fetch selected stack presentations
  const fetchPresentations = async () => {
    try {
      const res = await axios.get(`${SERVER_URL}/presentations/stacks/${stackId}`);
      setPresentations(res.data);
    } catch (err) {}
  };
  //fetch selected stack from db
  useEffect(() => {
    getSelectedStack(user.id).then((r) => {
      setSelectedStack(r);
    });
  }, [user]);

  const getSelectedStack = async (id) => {
    try {
      const res = await axios.get(`${SERVER_URL}/selected-stack/${id}`);
      return res.data.data;
    } catch (err) {}
  };

  useEffect(() => {
    fetchPresentations();
  }, [stackId]);

  return (
    <>
      <div className='main-content__header-inner'>
        <span className='p-s-bold'>
          {selected && selected?.data ? selected?.data.title : 'No selected'} stack
        </span>
        {/*** show for pro user ****/}
        <div className='main-content__header-buttons'>
          <div
            // className={
            //   newPresentationIsNotAllowed
            //     ? 'btn btn--rect-sm btn--blue btn--blue--disabled add-pres-btn'
            //     : 'btn btn--rect-sm btn--blue add-pres-btn'
            // }
            className='btn btn--rect-sm btn--blue add-pres-btn'
            onClick={() => {
              //   if (
              //     !newPresentationIsNotAllowed ||
              //     (newPresentationIsNotAllowed && presentations?.length < 1)
              //   ) {
              //     setAddNew(true);
              //     setPresentations((previous) => [...previous, { _id: '' }]);
              //     setShowWelcomeMessage(false);
              //   }
              setAddNew(true);
              setPresentations((previous) => [...previous, { _id: '' }]);
              setShowWelcomeMessage(false);
            }}
            // onMouseEnter={() => {
            //   if (newPresentationIsNotAllowed && presentations?.length > 0) {
            //     setTooltipVisible(true);
            //   }
            // }}
            // onMouseLeave={() => {
            //   setTooltipVisible(false);
            // }}
          >
            <div className='btn__content'>
              <PlusIcon />
            </div>
            {/* {tooltipVisible &&
                            <div className="tooltip-container">
                                <div className="tooltip-content">
                                    <p>You can’t add more presentations because you are on Free plan and it grants one presentation at time.</p>
                                </div>
                            </div>
                        } */}
          </div>

          <div
            className={
              newPresentationIsNotAllowed && presentations?.length > 0
                ? 'btn btn--rect-sm btn--disabled'
                : 'btn btn--rect-sm'
            }>
            <div className='btn__content'>
              <ShareIcon />
            </div>
          </div>
        </div>
      </div>
      <div className='main-content__presentations-list-container'>
        <div className='main-content__presentations-list'>
          {/*** show for pro user ****/}
          {!newProjectNotAllowed &&
            stackId &&
            presentations?.length > 0 &&
            presentations?.map((presentation) => (
              <PresentationCard
                key={presentation._id}
                presentation={presentation}
                SERVER_URL={SERVER_URL}
                setPresentations={setPresentations}
                addNew={addNew}
                setAddNew={setAddNew}
                stackId={stackId}
                newStackIsNotAllowed={newStackIsNotAllowed}
              />
            ))}
          {/*** for free user to only one last edited presentation ****/}
          {newProjectNotAllowed && onlyOnePresentation && (
            <PresentationCard
              key={onlyOnePresentation._id}
              presentation={onlyOnePresentation}
              SERVER_URL={SERVER_URL}
              setPresentations={setPresentations}
              addNew={addNew}
              setAddNew={setAddNew}
              stackId={selectedStack?._id}
              newStackIsNotAllowed={newStackIsNotAllowed}
            />
          )}
          {/*** for free user to only one last edited presentation ****/}
          {newProjectNotAllowed &&
            !onlyOnePresentation &&
            presentations?.length < 2 &&
            presentations?.map((presentation) => (
              <PresentationCard
                key={presentation._id}
                presentation={presentation}
                SERVER_URL={SERVER_URL}
                setPresentations={setPresentations}
                addNew={addNew}
                setAddNew={setAddNew}
                stackId={stackId}
                newStackIsNotAllowed={newStackIsNotAllowed}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default PresentationsList;
