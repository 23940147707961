import { useState, useEffect, useRef } from "react";
import axios from 'axios';
import DeleteDialog from "./DeleteModal";
import { ReactComponent as DotsIcon } from '../icons/dots-vertical.svg';

const StackName = ({ stack, selectedStack, setSelectedStack, project, setProjects, SERVER_URL }) => {
    const [dropdownVisible, setDropdownVisible] = useState(false)
    const dropdownRef = useRef()

    const [stackTitleInputVisible, setStackTitleInputVisible] = useState(false)
    const stackTitleInputRef = useRef()
    const [stackTitle, setStackTitle] = useState('')
    const [deleteModalVisible,setDeleteModalVisible] = useState(false)

    useEffect(() => {
        if (stack) {
            setStackTitle(stack.data.title)
        }
    }, [stack])

    useEffect(() => {
        if (stackTitleInputVisible && stackTitleInputRef.current) {
            stackTitleInputRef.current.focus()
        }
    }, [stackTitleInputVisible]);

    const deleteStack = async () => {
        try {
            const res = await axios.delete(`${SERVER_URL}/stacks/${stack._id}`)

            setProjects(previous => previous.map(prevProject => {
                if (prevProject._id === project._id) {
                    return {
                        ...prevProject,
                        stack: prevProject.stack.filter(prevStack => prevStack._id !== stack._id)
                    }
                } else {
                    return prevProject
                }
            }))

            setSelectedStack(null)
            window.location.reload();
        } catch (err) {
        }
    }

    const renameStack = async () => {
        if (!stackTitle) {
            setStackTitleInputVisible(false)
            setStackTitle(stack.data.title)
            return
        }

        try {
            const res = await axios.patch(`${SERVER_URL}/stacks/${stack._id}`, { data: { title: stackTitle } })
            setStackTitleInputVisible(false)

            const renamedStack = res.data
            setProjects(previous => previous.map(prevProject => {
                if (renamedStack.projectId === prevProject._id) {
                    return {
                        ...prevProject,
                        stack: prevProject.stack.map(prevStack => {
                            if (prevStack._id === renamedStack._id) {
                                return renamedStack
                            } else {
                                return prevStack
                            }
                        })
                    }
                } else {
                    return prevProject
                }
            }))
        } catch (err) {
        }
    }

    const handleSubmitRenameStack = (e) => {
        e.preventDefault()
        renameStack()
    }

    const handleSubmitRenameStackOnClickOutside = (e) => {
		if (stackTitleInputRef.current && !stackTitleInputRef.current.contains(e.target)) {
			renameStack()
		}
	}

    useEffect(() => {
		if (stackTitleInputVisible) {
			document.addEventListener('click', handleSubmitRenameStackOnClickOutside)
			document.addEventListener('contextmenu', handleSubmitRenameStackOnClickOutside)

            return () => {
                document.removeEventListener('click', handleSubmitRenameStackOnClickOutside)
                document.removeEventListener('contextmenu', handleSubmitRenameStackOnClickOutside)
            }
		}		
	})

    const handleRightClick = (e) => {
        e.preventDefault()

        setDropdownVisible(true)
    }

    const handleClickOutsideDropdown = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setDropdownVisible(false)
        }
    }

    useEffect(() => {
        if (dropdownVisible) {
            document.addEventListener('click', handleClickOutsideDropdown)
			document.addEventListener('contextmenu', handleClickOutsideDropdown)
			
			return () => {
				document.removeEventListener('click', handleClickOutsideDropdown)
				document.removeEventListener('contextmenu', handleClickOutsideDropdown)
			}
        }
    })

    useEffect(() => {
        if (selectedStack && stack && selectedStack.stackId === stack._id) {
            setSelectedStack(stack);
        }
    }, [selectedStack, stack]);

    return (
        <>  
            {stackTitleInputVisible ? (
                <form
                    onSubmit={handleSubmitRenameStack}
                >
                    <input 
                        ref={stackTitleInputRef}
                        type='text'
                        placeholder='Enter stack title'
                        className="project__stack-title-input p-s"
                        value={stackTitle}
                        onChange={(e) => setStackTitle(e.target.value)}
                    />
                </form>
            ) : (
                <div 
                    className={selectedStack && stack && selectedStack._id === stack._id ? "project__stack-name project__stack-name--active p-s" : "project__stack-name p-s"}
                    onClick={() => setSelectedStack(stack)}
                    onContextMenu={handleRightClick}
                >
                    <div 
                        className="project__stack-name-text"
                        onDoubleClick={() => {
                            setStackTitleInputVisible(true)
                        }}
                    >
                        {stack.data.title} <DotsIcon onClick={() => setDropdownVisible(true)} style={{position: 'absolute', right: '0'}}></DotsIcon> </div>
                    {dropdownVisible &&
                        <div 
                            ref={dropdownRef}
                            className="project__stack-name-dropdown"
                        >
                            <span 
                                className="p-s"
                                onClick={() => {
                                    setStackTitleInputVisible(true)
                                    setDropdownVisible(false)
                                }}    
                            >
                                Rename
                            </span>
                            <span 
                                className="p-s"
                                onClick={() => {
                                    setDropdownVisible(false)
                                    setDeleteModalVisible(true)
                                }}
                            >
                                Delete
                            </span>
                        </div>
                    }
                    {deleteModalVisible && <DeleteDialog message={"Are you sure you want to delete"} itemName={stack.data.title} setModalVisible={setDeleteModalVisible} handleDelete={deleteStack}></DeleteDialog>}
                </div>
            )}  
        </>
    );
}
 
export default StackName;