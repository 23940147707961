import { useState, useRef, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ReactComponent as DotsIcon } from '../icons/dots-vertical.svg';
import { secondsToDhms } from '../utils/utils';
import { v4 as uuidv4 } from 'uuid';
import { UserContext } from '../App';
import DeleteDialog from './DeleteModal';

const PresentationCard = ({
	presentation,
	SERVER_URL,
	setPresentations,
	addNew,
	setAddNew,
	stackId,
}) => {
	const [dropdownVisible, setDropdownVisible] = useState(false);
	const dropdownRef = useRef();
	const { user } = useContext(UserContext);

	const [presentationTitleInputVisible, setPresentationTitleInputVisible] =
		useState(false);
	const presentationTitleInputRef = useRef();
	const [presentationTitle, setPresentationTitle] = useState('');
	const [coverImg, setCoverImg] = useState(null);
	const [deleteModalVisible, setDeleteModalVisible] = useState(false);

	useEffect(() => {
		if (presentation?.data?.title && !addNew) {
			setPresentationTitle(presentation.data.title);
		}
	}, [presentation]);

	useEffect(() => {
		if (
			presentationTitleInputVisible &&
			presentationTitleInputRef.current
		) {
			presentationTitleInputRef.current.focus();
		}
	}, [presentationTitleInputVisible]);

	useEffect(() => {
		if (addNew && !presentation._id) {
			setPresentationTitleInputVisible(true);
		}
	}, [addNew, presentation]);

	const deletePresentation = async () => {
		try {
			await axios.delete(
				`${SERVER_URL}/presentations/${presentation?._id}`
			);
			setPresentations((previous) =>
				previous.filter(
					(prevPresentation) =>
						prevPresentation._id !== presentation?._id
				)
			);
			// window.location.reload();
		} catch (err) {}
	};

	const onSubmit = async () => {
		const method = addNew ? 'post' : 'patch';
		const route = addNew
			? 'presentations'
			: `presentations/${presentation._id}`;
		const emptySlide = {
			id: uuidv4(),
			title: 'Slide 1',
			desktop: [],
			laptop: [],
			tablet: [],
			mobile: [],
			layoutNotChanged: ['laptop', 'tablet', 'mobile'],
			backgroundColor: '#fff',
			showGrid: true,
		};
		const submitData = addNew
			? {
					data: {
						data: {
							title: presentationTitle,
							responsive: true,
							slides: [emptySlide],
						},
						stackId,
					},
			  }
			: { data: { ...presentation.data, title: presentationTitle } };

		if (!presentationTitle && !addNew) {
			setPresentationTitleInputVisible(false);
			setPresentationTitle(presentation.data.title);
			return;
		} else if (!presentationTitle && addNew) {
			setAddNew(false);
			setPresentations((previous) =>
				previous.filter((prevPresentation) => prevPresentation._id)
			);
			handleSetSelectedStackToDb();
			return;
		}

		try {
			const res = await axios[`${method}`](
				`${SERVER_URL}/${route}`,
				submitData
			);
			setPresentationTitleInputVisible(false);

			const responsePresentation = res.data;

			setPresentations((previous) =>
				previous.map((presentation) => {
					if (presentation._id === responsePresentation._id) {
						return responsePresentation;
					} else if (!presentation._id && addNew) {
						return responsePresentation;
					} else {
						return presentation;
					}
				})
			);
			if (addNew) setAddNew(false);
		} catch (err) {}
	};

	const duplicatePresentation = async () => {
		const data = { id: presentation?._id };
		try {
			await axios.post(`${SERVER_URL}/presentations/duplicate`, data);
		} catch (err) {}
		handleSetSelectedStackToDb();
		window.location.reload();
	};

	const handleSubmitRenamePresentation = (e) => {
		e.preventDefault();
		onSubmit();
	};

	const handleSubmitRenamePresentationOnClickOutside = (e) => {
		if (
			presentationTitleInputRef.current &&
			!presentationTitleInputRef.current.contains(e.target)
		) {
			onSubmit();
		}
	};

	useEffect(() => {
		if (presentationTitleInputVisible) {
			document.addEventListener(
				'click',
				handleSubmitRenamePresentationOnClickOutside
			);
			document.addEventListener(
				'contextmenu',
				handleSubmitRenamePresentationOnClickOutside
			);

			return () => {
				document.removeEventListener(
					'click',
					handleSubmitRenamePresentationOnClickOutside
				);
				document.removeEventListener(
					'contextmenu',
					handleSubmitRenamePresentationOnClickOutside
				);
			};
		}
	});

	const handleClickOutsideDropdown = (e) => {
		if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
			setDropdownVisible(false);
		}
	};

	useEffect(() => {
		if (dropdownVisible) {
			document.addEventListener('click', handleClickOutsideDropdown);
			document.addEventListener(
				'contextmenu',
				handleClickOutsideDropdown
			);

			return () => {
				document.removeEventListener(
					'click',
					handleClickOutsideDropdown
				);
				document.removeEventListener(
					'contextmenu',
					handleClickOutsideDropdown
				);
			};
		}
	});

	const now = new Date().getTime();
	const lastEdited = presentation?.updatedAt
		? new Date(presentation.updatedAt).getTime()
		: now;
	const editedAgo = secondsToDhms(now - lastEdited);

	const findCoverImg = () => {
		const slide = presentation?.data?.slides?.find(
			(slide) =>
				slide.desktop.findIndex((element) => element.type === 'img') !==
				-1
		);
		if (!slide) {
			return;
		} else {
			const element = slide.desktop.find((el) => el.type === 'img');
			setCoverImg(element.img);
		}
	};

	useEffect(() => {
		findCoverImg();
	}, []);

	// save presentation and stack user clicked last, for displaying on dashboard page
	const handleSetSelectedStackToDb = async () => {
		try {
			const res = await axios.post(
				`${SERVER_URL}/selected-stack/${presentation?._id}/${user.id}`
			);
			const item = res.data.selectedStack.data;
			// set value in local storage if needed
			// if not needed, delete this
			localStorage.setItem('selectedStack', JSON.stringify(item));
			return item;
		} catch (err) {}
	};

	return (
		<div className="presentation-card">
			<Link
				to={`/slideshow/${presentation?._id}`}
				className="presentation-card__image"
			>
				<div
					onMouseEnter={handleSetSelectedStackToDb}
					onClick={handleSetSelectedStackToDb}
					style={{
						height: '100%',
						backgroundImage: coverImg ? `url(${coverImg})` : '',
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'center',
					}}
				></div>
			</Link>

			<div className="presentation-card__content">
				{presentationTitleInputVisible ? (
					<form onSubmit={handleSubmitRenamePresentation}>
						<input
							ref={presentationTitleInputRef}
							type="text"
							placeholder="Enter presentation title"
							className="presentation-card__title-input"
							value={presentationTitle}
							onChange={(e) =>
								setPresentationTitle(e.target.value)
							}
						/>
					</form>
				) : (
					<div className="presentation-card__title">
						{presentation?.data?.title}
					</div>
				)}

				<div className="presentation-card__bottom">
					<span className="presentation-card__edited">
						Last edited: {editedAgo}
					</span>
					<div
						className="presentation-card__options-btn"
						onClick={() => {
							setDropdownVisible(true);
							handleSetSelectedStackToDb();
							console.log();
						}}
					>
						<DotsIcon />
					</div>
				</div>
				{dropdownVisible && (
					<div
						ref={dropdownRef}
						className="presentation-card__options-dropdown"
					>
						<span
							className="p-s"
							onClick={() => {
								setDropdownVisible(false);
								duplicatePresentation();
							}}
						>
							Duplicate
						</span>
						<span
							className="p-s"
							onClick={() => {
								setPresentationTitleInputVisible(true);
								setDropdownVisible(false);
							}}
						>
							Rename
						</span>
						<span
							className="p-s"
							onClick={() => {
								setDropdownVisible(false);
								setDeleteModalVisible(true);
							}}
						>
							Delete
						</span>
					</div>
				)}
				{deleteModalVisible && (
					<DeleteDialog
						message={'Are you sure you want to delete'}
						itemName={presentation.data.title}
						setModalVisible={setDeleteModalVisible}
						handleDelete={deletePresentation}
					></DeleteDialog>
				)}
			</div>
		</div>
	);
};

export default PresentationCard;
