import { useState } from 'react';
import  { ReactComponent as TrashIcon } from '../../icons/trash.svg'
import  { ReactComponent as PlusIcon } from '../../icons/plus.svg'

const MembersSection = ({ user }) => {
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const [newUserTooltipVisible, setNewUserTooltipVisible] = useState(false);
    const [deleteMemberTooltipVisible, setDeleteMemberTooltipVisible] = useState(false);
    return (  
        <div>
            <div className="project__add-new-stack-btn project__add-new-stack-btn--disabled" style={{width: '3%', marginLeft: '95%'}}>
                <div className="btn btn--rect-sm btn--blue btn--blue--disabled add-pres-btn">
                    <div className="btn__content" onMouseEnter={() => setNewUserTooltipVisible(true)} onMouseLeave={() => setNewUserTooltipVisible(false)}>
                        <PlusIcon />
                        {newUserTooltipVisible && 
                            <div className="tooltip-container">
                                <div className="tooltip-content" style={{height: '70%', width:'100px'}}>
                                    <p>Add new user feature is coming soon!</p>
                                </div>
                            </div>}
                    </div>
                </div>
            </div>

        <div
            className='user-account__section'
        >
            <div className="list-block">
                <p className="list-block__title">Member management</p>
                <div className="list-block__items">
                    <div className="table">
                        <div className="table__head">
                            <span>Name</span>
                            <span>Email</span>
                            <span>Permissions</span>
                            <div className="table__empty"></div>
                        </div>
                        <div className="table__row">
                            <span>{user.firstName.concat(' ', user.lastName)}</span>
                            <span>{user.email}</span>
                            <span>{user.role.map(item => capitalizeFirstLetter(item))}</span>
                            {/* TODO uncomment this after Users system is up:*/}
                            {/* TODO after admin gives admin role to someone else, could be deleted */}
                            <div className="table__delete-icon table__delete-icon--disabled" onMouseEnter={() => setDeleteMemberTooltipVisible(true)} onMouseLeave={() => setDeleteMemberTooltipVisible(false)}  key={Math.random()}>
                                <TrashIcon />
                            {/*    {deleteMemberTooltipVisible &&*/}
                            {/*        <div className="tooltip-container">*/}
                            {/*            <div className="tooltip-content" style={{height: '70%', width:'100px'}}>*/}
                            {/*                <p>Delete user feature is coming soon!</p>*/}
                            {/*            </div>*/}
                            {/*        </div>*/}
                            {/*    }*/}
                                </div>
                        </div>
                        {/*<div className="table__row">*/}
                        {/*    <span>James Doe</span>*/}
                        {/*    <span>james@gmail.com</span>*/}
                        {/*    <span>Editor</span>*/}
                        {/*    <div className="table__delete-icon table__delete-icon--disabled">*/}
                        {/*        <TrashIcon />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="table__row">*/}
                        {/*    <span>James Doe</span>*/}
                        {/*    <span>james@gmail.com</span>*/}
                        {/*    <span>Viewer</span>*/}
                        {/*    <div className="table__delete-icon table__delete-icon--disabled" >*/}
                        {/*        <TrashIcon />*/}


                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
}
 
export default MembersSection;