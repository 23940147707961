import { ReactComponent as CloseIcon } from '../../icons/not-close.svg';

const CloseButton = ({ closeToast }) => {
    return (  
        <CloseIcon 
            onClick={closeToast}
        />
    );
}
 
export default CloseButton;