import { useState } from 'react';
import img1 from '../images/welcomeImg1.jpg'
import img2 from '../images/welcomeImg2.jpg'
import img3 from '../images/welcomeImg3.jpg'
import img4 from '../images/welcomeImg4.jpg'
import img5 from '../images/welcomeImg5.jpg'
import img6 from '../images/welcomeImg6.jpg'
import img7 from '../images/welcomeImg7.jpg'
import img8 from '../images/welcomeImg8.jpg'
import img9 from '../images/welcomeImg9.jpg'
import { ReactComponent as PlusIcon } from '../icons/plus.svg';
import { ReactComponent as DotsIcon } from '../icons/dots-vertical.svg';
import { ReactComponent as CloseIcon } from '../icons/close-ins.svg';
import { ReactComponent as NextIcon } from '../icons/next-icon.svg';
import { ReactComponent as PreviousIcon } from '../icons/previous-icon.svg';
import Modal from 'react-modal'

const WelcomeMessage = ({ showWelcomeMessageOnClick, setShowWelcomeMessageOnClick, isClosableOnClick }) => {
    const [page, setPage] = useState(1);

    const handleCloseWelcomeDialogue = () => {
        setShowWelcomeMessageOnClick(false);
        //set flag in session storage to allow showing welcome message only once per session
        sessionStorage.setItem("accessed", "yes");
    }
    return (  
        <Modal
            isOpen={showWelcomeMessageOnClick}
            onRequestClose={() => setShowWelcomeMessageOnClick(false)}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                },
                content: {
                    width: '944px',
                    height: '624px',
                    backgroundColor: '#FFFFFF',
                    borderRadius: '24px',
                    position: 'relative',
                    padding: '45px 64px',
                }
            }}
        >
            <div className="welcome-message">
                {page === 1 &&
                    <>
                        <h1 className="welcome-message__title">Welcome to your dashboard!</h1>
                        <h3 className="welcome-message__subtitle">Lets get you started on your first stackslide!</h3>
                        <div className="welcome-message__examples">
                            <div className="welcome-message__example">
                                <img src={img1} alt="Image 1" className="welcome-message__image" />
                                <p className="welcome-message__text">
                                    <span>First project is created on your behalf, it is like root folder for your project, you can edit its name by clicking on it and you can add as many projects as you need by clicking on </span>
                                    <PlusIcon 
                                        className="welcome-message__icon-blue"
                                    />
                                    <span className="welcome-message__text welcome-message__text--blue"> Add new project.</span>
                                        
                                </p>
                            </div>
                            <div className="welcome-message__example">
                                <img src={img2} alt="Image 2" className="welcome-message__image" />
                                <p className="welcome-message__text">
                                    <span>You can also further organise your slideshows by adding sub folders in your projects, just click </span>
                                    <PlusIcon 
                                        className="welcome-message__icon-blue"
                                    />
                                    <span className="welcome-message__text welcome-message__text--blue">New stack </span>
                                    <span>to try.</span>
                                    
                                </p>
                            </div>
                            <div className="welcome-message__example">
                                <img src={img3} alt="Image 3" className="welcome-message__image" />
                                <p className="welcome-message__text">
                                    <span>To create your very first slideshow deck click on blue plus button. And you can name your slideshow by clicking on its name and see more options by clicking the </span>
                                    <DotsIcon />
                                    <span> icon.</span>
                                </p>
                            </div>
                        </div>
                    </>
                }
                {page === 2 &&
                    <>
                        <h1 className="welcome-message__title">How to use StackSlides</h1>
                        <h3 className="welcome-message__subtitle">This is where you create magic. Here are few tips.</h3>
                        <div className="welcome-message__examples">
                            <div className="welcome-message__example">
                                <img src={img4} alt="Image 1" className="welcome-message__image" />
                                <p className="welcome-message__text">
                                    On top left corner, once you have created your first Stackslide (aka. presentation) you can rename it or see options by clicking on the name.   
                                </p>
                            </div>
                            <div className="welcome-message__example">
                                <img src={img5} alt="Image 2" className="welcome-message__image" />
                                <p className="welcome-message__text">
                                    In center controls let you select screen size. By default all Stackslides are created in 16:9 (the screen standard), but you can now choose and adapt your Stackslides to any screensize you need, and impress no matter where. 
                                </p>
                            </div>
                            <div className="welcome-message__example">
                                <img src={img6} alt="Image 3" className="welcome-message__image" />
                                <p className="welcome-message__text">
                                    On top right corner you will find your general controls, for zoom, save, share and show. Click show to start your Stackslides presentation!
                                </p>
                            </div>
                        </div>
                    </>
                }
                {page === 3 &&
                    <>
                        <h1 className="welcome-message__title">How to use StackSlides</h1>
                        <h3 className="welcome-message__subtitle">This is where you create magic. Here are few tips.</h3>
                        <div className="welcome-message__examples">
                            <div className="welcome-message__example">
                                <img src={img7} alt="Image 1" className="welcome-message__image" />
                                <p className="welcome-message__text">
                                    Click on the main slide to see slide navbar, this gives you overview of all layers and their controls, like title, lock and delete.  
                                </p>
                            </div>
                            <div className="welcome-message__example">
                                <img src={img8} alt="Image 2" className="welcome-message__image" />
                                <p className="welcome-message__text">
                                    Once clicked on the slide on right side center you will find the instrument panel with all available options, for creating slides, like adding images, text and objects. 
                                </p>
                            </div>
                            <div className="welcome-message__example">
                                <img src={img9} alt="Image 3" className="welcome-message__image" />
                                <p className="welcome-message__text">
                                    To add another slide simply click on the Add new slide button on the left side bottom. 
                                </p>
                            </div>
                        </div>
                    </>
                }
                
                {isClosableOnClick &&
                    <div 
                        className='welcome-message__close'
                        onClick={handleCloseWelcomeDialogue}
                    >
                            <CloseIcon />
                    </div>
                }
                {page > 1 &&
                    <div 
                        className="welcome-message__previous"
                        onClick={() => setPage(previous => previous - 1)}    
                    >
                        <PreviousIcon />
                        <span>Back</span>
                    </div>
                }
                <div className="welcome-message__next">
                    <span className="welcome-message__step">{page} of 3</span>
                    <div
                        className={page === 3 ? "welcome-message__next-btn welcome-message__next-btn--disabled" : "welcome-message__next-btn"}
                        onClick={() => {
                            if (page < 3) {
                                setPage(previous => previous + 1)
                            }
                        }}
                    >
                        <span>Next page</span>
                        <NextIcon />
                    </div>
                </div>
            </div>
        </Modal>
    );
}
 
export default WelcomeMessage;