import { useState, useEffect, memo } from 'react';
import Moveable from 'react-moveable';
import { EDIT_ELEMENT, EDIT_SLIDE_PROPERTY } from '../reducers/presentation/actionTypes';
import Element from './Element';
import { toast } from 'react-toastify';
import Notification from './notifications/Notification';
import { notificationIcons } from './notifications/notificationIcons';

const SlideContent = ({
  slide,
  screenSize,
  selectedSlide,
  setSelectedSlide,
  dispatch,
  isMovable,
  setIsMovableMemoed,
  setEditingShapeElement,
  selectedElement,
  selectedElements,
  setSelectedElement,
  setSelectedElements,
  target,
  setTarget,
  targets,
  setTargets,
  setZoomDisabled,
  addElement,
  editorStyles,
  setEditorStyles,
  updateEditorStyles,
  deleteEditorStyles
}) => {
  const [isShiftPressed, setIsShiftPressed] = useState(false);
  const [shouldShowToolbar, setShouldShowToolbar] = useState(false);
  const [draggingOver, setDraggingOver] = useState(false);
  const [secondFrame, setSecondFrame] = useState({ height: undefined, width: undefined });

  const selectElement = (id) => {
    const targetElement = document.querySelector(`.target-${id}`);
    setTarget(targetElement);
    setIsMovableMemoed(true);
    setSelectedElement(id);
  };

  const selectMultipleElements = (selectedElements) => {
    const targetElements = selectedElements.map((id) => document.querySelector(`.target-${id}`));

    setTargets(targetElements);
    setIsMovableMemoed(true);
    setSelectedElements(selectedElements);
  };

  useEffect(() => {
    selectMultipleElements(selectedElements);
  }, [selectedElements]);

  useEffect(() => {
    if (target) {
      setTarget(null);
      setSelectedElement(null);
    }
    if (isMovable) setIsMovableMemoed(false);
  }, [screenSize.type]);

  const handleKeyDown = (e) => {
    if (e.key === 'Shift') {
      setIsShiftPressed(true);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  });
  const handleKeyUp = (e) => {
    if (e.key === 'Shift') {
      setIsShiftPressed(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp);

    return () => document.removeEventListener('keyup', handleKeyUp);
  });

  let moveableElement;
  if (target && isMovable) {
    moveableElement = slide[`${screenSize.type}`].find(
      (element) => target.className === `target-${element.id}`
    );
  }

  const frameX = moveableElement?.translate ? moveableElement.translate[0] : 0;
  const frameY = moveableElement?.translate ? moveableElement.translate[1] : 0;

  const frame = {
    width: moveableElement?.width,
    height: moveableElement?.height,
    translate: [frameX, frameY],
    rotate: moveableElement?.rotate
  };

  const editElement = (updates) => {
    const { width, height } = updates;

    // Update current resolution
    dispatch({
      type: EDIT_ELEMENT,
      payload: {
        slideId: slide.id,
        screenSize: screenSize.type,
        elementId: moveableElement.id,
        updates: {
          ...updates,
          // Ensure width and height are updated for all element types
          width: width !== undefined ? width : null,
          height: height !== undefined ? height : null
        }
      }
    });

    // Remove current resolution from layoutNotChanged array
    if (slide.layoutNotChanged.includes(screenSize.type)) {
      dispatch({
        type: EDIT_SLIDE_PROPERTY,
        payload: {
          slideId: slide.id,
          updates: {
            layoutNotChanged: slide.layoutNotChanged.filter((item) => item !== screenSize.type)
          }
        }
      });
    }
  };

  const bounds =
    moveableElement?.type === 'img'
      ? {
          left: 0 - 0.8 * moveableElement.width,
          top: 0 - 0.8 * moveableElement.height,
          right: screenSize.width + 0.8 * moveableElement.width,
          bottom: screenSize.height + 0.8 * moveableElement.height
        }
      : {
          left: 0,
          top: 0,
          right: screenSize.width,
          bottom: screenSize.height
        };

  const handleDrag = (e) => {
    setSelectedSlide(slide);
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDraggingOver(true);
    } else if (e.type === 'dragleave') {
      setDraggingOver(false);
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDraggingOver(false);

    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      const file = e.dataTransfer.files[0];
      const acceptedImageTypes = ['image/jpeg', 'image/png'];

      if (!acceptedImageTypes.includes(file.type)) {
        return toast.error(
          <Notification message='Wrong file type! Only jpeg/png images are valid.' />,
          {
            icon: notificationIcons.error,
            autoDismissTimeout: 1000,
            closeButton: notificationIcons.close
          }
        );
      }
      if (file.size > 5 * 1024 * 1024) {
        return toast.error(<Notification message='File too big! Max size is 5MB.' />, {
          icon: notificationIcons.error,
          autoDismissTimeout: 1000,
          closeButton: notificationIcons.close
        });
      }

      let imgSrc;
      const reader = new FileReader();
      reader.onload = function () {
        imgSrc = reader.result;
        let imgWidth;
        let imgHeight;

        const url = URL.createObjectURL(file);
        const img = new Image();
        img.src = url;

        img.onload = function () {
          imgWidth = this.width;
          imgHeight = this.height;
          const max = 300;
          const ratio = imgWidth > imgHeight ? imgWidth / max : imgHeight / max;

          addElement('img', {
            src: imgSrc,
            width: imgWidth / ratio,
            height: imgHeight / ratio
          });
        };
      };
      reader.readAsDataURL(file);
    }
  };

  // console.log('slide: ', slide);

  return (
    <div
      className={`slide slide-${slide.id}`}
      style={{
        width: screenSize.width,
        height: screenSize.height,
        marginBottom: '50px',
        marginTop: '50px',
        position: 'relative',
        backgroundColor: slide.backgroundColor ?? '#fff',
        backgroundImage: slide.showGrid
          ? `radial-gradient(circle, rgba(77, 77, 77, 0.3) 1px, ${
              slide.backgroundColor ?? '#fff'
            } 1px)`
          : 'none',
        backgroundSize: '16px 16px',
        border: selectedSlide?.id === slide.id ? '1px solid #3ECAF6' : 'none'
      }}
      onClick={() => {
        if (selectedSlide?.id !== slide.id) {
          setSelectedSlide(slide);
        }
      }}
      // onClick={() => setSelectedSlide(slide)}
      onDragEnter={handleDrag}>
      <div className='slide__slide-name'>
        <div className='slide__name-options-btn'>
          {/* <DotsIcon /> */}
          {/* <div className="slide__name-options-dropdown">

                    </div> */}
        </div>
        <span className='slide__name-title'>{slide.title}</span>
      </div>
      <div className='slide__wrapper'>
        {slide[`${screenSize.type}`] &&
          slide[`${screenSize.type}`].length > 0 &&
          slide[`${screenSize.type}`].map((element) => (
            <Element
              key={element.id}
              element={element}
              isMovable={isMovable}
              setIsMovable={setIsMovableMemoed}
              bounds={bounds}
              target={target}
              selectElement={selectElement}
              selectMultipleElements={selectMultipleElements}
              setTarget={setTarget}
              setTargets={setTargets}
              dispatch={dispatch}
              slideId={slide.id}
              screenSize={screenSize}
              setEditingShapeElement={setEditingShapeElement}
              selectedElement={selectedElement}
              selectedElements={selectedElements}
              setSelectedElement={setSelectedElement}
              setSelectedElements={setSelectedElements}
              shouldShowToolbar={shouldShowToolbar}
              setShouldShowToolbar={setShouldShowToolbar}
              setZoomDisabled={setZoomDisabled}
              editorStyles={editorStyles}
              setEditorStyles={setEditorStyles}
              updateEditorStyles={updateEditorStyles}
              deleteEditorStyles={deleteEditorStyles}
              frame={secondFrame}
              setFrame={setSecondFrame}
            />
          ))}
      </div>
      {/* <div 
                className="slide__add-element-btn"
                onClick={handleClickOnAddBtn}    
            >
                {insertMenuCoords ? <CloseIcon /> : <PlusIcon />}

            </div> */}

      {moveableElement && (
        <Moveable
          keepRatio={isShiftPressed || moveableElement.type === 'img'}
          target={target}
          bounds={bounds}
          snappable={true}
          draggable={true}
          throttleDrag={0}
          resizable={true}
          throttleResize={0}
          rotatable={true}
          rotationPosition={'top'}
          throttleRotate={0}
          onDragStart={({ set }) => {
            if (shouldShowToolbar) {
              setShouldShowToolbar(false);
            }
            set(frame.translate);
          }}
          onDrag={({ beforeTranslate }) => {
            frame.translate = beforeTranslate;
          }}
          onResizeStart={({ setOrigin, dragStart }) => {
            if (shouldShowToolbar) {
              setShouldShowToolbar(false);
            }
            dragStart && dragStart.set(frame.translate);
          }}
          onResize={({ target, width, height, drag }) => {
            frame.translate = drag.beforeTranslate;
            frame.width = width;
            frame.height = height;
            target.style.width = `${width}px`;
            target.style.height = `${height}px`;
            //moveableElement.type === 'text'?`${secondFrame.height || height}px`:`${height}px`;
            // setSecondFrame((prevFrame) => ({
            //     ...prevFrame,
            //     width: width,
            //     height: height
            // }));
          }}
          onRotateStart={({ set }) => {
            if (shouldShowToolbar) {
              setShouldShowToolbar(false);
            }
            set(frame.rotate);
          }}
          onRotate={({ beforeRotate }) => {
            frame.rotate = beforeRotate;
          }}
          onRender={({ target }) => {
            target.style.transform = `translate(${frame.translate[0]}px, ${frame.translate[1]}px) rotate(${frame.rotate}deg)`;
          }}
          onDragEnd={() => {
            if (!shouldShowToolbar) {
              setShouldShowToolbar(true);
            }
            editElement(frame);
          }}
          onRotateEnd={() => {
            if (!shouldShowToolbar) {
              setShouldShowToolbar(true);
            }
            editElement(frame);
          }}
          onResizeEnd={() => {
            if (!shouldShowToolbar) {
              setShouldShowToolbar(true);
            }

            editElement(frame);
          }}
        />
      )}
      {draggingOver && (
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.05)',
            position: 'absolute',
            top: '0',
            left: '0',
            zIndex: '1000'
          }}
          onDragEnter={handleDrag}
          onDragOver={handleDrag}
          onDragLeave={handleDrag}
          onDrop={handleDrop}></div>
      )}
    </div>
  );
};

export default memo(SlideContent);
