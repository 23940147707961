import  { ReactComponent as TextIcon } from '../icons/type.svg'
import  { ReactComponent as ShapeIcon } from '../icons/shapes-full.svg'
import  { ReactComponent as ImgIcon } from '../icons/img.svg'
import  { ReactComponent as EmbedIcon } from '../icons/embed.svg'
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { SET_LAYERS } from '../reducers/presentation/actionTypes';
import LayerItem from './LayerItem';

const iconsList = {
    text: {
        icon: <TextIcon />
    },
    shape: {
        icon: <ShapeIcon />
    },
    img: {
        icon: <ImgIcon />
    },
    embed: {
        icon: <EmbedIcon />
    }
}

const LayersList = ({ slides, dispatch, slideId, screenSize, selectedElement, selectedElements }) => {
    let layers
    if (slides && slides.length > 0) {
        const selectedSlide = slides.find(slide => slide.id === slideId)
        layers = [...selectedSlide[`${screenSize}`]].reverse()
    }

    const onDragEnd = (result) => {
        if (!result.destination) {
            return
        }

        const newLayers = [...layers]
        const [removed] = newLayers.splice(result.source.index, 1)
        newLayers.splice(result.destination.index, 0, removed)

        dispatch({
            type: SET_LAYERS,
            payload: {
                slideId,
                screenSize,
                layers: [...newLayers].reverse()
            }
        })
    }

    return (  
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
            {(provided, snapshot) => (
                <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="layers-list"
                >
                {layers && layers.length > 0 && layers.map((layer, index) => (
                  <LayerItem
                        key={layer.id}
                        layer={layer}
                        index={index}
                        iconsList={iconsList}
                        dispatch={dispatch}
                        slideId={slideId}
                        selectedElement={selectedElement}
                        screenSize={screenSize}
                        selectedElements={selectedElements}
                    />
                ))}
                {provided.placeholder}
                </div>
            )}
        </Droppable>
    </DragDropContext>
        
    );
}
 
export default LayersList;