import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios'
import { SERVER_URL } from '../../url.config'
import  { ReactComponent as Logo } from '../../icons/logo-horizontal master.svg'
import  { ReactComponent as DoneIcon } from '../../icons/done_all_black_24dp.svg'

const EmailVerification = () => {
    const navigate = useNavigate()
    const { emailToken } = useParams()
    const [verified, setVerified] = useState(false);
    const [countdown, setCountdown] = useState(5);

    const verifyEmail = async () => {
        try {
            const res = await axios.get(`${SERVER_URL}/auth/verify-email/${emailToken}`)
            if (res.statusText === 'OK') {
                setVerified(true)
            }
        } catch (error) {
        }
    }

    useEffect(() => {
        verifyEmail()
    }, [])

    useEffect(() => {
        if (verified) {
            const interval = setInterval(() => {
                setCountdown(previous => previous - 1)
            }, 1000);

            return () => clearInterval(interval)
        }
    }, [verified])

    useEffect(() => {
        if (countdown === 0) {
            navigate('/', { replace: true })
        }
    }, [countdown])

    return (  
        <div className="onboarding">
            <div className="onboarding__form-wrap">
                <div className="onboarding__form-box">
                    <Logo className='onboarding__logo' />
                    {verified &&
                        <>
                            <DoneIcon />
                            <div className="onboarding__title">
                                <h3 className="onboarding__heading">Success!</h3>
                                <p className="onboarding__subtitle">Your email was verified successfully.</p>
                            </div>
                            <button 
                                className='onboarding__submit'
                                onClick={() => navigate('/', { replace: true })}    
                            >
                                Go to login page
                            </button>
                            <p className="onboarding__notification">
                                <span>Redirecting to login page in ({countdown}s)</span>
                            </p>
                        </>
                    }
                </div>
                <div className="onboarding__legal">
                    <a href='https://www.stackslides.com/legal/privacy-policy' style={{color: "#FFFFFF", textDecoration: "none"}}>
                        <span>Privacy Policy</span>
                    </a>
                    <a href='https://www.stackslides.com/legal/terms-of-service' style={{color: "#FFFFFF", textDecoration: "none"}}>
                        <span>Terms &amp; Conditions</span>
                    </a>
                </div>
            </div>
        </div>
    );
}
 
export default EmailVerification;