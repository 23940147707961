import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { SERVER_URL } from "../../url.config";
import { UserContext } from "../../App";

const Users = () => {
	const { user } = useContext(UserContext);
	const [users, setUsers] = useState([]);
	const fetchUsers = async () => {
		try {
			const res = await axios.get(`${SERVER_URL}/users`);
			setUsers(res.data);
		} catch (err) {}
	};
	useEffect(() => {
		fetchUsers();
	}, []);
	return (
		<div className="list-block">
			<p className="list-block__title">Users</p>
			<div className="list-block__items">
				<div className="table">
					<div className="table__head">
						<span>First name</span>
						<span>Last name</span>
						<span>e-mail</span>
						<span>Country</span>
						<span>Status</span>
					</div>
					{users
						?.map((item, index) => {
							return (
								<div className="table__row" key={index}>
									<span>{item.firstName}</span>
									<span>{item.lastName}</span>
									<span>{item.email}</span>
									<span>{item.country}</span>
									<span>{item.status}</span>
								</div>
							);
						})
						.reverse()}
				</div>
			</div>
		</div>
	);
};

export default Users;
