import { useState, useEffect } from 'react'
import Modal from 'react-modal'
import { ReactComponent as CloseIcon } from '../icons/close-ins.svg'
import { ReactComponent as BoldIcon } from '../icons/rte-icon-bold.svg'
import { ReactComponent as ItalicIcon } from '../icons/rte-icon-italic.svg'
import { ReactComponent as UnderlineIcon } from '../icons/rte-icon-underline.svg'
import RemoveCustomFormatBtn from './RemoveCustomFormatBtn'

Modal.setAppElement('#root')

const CustomFormatModal = ({ newCustomFormat, setNewCustomFormat, editorStyles, showTextEditor, updateEditorStyles, deleteEditorStyles }) => {
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        if (error) {
            setError(null)
        }
        setNewCustomFormat(previous => ({ ...previous, title: e.target.value }))
    }

    const addCustomFormat = (format) => {
        if (!format.title) {
            setError('Please add custom format title!')
            return
        } else if (editorStyles.some(item => item.title === format.title)) {
            setError('Custom format with this title already exists!')
            return
        }

        const newFormats = [...editorStyles, {...format, inline: 'span'}]
        updateEditorStyles(newFormats);
        // sessionStorage.setItem('editorStyles', JSON.stringify(newFormats))
        // showTextEditor()
        setNewCustomFormat(null)
    }

    // useEffect(() => {
    //     deleteEditorStyles(5);
    // }, []);

    const handleDelete = (title) => {
        const newStyles = editorStyles.filter(style => style.title !== title) 
        updateEditorStyles(newStyles);
    }

    return (  
        <Modal
            isOpen={!!newCustomFormat}
            onRequestClose={() => {
                setNewCustomFormat(null)
                showTextEditor()
            }}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                },
                content: {
                    position: 'relative',
                    padding: '24px',
                    backgroundColor: '#3A405A',
                    borderRadius: '8px',
                    border: '1px solid rgba(44, 44, 44, 0.1)',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.12), 0px 16px 32px rgba(0, 0, 0, 0.12)',
                    overflow: 'visible'
                }
            }}
        >
            <div className="unsplash-modal">
                <div className="unsplash-modal__top-row">
                    <h2 className="unsplash-modal__heading">Custom formats:</h2>
                    <CloseIcon 
                        onClick={() => setNewCustomFormat(null)}
                    />
                </div>
                <div>
                    {editorStyles?.map((format, index) => (
                        <div className="unsplash-modal__formats" key={index}>
                            <span>{format.title}</span>
                            {format.addedByUser &&
                                <RemoveCustomFormatBtn
                                    format={format}
                                    handleDelete={handleDelete}
                                />
                            }  
                        </div>
                    ))}
                </div>
                <div className="unsplash-modal__top-row">
                    <h2 className="unsplash-modal__heading">Current format:</h2>
                </div>
                <div className="unsplash-modal__formats">
                    {newCustomFormat.styles['font-size'] &&
                        <span>Font size: {newCustomFormat.styles['font-size']}</span>
                    }
                    {newCustomFormat.styles['line-height'] &&
                        <span>Line height: {newCustomFormat.styles['line-height']}</span>
                    }
                    <div className="unsplash-modal__biu">
                        {newCustomFormat.styles['font-weight'] && 
                            <div className='unsplash-modal__biu-icon'>
                                <BoldIcon />
                            </div>
                        }           
                        {newCustomFormat.styles['font-style'] && 
                            <div className='unsplash-modal__biu-icon'>
                                <ItalicIcon />
                            </div>
                        }
                        {newCustomFormat.styles['text-decoration'] && 
                            <div className='unsplash-modal__biu-icon'>
                                <UnderlineIcon />
                            </div>
                        }
                    </div>
                </div>
                <div className="unsplash-modal__input-container">
                    <input 
                        className={error ? "unsplash-modal__input unsplash-modal__input--error" : "unsplash-modal__input"}
                        type="text" 
                        value={newCustomFormat.title}
                        onChange={handleChange}
                        placeholder='Enter custom format title'
                    />
                    {error &&
                        <span className="unsplash-modal__input-error-text">{error}</span>
                    }
                </div>
                <div 
                    className="unsplash-modal__choose-btn unsplash-modal__choose-btn--mt"
                    onClick={() => addCustomFormat(newCustomFormat)}    
                >
                    <span>Add custom format</span>
                </div>
            {/*    TODO this is for delete */}
            {/*    <div*/}
            {/*        className="unsplash-modal__choose-btn unsplash-modal__choose-btn--mt"*/}
            {/*        onClick={() => deleteEditorStyles(6)}*/}
            {/*    >*/}
            {/*        <span>DELETE AAAAAAAAA</span>*/}
            {/*    </div>*/}
            </div>
        </Modal>
    );
}
 
export default CustomFormatModal;