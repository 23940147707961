export const SET_PRESENTATION = 'SET_PRESENTATION'
export const EDIT_PRESENTATION = 'EDIT_PRESENTATION'
export const EDIT_ELEMENT = 'EDIT_ELEMENT'
export const EDIT_ELEMENT_ON_ALL_SCREENS = 'EDIT_ELEMENT_ON_ALL_SCREENS'
export const ADD_ELEMENT = 'ADD_ELEMENT'
export const DELETE_ELEMENT = 'DELETE_ELEMENT'
export const ADD_SLIDE = 'ADD_SLIDE'
export const SET_LAYERS = 'SET_LAYERS'
export const EDIT_SLIDE_PROPERTY = 'EDIT_SLIDE_PROPERTY'
export const DELETE_SLIDE = 'DELETE_SLIDE'
export const UNDO = 'UNDO'
export const REDO = 'REDO'
