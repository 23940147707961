import { useState, useRef, useEffect } from 'react';
import axios from 'axios'
import  { ReactComponent as UpArrow } from '../icons/chevron-up.svg'
import  { ReactComponent as DownArrow } from '../icons/chevron-down.svg'
import  { ReactComponent as PlusIcon } from '../icons/plus.svg'
import StackName from './StackName';
import DeleteDialog from './DeleteModal';

const Project = ({
      project,
      selectedProject,
      setSelectedProject,
      selectedStack,
      setSelectedStack,
      SERVER_URL,
      setProjects,
      newProjectNotAllowed,
      setShowWelcomeMessage
    }) => {
    const [stacksListOpen, setStackListOpen] = useState(false)
    const [dropdownVisible, setDropdownVisible] = useState(false)
    const dropdownRef = useRef()

    const [projectTitleInputVisible, setProjectTitleInputVisible] = useState(false)
    const projectTitleInputRef = useRef()
    const [projectTitle, setProjectTitle] = useState('')

    const [addNewStackVisible, setAddNewStackVisible] = useState(false)
    const newStackInputRef = useRef()
    const [newStackTitle, setNewStackTitle] = useState('');
    const [newStackAdded, setNewStackAdded] = useState(false);
    const [newStackNotAllowed, setNewStackNotAllowed] = useState(false);
    const [onlyOneStack, setOnlyOneStack] = useState(undefined);
    const [newStackTooltipVisible, setNewStackTooltipVisible] = useState(false);
    const [deleteModalVisible,setDeleteModalVisible] = useState(false)

    const handleRightClick = (e) => {
        e.preventDefault()

        setDropdownVisible(true)
    }

    const handleClickOutsideDropdown = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            setDropdownVisible(false)
        }
    }

    //set only one stack for free plan
    useEffect(()=> {
        if (project && newProjectNotAllowed && selectedStack) {
            project.stack.map(stack => {
                if (selectedStack.stackId === stack._id) {
                    setOnlyOneStack(stack);
                }
            });
        }
        //if there is no selectedStack
        if (project && newProjectNotAllowed && !selectedStack) {
             setOnlyOneStack(project.stack[0]);
        }
    }, [selectedStack, newProjectNotAllowed, project]);

    useEffect(() => {
        if (newStackAdded && newProjectNotAllowed && selectedProject.stack.length) {
            setNewStackNotAllowed(true);
            setAddNewStackVisible(false);
        }
        if (newProjectNotAllowed && selectedProject?.stack?.length) {
            setNewStackNotAllowed(true);
            setAddNewStackVisible(false);
        }
    }, [newStackAdded, newProjectNotAllowed, selectedProject])

    useEffect(() => {
        if (dropdownVisible) {
            document.addEventListener('click', handleClickOutsideDropdown)
			document.addEventListener('contextmenu', handleClickOutsideDropdown)
			
			return () => {
				document.removeEventListener('click', handleClickOutsideDropdown)
				document.removeEventListener('contextmenu', handleClickOutsideDropdown)
			}
        }
    })

    useEffect(() => {
        if (project) {
            setProjectTitle(project.data.title)
        }
    }, [project])

    useEffect(() => {
        if (projectTitleInputVisible && projectTitleInputRef.current) {
            projectTitleInputRef.current.focus()
        }
    }, [projectTitleInputVisible])
    
    useEffect(() => {
        if (addNewStackVisible && newStackInputRef.current) {
            newStackInputRef.current.focus()
        }
    }, [addNewStackVisible])

    const deleteProject = async () => {
        try {
            const res = await axios.delete(`${SERVER_URL}/projects/${project._id}`)
            setProjects(previous => previous.filter(prevProject => prevProject._id !== project._id))
            setSelectedProject(null);
            window.location.reload();
        } catch (err) {
        }
    }

    const renameProject = async () => {
        if (!projectTitle) {
            setProjectTitleInputVisible(false)
            setProjectTitle(project.data.title)
            return
        }
        try {
            const res = await axios.patch(`${SERVER_URL}/projects/title/${project._id}`, { data: { title: projectTitle } })
            setProjectTitleInputVisible(false)
            
            const renamedProject = res.data

            setProjects(previous => previous.map(project => {
                if (project._id === renamedProject._id) {
                    return {
                        ...project,
                        data: renamedProject.data
                    }
                } else {
                    return project
                }
            }))
        } catch (err) {
        }
    }

    const handleSubmitRenameProject = (e) => {
        e.preventDefault()
        renameProject()
    }

    const handleSubmitRenameProjectOnClickOutside = (e) => {
		if (projectTitleInputRef.current && !projectTitleInputRef.current.contains(e.target)) {
			renameProject()
		}
	}

    useEffect(() => {
		if (projectTitleInputVisible) {
			document.addEventListener('click', handleSubmitRenameProjectOnClickOutside)
			document.addEventListener('contextmenu', handleSubmitRenameProjectOnClickOutside)

            return () => {
                document.removeEventListener('click', handleSubmitRenameProjectOnClickOutside)
                document.removeEventListener('contextmenu', handleSubmitRenameProjectOnClickOutside)
            }
		}		
	})

    const addNewStack = async () => {
        if (!newStackTitle) {
            setAddNewStackVisible(false)
            return 
        }
        const submitData = {
            data: {
                title: newStackTitle
            },
            projectId: project._id
        }

        try {
            const res = await axios.post(`${SERVER_URL}/stacks`, { data: submitData })
            const newStack = {
                ...submitData,
                _id: res.data.id
            }
            setNewStackTitle('')
            setNewStackAdded(true);
            setAddNewStackVisible(false)
            setProjects(previous => previous.map(prevProject => {
                if (prevProject._id === project._id) {
                    return {
                        ...prevProject,
                        stack: [
                            ...prevProject.stack,
                            newStack
                        ]
                    }
                } else {
                    return prevProject
                }
            }))
            setSelectedStack(newStack)
        } catch (err) {
        }
    }

    const handleSubmitNewStack = (e) => {
        e.preventDefault()
        addNewStack()
    }

    const handleSubmitNewStackOnClickOutside = (e) => {
		if (newStackInputRef.current && !newStackInputRef.current.contains(e.target)) {
			addNewStack()
		}
	}

	useEffect(() => {
		if (addNewStackVisible) {
			document.addEventListener('click', handleSubmitNewStackOnClickOutside)
			document.addEventListener('contextmenu', handleSubmitNewStackOnClickOutside)

            return () => {
                document.removeEventListener('click', handleSubmitNewStackOnClickOutside)
                document.removeEventListener('contextmenu', handleSubmitNewStackOnClickOutside)
            }
		}		
	})

    useEffect(() => {
        if (selectedProject && selectedStack) {
            selectedProject.presentation.map((item) => {
                if (item.stackId === selectedStack.stackId) {
                    setStackListOpen(true)
                }
            })
        }
    }, [selectedProject]);

    return (  
        <div 
            className={selectedProject && selectedProject._id === project._id ? 'project project--active' : 'project'}
            onClick={() => setSelectedProject(project)}    
        >
            {projectTitleInputVisible ? (
                <form
                    onSubmit={handleSubmitRenameProject}
                >
                    <input 
                        ref={projectTitleInputRef}
                        type='text'
                        placeholder='Enter project title'
                        className="left-sidebar__project-title-input p-s-bold"
                        value={projectTitle}
                        onChange={(e) => setProjectTitle(e.target.value)}
                    />
                </form>
            ) : (
                <div 
                    className="project__title"
                    onClick={() => {
                        if (!dropdownVisible) {
                            setStackListOpen(previous => !previous)
                        }
                    }}   
                    onDoubleClick={(e) => {
                        setProjectTitleInputVisible(true)
                        if(e.key === 'Enter'){
                            setProjectTitleInputVisible(false)
                        }
                    }}
                    onContextMenu={handleRightClick}
                >
                    <div className="project__title-text p-s-bold">{project.data.title}</div>
                    {stacksListOpen ? <UpArrow /> : <DownArrow />}
                    {dropdownVisible &&
                        <div 
                        ref={dropdownRef}
                        className="project__project-title-dropdown"
                    >
                        <span 
                            className="p-s"
                            onClick={() => {
                                setProjectTitleInputVisible(true)
                                setDropdownVisible(false) 
                            }}
                        >
                            Rename
                        </span>
                        <span 
                            className="p-s"
                            onClick={() => {
                                setDropdownVisible(false)
                                setDeleteModalVisible(true)
                            }}
                        >
                            Delete
                        </span>
                    </div>
                    }
                    {deleteModalVisible && <DeleteDialog message={"are you sure you want to delete"} itemName={projectTitle} setModalVisible={setDeleteModalVisible} handleDelete={deleteProject}></DeleteDialog>}
                    
                </div>
            )}
           
            <div className="project__stacks">
                {!newProjectNotAllowed && !newStackNotAllowed && stacksListOpen &&
                    <>
                        {project.stack.length > 0 && project.stack.map(stack => (
                            <StackName
                                key={stack._id}
                                stack={stack}
                                selectedStack={selectedStack}
                                setSelectedStack={setSelectedStack}
                                project={project}
                                setProjects={setProjects}
                                SERVER_URL={SERVER_URL}
                                setShowWelcomeMessage={setShowWelcomeMessage}
                            />
                        ))}
                        {addNewStackVisible && !newProjectNotAllowed &&
                            <form
                                onSubmit={handleSubmitNewStack}
                            >
                                <input 
                                    ref={newStackInputRef}
                                    type='text'
                                    placeholder='Enter stack name'
                                    className="project__stack-title-input p-s"
                                    value={newStackTitle}
                                    onChange={(e) => setNewStackTitle(e.target.value)}
                                />
                            </form>
                        }
                        {selectedProject &&
                          selectedProject._id === project._id &&
                          !newStackNotAllowed &&
                          !newProjectNotAllowed && 
                            <div 
                                className="project__add-new-stack-btn"
                                onClick={() => setAddNewStackVisible(true)}    
                            >
                                <PlusIcon />
                                <span>New stack</span>
                            </div>
                        }
                    </>
                }
                {newProjectNotAllowed && addNewStackVisible && project?.stack.length < 2 &&
                  <form
                    onSubmit={handleSubmitNewStack}
                  >
                      <input
                        ref={newStackInputRef}
                        type='text'
                        placeholder='Enter stack name'
                        className="project__stack-title-input p-s"
                        value={newStackTitle}
                        onChange={(e) => setNewStackTitle(e.target.value)}
                      />
                  </form>
                }
                {newProjectNotAllowed && stacksListOpen &&
                  <>
                      {onlyOneStack &&
                           <StackName
                             key={onlyOneStack._id}
                             stack={onlyOneStack}
                             selectedStack={selectedStack}
                             setSelectedStack={setSelectedStack}
                             project={project}
                             setProjects={setProjects}
                             SERVER_URL={SERVER_URL}
                           />
                      }
                    <div
                        className={newProjectNotAllowed && stacksListOpen && project?.stack.length < 1 ? "project__add-new-stack-btn" : "project__add-new-stack-btn project__add-new-stack-btn--disabled"}
                        onClick={() => {
                            if (newProjectNotAllowed && stacksListOpen && project?.stack.length < 1) {
                                setAddNewStackVisible(true)
                            }
                        }}
                        onMouseEnter={() => {
                            if (newStackNotAllowed && project?.stack.length >= 1) setNewStackTooltipVisible(true)
                        }}
                        onMouseLeave={() => setNewStackTooltipVisible(false)}
                    >
                        <PlusIcon />
                        <span>New stack</span>
                        {newStackTooltipVisible &&
                            <div className="tooltip-container">
                                <div className="tooltip-content">
                                    <p>You can’t add more stacks because you are on Free plan and it grants one stack at time.</p>
                                </div>
                            </div>
                        }
                    </div>
                  </>
                }
            </div>
        </div>
    );
}
 
export default Project;