import Users from "./Users";
import MainNav from "../MainNav";
import Subscriptions from "./Subscriptions";
import { useState } from "react";

const AdminDashboard = () => {
	const [section, setSection] = useState("users");
	return (
		<div className="user-account">
			<MainNav userPage={true} />
			<div className="user-account__main">
				<div className="user-account__header">
					<div>
						<h5 className="user-account__title">Admin Dashboard</h5>
						<p className="user-account__subtitle">
							View users and active subscriptions
						</p>
					</div>
					<div className="user-account__sections">
						<div
							className={
								section === "users"
									? "user-account__section-tab user-account__section-tab--active"
									: "user-account__section-tab"
							}
							onClick={() => setSection("users")}
						>
							<span>Users</span>
						</div>
						<div
							className={
								section === "subscriptions"
									? "user-account__section-tab user-account__section-tab--active"
									: "user-account__section-tab"
							}
							onClick={() => setSection("subscriptions")}
						>
							<span>Subscriptions</span>
						</div>
					</div>
				</div>

				{section === "users" && <Users />}
				{section === "subscriptions" && <Subscriptions />}
			</div>
		</div>
	);
};

export default AdminDashboard;
