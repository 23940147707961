import { useState } from 'react'
import Modal from 'react-modal'
import { ReactComponent as CloseIcon } from '../icons/close-ins.svg'

Modal.setAppElement('#root')

const EmbedModal = ({ embedModalVisible, setEmbedModalVisible, addElement }) => {
    const [embedCode, setEmbedCode] = useState('');

    return (  
        <Modal
            isOpen={embedModalVisible}
            onRequestClose={() => setEmbedModalVisible(false)}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                },
                content: {
                    position: 'relative',
                    padding: '24px',
                    backgroundColor: '#3A405A',
                    borderRadius: '8px',
                    border: '1px solid rgba(44, 44, 44, 0.1)',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.12), 0px 16px 32px rgba(0, 0, 0, 0.12)'
                }
            }}
        >
            <div className="embed-modal">
                <div className="embed-modal__top-row">
                    <h2 className="embed-modal__heading">Embed element</h2>
                    <CloseIcon 
                        onClick={() => setEmbedModalVisible(false)}
                    />
                </div>
                <textarea
                    placeholder='Paste embed code here'
                    className='embed-modal__textarea'
                    value={embedCode}
                    onChange={(e) => setEmbedCode(e.target.value)}
                />
                {embedCode &&
                    <div 
                        className="unsplash-modal__choose-btn"
                        onClick={() => {
                            addElement('embed', { src: embedCode })
                            setEmbedModalVisible(false)
                        }}    
                    >
                        <span>Embed</span>
                    </div>
                }
            </div>
            
        </Modal>
    );
}
 
export default EmbedModal;