import axios from 'axios';
import { useEffect, useState, useCallback } from 'react';
import Modal from 'react-modal'
import { SERVER_URL } from '../url.config'
import { debounce } from '../utils/utils'
import InfiniteScroll from 'react-infinite-scroll-component'
import { ReactComponent as CloseIcon } from '../icons/close-ins.svg'
import { ReactComponent as SearchIcon } from '../icons/search.svg'

Modal.setAppElement('#root')

const UnsplashModal = ({ unsplashModalVisible, setUnsplashModalVisible, addElement }) => {
    const [images, setImages] = useState([]);
    const [unsplashOptions, setUnsplashOptions] = useState({ isLoading: false, page: 1 });
    const [searchTerm, setSearchTerm] = useState('random');
    const [stateForRefetching, setStateForRefetching] = useState(1);
    const [selectedImage, setSelectedImage] = useState(null);

    const getUnsplashImages = async () => {
        try {
            const res = await axios.get(`${SERVER_URL}/unsplash?query=${searchTerm}&page=${unsplashOptions.page}`)
            const result = res.data

            const mappedImages = result.response.results.map(image => ({
                id: image.id,
                small: image.urls.small,
                regular: image.urls.regular,
                width: image.width,
                height: image.height
            }))

            setImages(mappedImages)
            setUnsplashOptions(previous => ({ 
                isLoading: false, 
                page: previous.page + 1, 
                total: result.response.total 
            }))
        } catch (err) {
        }
    }

    const fetchMoreUnsplash = async () => {
        if (unsplashOptions.isLoading) {
            return
        }
        setUnsplashOptions(previous => ({ ...previous, isLoading: true }))
        try {
            const res = await axios.get(`${SERVER_URL}/unsplash?query=${searchTerm}&page=${unsplashOptions.page}`)
            if (res.data.status === 200) {
                const result = res.data
                const mappedImages = result.response.results.map(image => ({
                    id: image.id,
                    small: image.urls.small,
                    regular: image.urls.regular,
                    width: image.width,
                    height: image.height
                }))
                setImages(previous => [...previous, ...mappedImages])
                setUnsplashOptions(previous => ({
                    ...previous,
                    isLoading: false,
                    page: previous.page + 1
                }))
            }
        } catch (err) {
        }
    }

    useEffect(() => {
        getUnsplashImages()
    }, [stateForRefetching])

    const handleChangeDebounced = useCallback(debounce((e) => {
        const scrollableDiv = document.querySelector('#scrollableDiv')
        if (scrollableDiv) {
            scrollableDiv.scrollTo(0, 0)
        }

        setSearchTerm(e.target.value)
        setStateForRefetching(previous => previous + 1)
    }, 1000), [])
    const handleChange = (e) => {
        handleChangeDebounced(e)
    }

    const chooseImage = (image) => {
        const imgWidth = image.width
        const imgHeight = image.height
        const max = 300
        const ratio = imgWidth > imgHeight ? imgWidth / max : imgHeight / max

        addElement('img', {
            src: image.regular,
            width: imgWidth / ratio,
            height: imgHeight / ratio
        })
        setUnsplashModalVisible(false)
    }

    return (  
        <Modal
            isOpen={unsplashModalVisible}
            onRequestClose={() => setUnsplashModalVisible(false)}
            style={{
                overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.6)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                },
                content: {
                    position: 'relative',
                    padding: '24px',
                    backgroundColor: '#3A405A',
                    borderRadius: '8px',
                    border: '1px solid rgba(44, 44, 44, 0.1)',
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.12), 0px 16px 32px rgba(0, 0, 0, 0.12)'
                }
            }}
        >
            <div className="unsplash-modal">
                <div className="unsplash-modal__top-row">
                    <h2 className="unsplash-modal__heading">Unsplash fotos</h2>
                    <CloseIcon 
                        onClick={() => setUnsplashModalVisible(false)}
                    />
                </div>

                <div className="unsplash-modal__input-container">
                    <input 
                        className="unsplash-modal__input"
                        type="text" 
                        onChange={handleChange}
                        placeholder='Search images'
                    />
                    <div className="unsplash-modal__search-icon">
                        <SearchIcon />
                    </div>
                </div>
                
                <div 
                    id="scrollableDiv" 
                    className="unsplash-modal__scrollable"
                >
                    <InfiniteScroll
                        dataLength={images.length}
                        next={fetchMoreUnsplash}
                        hasMore={images.length < unsplashOptions.total}
                        loader={<h4>Loading...</h4>}
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '4px'
                        }}
                        scrollableTarget="scrollableDiv"
                    >
                        {images && images.length > 0 && images.map(image => (
                            <div
                                key={image.id}
                                style={{
                                    width: '160px',
                                    height: '213px',
                                    backgroundImage: `url(${image.small})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    cursor: 'pointer',
                                    border: selectedImage && selectedImage.id === image.id ? '2px solid #3ECAF6' : 'none'
                                }}
                                onClick={() => setSelectedImage(image)}
                            >

                            </div>
                        ))}
                    </InfiniteScroll>
                </div>
                
                {selectedImage &&
                    <div 
                        className="unsplash-modal__choose-btn"
                        onClick={() => chooseImage(selectedImage)}    
                    >
                        <span>Choose image</span>
                    </div>
                }
            </div>
        </Modal>
    );
}
 
export default UnsplashModal;