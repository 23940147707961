import { useState, useEffect, useRef } from "react";
import { Draggable } from "react-beautiful-dnd";
import { DELETE_ELEMENT, EDIT_ELEMENT_ON_ALL_SCREENS, EDIT_ELEMENT } from "../reducers/presentation/actionTypes";
import { ReactComponent as DotsIcon } from '../icons/dots-vertical.svg';

const LayerItem = ({ layer, index, iconsList, dispatch, slideId, selectedElement, screenSize, selectedElements }) => {
    const [elementNameInputVisible, setElementNameInputVisible] = useState(false);
    const [elementDropdownVisible, setElementDropdownVisible] = useState(false);
    const elementDropdownRef = useRef()
    const elementNameInputRef = useRef()


    const handleSubmit = (e) => {
        e.preventDefault()
        if (!layer.name) {
            return
        }
        
        setElementNameInputVisible(false)
    }

    useEffect(() => {
        if (elementNameInputVisible && elementNameInputRef.current) {
            elementNameInputRef.current.focus()
        }
    }, [elementNameInputVisible])

    const handleClickOutside = (e) => {
        if (!layer.name) {
            return
        }

        if (elementNameInputRef && !elementNameInputRef.current.contains(e.target)) {
            
            setElementNameInputVisible(false)
        }
        
    }

    useEffect(() => {
        if (elementNameInputVisible) {
            document.addEventListener('click', handleClickOutside)

            return () => document.removeEventListener('click', handleClickOutside)
        }
    })

    const handleClickOutsideDropdown = (e) => {
        if (elementDropdownRef.current && !elementDropdownRef.current.contains(e.target)) {
            setElementDropdownVisible(false)
        }
    }

    useEffect(() => {
        if (elementDropdownVisible) {
            document.addEventListener('click', handleClickOutsideDropdown)
			document.addEventListener('contextmenu', handleClickOutsideDropdown)
			
			return () => {
				document.removeEventListener('click', handleClickOutsideDropdown)
				document.removeEventListener('contextmenu', handleClickOutsideDropdown)
			}
        }
    })

    const handleHideShow = () => {
        const resolutions = ['desktop', 'laptop', 'tablet', 'mobile']
        const filteredResolutions = resolutions.filter((resolution, index) => resolutions.indexOf(screenSize) <= index)

        filteredResolutions.forEach(resolution => {
            dispatch({
                type: EDIT_ELEMENT,
                payload: {
                    slideId,
                    screenSize: resolution,
                    elementId: layer.id,
                    updates: {
                        hidden: !layer.hidden
                    }
                }
            })
        })
        setElementDropdownVisible(false)
    }

    return (
        <Draggable key={layer.id} draggableId={layer.id} index={index}>
            {(provided, snapshot) => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    
                    className={layer.id === selectedElement ? "layers-list__item layers-list__item--selected" : "layers-list__item"}
                    style={{
                        boxShadow: snapshot.isDragging ? '0px 4px 8px rgba(0, 0, 0, 0.15)' : '',
                        borderRadius: '4px',
                        ...provided.draggableProps.style
                    }}
                    onDoubleClick={() => setElementNameInputVisible(true)}
                >
                    {iconsList[`${layer.type}`].icon}

                    {elementNameInputVisible ? (
                        <form
                            onSubmit={handleSubmit}
                        >
                            <input 
                                type="text" 
                                className="layers-list__element-input" 
                                ref={elementNameInputRef}
                                value={(layer?.textContent?.match(/<span[^>]*>(.*?)<\/span>|<p[^>]*>(.*?)<\/p>/)?.[1] || layer?.textContent?.replace(/<\/?[^>]+(>|$)/g, '').match(/\b\w+\b/)?.[0] || layer.name)}

                                onChange={(e) => {
                                    dispatch({
                                        type: EDIT_ELEMENT_ON_ALL_SCREENS,
                                        payload: {
                                            slideId,
                                            elementId: layer.id,
                                            updates: {
                                                name: e.target.value
                                            }
                                        }
                                    })
                                }} 
                            />
                        </form>
                    ) : (
                        <div 
                            className="layers-list__type"
                            onContextMenu={(e) => {
                                e.preventDefault()
                                setElementDropdownVisible(true)
                            }}    
                        >
                            <span>{(layer?.textContent?.match(/<span[^>]*>(.*?)<\/span>|<p[^>]*>(.*?)<\/p>/)?.[1] || layer?.textContent?.replace(/<\/?[^>]+(>|$)/g, '').match(/\b\w+\b/)?.[0] || layer.name)}</span>
                        </div>
                    )}
                    

                    {elementDropdownVisible &&
                        <div 
                            ref={elementDropdownRef}
                            className="slideshow__layers-heading-dropdown"
                        >
                            <span 
                                className="p-s"
                                onClick={() => {
                                    setElementNameInputVisible(true)
                                    setElementDropdownVisible(false)
                                }}
                            >
                                Rename
                            </span>
                            <span 
                                className="p-s"
                                onClick={() => {
                                    setElementDropdownVisible(false)
                                    dispatch({
                                        type: DELETE_ELEMENT,
                                        payload: {
                                            slideId,
                                            elementId: layer.id
                                        }
                                    })
                                }}
                            >
                                Delete
                            </span>
                            <span 
                                className="p-s"
                                onClick={handleHideShow}
                            >
                                {layer.hidden ? 'Show' : 'Hide'}
                            </span>
                        </div>
                    }
                    <DotsIcon 
                        className='layers-list__more-icon'
                        onClick={(e) => {
                            e.preventDefault()
                            setElementDropdownVisible(true)
                        }}   
                    />
                </div>
            )}
        </Draggable>
    );
}
 
export default LayerItem;