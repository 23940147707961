import { useContext, useState, useEffect } from "react";
import PaymentForm from '../PaymentForm';
import  { ReactComponent as TrashIcon } from '../../icons/trash.svg'
import  { ReactComponent as DownloadIcon } from '../../icons/download.svg'
import  { ReactComponent as CheckIcon } from '../../icons/c-check.svg'
import  { ReactComponent as PlusIcon } from '../../icons/i-add-1.svg'
import { ReactComponent as CloseIcon } from '../../icons/close-ins.svg'
import { UserContext } from '../../App';
import axios from 'axios';
import { SERVER_URL } from '../../url.config';
import Modal from 'react-modal'

const BillingSection = () => {
    const { user } = useContext(UserContext);
    const [accountPlan, setAccountPlan] = useState('free');
    const [paymentTerm, setPaymentTerm] = useState('yearly');
    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const [subEndDate, setSubEndDate] = useState(null);
    const [subscriptionValid, setSubscriptionValid] = useState(false);
    const [downloadTooltipVisible, setDownloadTooltipVisible] = useState(false);

    useEffect(() => {
        fetchSubscriptions();
    }, []);

    useEffect(() => {
        if (new Date(Number(user.endDateSubscription)).getTime() > Date.now()) {
            setAccountPlan('pro')
            setSubscriptionValid(true)
        }
    }, [user, setSubscriptionValid, setAccountPlan])

    const fetchSubscriptions = async () => {
        try {
            const res = await axios.get(`${SERVER_URL}/subscriptions/${user.id}`);
            setSubscriptions(res.data);
        } catch (err) {
        }
    }

    console.log('USER ACC PLAN', user);

    return (  
        <div
            className='user-account__section'
        >
            {isPaymentModalOpen &&
            <PaymentForm
              isPaymentModalOpen={isPaymentModalOpen}
              setIsPaymentModalOpen={setIsPaymentModalOpen}
              accountPlan={accountPlan}
              paymentTerm={paymentTerm}
              setSubEndDate={setSubEndDate}
            />
            }
            <div className="list-block">
                <p className="list-block__title">Account plan</p>
                <div className="list-block__items">
                    <div className="account-plans">
                        <div 
                            className={subscriptionValid ? 'account-plan account-plan--disabled' : accountPlan === 'free' ? 'account-plan account-plan--active' : 'account-plan'}
                            onClick={() => {
                                if (!subscriptionValid) {
                                    setAccountPlan('free')
                                }
                            }}
                        >
                            <div className="account-plan__main">
                                <span className="account-plan__label">Account plan</span>
                                <h5 className="account-plan__type">Free</h5>
                                <span className="account-plan__price">0.00 EUR</span>
                            </div>
                            <div className="account-plan__features">
                                <div className="account-plan__feature">
                                    <CheckIcon />
                                    <span>Slide creation 1</span>
                                </div>
                                <div className="account-plan__feature">
                                    <CheckIcon />
                                    <span>Slide sharing</span>
                                </div>
                                <div className="account-plan__feature">
                                    <CheckIcon />
                                    <span>Unlimited members</span>
                                </div>
                            </div>
                        </div>
                        <div 
                            className={accountPlan === 'pro' ? 'account-plan account-plan--active' : 'account-plan'}
                            onClick={() => setAccountPlan('pro')}
                        >
                            <div className="account-plan__main">
                                <span className="account-plan__label">Account plan</span>
                                <h5 className="account-plan__type">Pro</h5>
                                <span className="account-plan__price">
                                    {paymentTerm === 'monthly' ? '4.00 EUR' : '43.00 EUR'}
                                </span>
                            </div>
                            <div className="account-plan__features">
                                <div className="account-plan__feature">
                                    <CheckIcon />
                                    <span>Unlimited slides</span>
                                </div>
                                <div className="account-plan__feature">
                                    <CheckIcon />
                                    <span>Slide sharing</span>
                                </div>
                                <div className="account-plan__feature">
                                    <CheckIcon />
                                    <span>Unlimited members</span>
                                </div>
                            </div>
                            <div className="account-plan__switch">
                                <div 
                                    className={paymentTerm === 'yearly' ? "account-plan__choice account-plan__choice--active" : "account-plan__choice"}
                                    onClick={() => setPaymentTerm('yearly')}
                                >
                                    Yearly
                                </div>
                                <div 
                                    className={paymentTerm === 'monthly' ? "account-plan__choice account-plan__choice--active" : "account-plan__choice"}
                                    onClick={() => setPaymentTerm('monthly')}
                                >
                                    Monthly
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {accountPlan === 'pro' &&
                <>
                    <div className="list-block">
                       <p className="list-block__title">Billing method</p>
                       <div className="list-block__items">
                           <div className="table">
                               <div className="table__head">
                                   <span>Name</span>
                                   <span>Card number</span>
                                   <span>Expiry</span>
                                   <div className="table__empty"></div>
                               </div>
                               <div className="table__row">
                                   <span>{user?.firstName} {user?.lastName}</span>
                                   <span>**** **** **** 1234</span>
                                   <span>06/26</span>
                                   <div className="table__delete-icon">
                                       <TrashIcon />
                                   </div>
                               </div>
                               <div className="table__add-btn">
                                   <PlusIcon />
                                   <span>Add new</span>
                               </div>
                           </div>
                       </div>
                    </div>

                    <div className="list-block">
                        <p className="list-block__title">Invoices</p>
                        <div className="list-block__items">
                            <div className="table">
                                <div className="table__head">
                                    <span>Date</span>
                                    <span>Users</span>
                                    <span>Total</span>
                                    <span>Status</span>
                                    <div className="table__empty"></div>
                                </div>
                                {subscriptions?.map((item, index) => {
                                return (
                                  <div className="table__row" key={index}>
                                      <span>{item.startDateSubscription}</span>
                                      <span>1</span>
                                      <span>{item.amount / 100} EUR</span>
                                      <span>Paid until: {item.endDateSubscription}</span>
                                      <div className="table__delete-icon table__delete-icon--disabled" onMouseEnter={() => setDownloadTooltipVisible(true)} onMouseLeave={() => setDownloadTooltipVisible(false)}>
                                          <DownloadIcon />
                                          {/*{downloadTooltipVisible &&*/}
                                          {/*    <div className="tooltip-container">*/}
                                          {/*        <div className="tooltip-content" style={{height: '70%', width:'100px'}}>*/}
                                          {/*            <p>Feature is coming soon!</p>*/}
                                          {/*        </div>*/}
                                          {/*    </div>*/}
                                          {/*}*/}
                                      </div>
                                  </div>
                                )}).reverse()
                              }

                            </div>
                        </div>
                    </div>

                    <button className='onboarding__submit' style={{maxWidth: '150px'}} onClick={() => setIsPaymentModalOpen(!isPaymentModalOpen)}>Pay now</button>
                </>
            }
            {subEndDate &&
                <Modal
                    isOpen={!!subEndDate}
                    onRequestClose={() => {
                        setSubEndDate(null)
                        
                    }}
                    style={{
                        overlay: {
                            backgroundColor: 'rgba(0, 0, 0, 0.6)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        },
                        content: {
                            position: 'relative',
                            padding: '24px',
                            backgroundColor: '#3A405A',
                            borderRadius: '8px',
                            border: '1px solid rgba(44, 44, 44, 0.1)',
                            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.12), 0px 16px 32px rgba(0, 0, 0, 0.12)'
                        }
                    }}
                >
                    <div className="unsplash-modal">
                        <div className="unsplash-modal__top-row">
                            <h2 className="unsplash-modal__heading">Successfull payment</h2>
                            <CloseIcon 
                                onClick={() => {
                                    setSubEndDate(null)
                                    fetchSubscriptions();
                                    // window.location.reload();
                                }}
                            />
                        </div>
                        <p className="unsplash-modal__text-paragraph">
                        {`Dear ${user.firstName}, your plan has been upgraded. Pro plan valid until ${subEndDate}`}
                        </p>
                    </div>
                    <div 
                        className="unsplash-modal__choose-btn"
                        onClick={() => {
                            setSubEndDate(null)
                            window.location.reload();
                        }}    
                    >
                        <span>Go to home page</span>
                    </div>
                </Modal>
            }
        </div>
    );
}
 
export default BillingSection;