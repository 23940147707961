const SAPETYPES = ['rectangle', 'ellipse', 'triangle', 'trapezoid', 'parallelogram', 'pentagon', 'hexagon', 'heptagon', 'octagon', 'bevel', 'rabbet', 'left arrow', 'right arrow', 'left point', 'right point', 'left chevron', 'right chevron', 'star', 'cross', 'message', 'close', '']

export const SHAPETYPES = {
    rectangle: {
        name: 'rectangle',
        clipPath: 'polygon(0% 0%, 100% 0, 100% 100%, 0% 100%)'
    },
    ellipse: {
        name: 'ellipse',
        clipPath: 'circle(50% at 50% 50%)'
    },
    triangle: {
        name: 'triangle',
        clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)' 
    },
    trapezoid: {
        name: 'trapezoid',
        clipPath: 'polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%)'
    },
    parallelogram: {
        name: 'parallelogram',
        clipPath: 'polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%)'
    },
    pentagon: {
        name: 'pentagon',
        clipPath: 'polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%)'
    },
    hexagon: {
        name: 'hexagon',
        clipPath: 'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)'
    },
    heptagon: {
        name: 'heptagon',
        clipPath: 'polygon(50% 0%, 90% 20%, 100% 60%, 75% 100%, 25% 100%, 0% 60%, 10% 20%)'
    },
    octagon: {
        name: 'octagon',
        clipPath: 'polygon(30% 0%, 70% 0%, 100% 30%, 100% 70%, 70% 100%, 30% 100%, 0% 70%, 0% 30%)'
    },
    bevel: {
        name: 'bevel',
        clipPath: 'polygon(20% 0%, 80% 0%, 100% 20%, 100% 80%, 80% 100%, 20% 100%, 0% 80%, 0% 20%)'
    },
    rabbet: {
        name: 'rabbet',
        clipPath: 'polygon(0% 15%, 15% 15%, 15% 0%, 85% 0%, 85% 15%, 100% 15%, 100% 85%, 85% 85%, 85% 100%, 15% 100%, 15% 85%, 0% 85%)'
    },
    leftArrow: {
        name: 'leftArrow',
        clipPath: 'polygon(40% 0%, 40% 20%, 100% 20%, 100% 80%, 40% 80%, 40% 100%, 0% 50%)'
    },
    rightArrow: {
        name: 'rightArrow',
        clipPath: 'polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%)'
    },
    leftPoint: {
        name: 'leftPoint',
        clipPath: 'polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%)'
    },
    rightPoint: {
        name: 'rightPoint',
        clipPath: 'polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%)'
    },
    leftChevron: {
        name: 'leftChevron',
        clipPath: 'polygon(100% 0%, 75% 50%, 100% 100%, 25% 100%, 0% 50%, 25% 0%)'
    },
    rightChevron: {
        name: 'rightChevron',
        clipPath: 'polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%)'
    },
    star: {
        name: 'star',
        clipPath: 'polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%)'
    },
    cross: {
        name: 'cross',
        clipPath: 'polygon(10% 25%, 35% 25%, 35% 0%, 65% 0%, 65% 25%, 90% 25%, 90% 50%, 65% 50%, 65% 100%, 35% 100%, 35% 50%, 10% 50%)'
    },
    message: {
        name: 'message',
        clipPath: 'polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 75% 100%, 50% 75%, 0% 75%)'
    },
    close: {
        name: 'close',
        clipPath: 'polygon(20% 0%, 0% 20%, 30% 50%, 0% 80%, 20% 100%, 50% 70%, 80% 100%, 100% 80%, 70% 50%, 100% 20%, 80% 0%, 50% 30%)'
    },
    line:{
        name: 'line',
        clipPath:'polygon(45% 100%, 55% 100%, 55% 0%, 45% 0%)'
    }
}
