const CustomSwitch = ({ checked, callback, onClick }) => {
    return (  
        <div className='switch-container'>
            <label className="switch">
                <input
                type="checkbox"
                className='switch__checkbox'
                checked={checked}
                onChange={callback}
                onClick={onClick}
                />
                <span className="switch__slider"></span>
            </label>
        </div>
    );
}
 
export default CustomSwitch;