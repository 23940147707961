const Notification = ({ closeToast, toastProps, message, title }) => {
    
    return (  
        <div className="notification">
            {title && <h5 className="notification__title">{title}</h5>}
            <p className="notification__message">{message}</p>
        </div>
    );
}
 
export default Notification;