import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { SERVER_URL } from "../../url.config";
import { UserContext } from "../../App";

const Subscriptions = () => {
	const { user } = useContext(UserContext);
	const [subscriptions, setSubscriptions] = useState([]);

	const fetchSubs = async () => {
		try {
			const res = await axios.get(
				// `${SERVER_URL}/subscriptions/${user.id}`
				`${SERVER_URL}/subscriptions`
			);
			setSubscriptions(res.data);
		} catch (err) {}
	};

	useEffect(() => {
		fetchSubs();
	}, []);

	const fullName = (user) => {
		const full = user.firstName + " " + user.lastName;
		return full;
	};
	return (
		<div className="list-block">
			<p className="list-block__title">Subscriptions</p>
			<div className="list-block__items">
				<div className="table">
					<div className="table__head">
						<span>User name</span>
						<span>Start date</span>
						<span>End date</span>
						<span>Account plan</span>
						<span>Payment term</span>
					</div>
					{subscriptions
						?.map((item, index) => {
							return (
								<div className="table__row" key={index}>
									<span>{fullName(user)}</span>
									<span>{item.startDateSubscription}</span>
									<span>{item.endDateSubscription}</span>
									<span>{item.accountPlan}</span>
									<span>{item.paymentTerm}</span>
								</div>
							);
						})
						.reverse()}
				</div>
			</div>
		</div>
	);
};

export default Subscriptions;
