import { useState, useRef, useLayoutEffect, useEffect } from "react";
import { EDIT_ELEMENT_ON_ALL_SCREENS } from "../../reducers/presentation/actionTypes";
import  { ReactComponent as DownArrowIcon } from '../../icons/backicon.svg'
import  { ReactComponent as TrashIcon } from '../../icons/trash-2.svg'

const ImageToolbar = ({ element, dispatch, setEditingShapeElement, screenSize, editingShapeElement, target, deleteHandler }) => {
    const toolbarRef = useRef()
    const [dropdownVisible, setDropdownVisible] = useState(null);

    const handleClickOutside = (e) => {
        const moveControl = document.querySelector('.moveable-control-box')
        if (toolbarRef.current && (!toolbarRef.current.contains(e.target) || target.contains(e.target) || moveControl.contains(e.target))) {
            setEditingShapeElement(null)
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside)

        return () => document.removeEventListener('mousedown', handleClickOutside)
    })

    const editElement = (updates) => {
        dispatch({
            type: EDIT_ELEMENT_ON_ALL_SCREENS,
            payload: {
                slideId: editingShapeElement.slideId,
                screenSize: screenSize.type,
                elementId: element.id,
                updates
            }
        })
    }

    useLayoutEffect(() => {
        if (toolbarRef.current) {
            const targetRect = toolbarRef.current.getBoundingClientRect()
            const windowWidth = window.innerWidth

            let x = targetRect.x
            if (x + targetRect.width > windowWidth) {
                x = windowWidth - targetRect.width
                setEditingShapeElement(previous => ({ ...previous, x }))
            }
        }
    }, [])


    return (  
        <div
            ref={toolbarRef}
            className="shapes-toolbar"
            style={{
                top: editingShapeElement.y,
                left: editingShapeElement.x  
            }}    
        >
            <span className="shapes-toolbar__item-label">Opacity:</span>
            <div className="shapes-toolbar__separator"></div>
            <div 
                className={dropdownVisible && dropdownVisible.opacityDropdown ? "shapes-toolbar__menu-dropdown shapes-toolbar__menu-dropdown--active slider-display" : "shapes-toolbar__menu-dropdown slider-display"}
                onClick={() => {
                    if (dropdownVisible && dropdownVisible.opacityDropdown) {
                        setDropdownVisible(null)
                    } else {
                        setDropdownVisible({ opacityDropdown: true })
                    }
                }}
            >
                <span className="shapes-toolbar__menu-dropdown-item">{element?.imgOpacity ? Math.round(element.imgOpacity * 100) : 100}%</span>
                <DownArrowIcon />
                {dropdownVisible && dropdownVisible.opacityDropdown &&
                    <div className="shapes-toolbar__border-radius-options-container">
                        <div className="shapes-toolbar__slider-container">
                            <span className="shapes-toolbar__slider-minmax">0</span>
                            <input type="range" 
                                min={0}
                                max={100}
                                step={1}
                                value={element.imgOpacity * 100 || 100}
                                onChange={(e) => editElement({ imgOpacity: e.target.value / 100 })}
                            />
                            <span className="shapes-toolbar__slider-minmax">100</span>
                        </div>
                    </div>
                }
            </div>
            <div className="shapes-toolbar__separator"></div>
            <TrashIcon 
                className="shapes-toolbar__icon"
                onClick={deleteHandler}
            />
        </div>
    );
}
 
export default ImageToolbar;