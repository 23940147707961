import { useState } from 'react'
import axios from 'axios'
import { SERVER_URL } from '../../url.config'
import  { ReactComponent as Logo } from '../../icons/logo-horizontal master.svg'
import  { ReactComponent as UserIcon } from '../../icons/user-1.svg'
import  { ReactComponent as WorldIcon } from '../../icons/world-1.svg'
import  { ReactComponent as EmailIcon } from '../../icons/at-sign-2-1.svg'
import  { ReactComponent as KeyIcon } from '../../icons/key-1.svg'
import  { ReactComponent as ChatIcon } from '../../icons/chat.svg'
import  { ReactComponent as WarningIcon } from '../../icons/warning-sign-1.svg'
import HidePass from '../../icons/hide-pass.png'
import ShowPass from '../../icons/show-pass.png'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { getNames } from 'country-list'
import { toast } from 'react-toastify';
import Notification from '../notifications/Notification';
import { notificationIcons } from '../notifications/notificationIcons';

const SignUp = () => {
    const [visible, setVisibility] = useState(false)
	const toggleBtn = () => {
		setVisibility(prevState => !prevState);
	}

    const [emailSent, setEmailSent] = useState(false);
    const countries = getNames()
    // const [error, setError] = useState(null);

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm()

    const registerUser = async (data) => {
        try {
            const res = await axios.post(`${SERVER_URL}/auth/register`, { data })
            setEmailSent(true)
        } catch (err) {
            // setError(err.response.data.message)
            toast.error(<Notification message={err.response.data.message} />, {
        	icon: notificationIcons.error,
            autoDismissTimeout: 1000,
        	closeButton: notificationIcons.close
      		})
        }
    }

    return (  
        <div className="onboarding">
            <div className="onboarding__form-wrap">
                <div className="onboarding__form-box">
                    <Logo className='onboarding__logo' />
                    {!emailSent &&
                        <>
                            <div className="onboarding__title">
                                <h3 className="onboarding__heading">Create account</h3>
                                <p className="onboarding__subtitle">Fill in your info to start.</p>
                            </div>
                            <form 
                                className="onboarding__form"
                                onSubmit={handleSubmit((data) => {
                                    registerUser(data)
                                })}
                            >
                                <div className="onboarding__form-row">
                                    <input
                                        {...register('firstName', { required: 'This field cannot be empty' })}
                                        type='text'
                                        className={errors.firstName ? 'onboarding__input onboarding__input--error' : 'onboarding__input'}
                                        placeholder='First name'
                                    />
                                    <UserIcon className='onboarding__input-icon'/>
                                    {errors.firstName?.message && 
                                        <span className="onboarding__error-msg">{errors.firstName.message}</span>
                                    }
                                </div>
                                <div className="onboarding__form-row">
                                    <input
                                        {...register('lastName', { required: 'This field cannot be empty' })}
                                        type='text'
                                        className={errors.lastName ? 'onboarding__input onboarding__input--error' : 'onboarding__input'}
                                        placeholder='Last name'
                                    />
                                    <UserIcon className='onboarding__input-icon'/>
                                    {errors.lastName?.message && 
                                        <span className="onboarding__error-msg">{errors.lastName.message}</span>
                                    }
                                </div>
                                <div className="onboarding__form-row">
                                    <select
                                        {...register('country', { required: 'This field cannot be empty' })}
                                        className={errors.country ? 'onboarding__input onboarding__input--error' : 'onboarding__input'}
                                    >
                                        <option value="" hidden>Country</option>
                                        {countries?.length > 0 && countries.map(country => (
                                            <option value={country} key={country}>{country}</option>
                                        ))}
                                        {/* <option value="" hidden>Country</option>
                                        <option value='serbia'>Serbia</option>
                                        <option value='usa'>USA</option>
                                        <option value='germany'>Germany</option> */}
                                    </select>
                                    <WorldIcon className='onboarding__input-icon'/>
                                    {errors.country?.message && 
                                        <span className="onboarding__error-msg">{errors.country.message}</span>
                                    }
                                </div>
                                <div className="onboarding__form-row">
                                    <input
                                        {...register('email', { 
                                            required: 'This field cannot be empty',
                                            pattern: {
                                                value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                message: 'Please enter a valid email'
                                            }
                                        
                                        })}
                                        type='text'
                                        className={errors.email ? 'onboarding__input onboarding__input--error' : 'onboarding__input'}
                                        placeholder='Email address'
                                    />
                                    <EmailIcon className='onboarding__input-icon'/>
                                    {errors.email?.message && 
                                        <span className="onboarding__error-msg">{errors.email.message}</span>
                                    }
                                </div>
                                <div className="onboarding__form-row">
                                    <input
                                        {...register('password', { 
                                            required: 'This field cannot be empty', 
                                            minLength: {
                                                value: 6,
                                                message: 'Minimum length is 6 characters'
                                            }
                                        })}
                                        type={visible ? "text" : "password"}
                                        className='onboarding__input'
                                        placeholder='Password'
                                    />
                                    <div style={{position:'absolute', right:"5%", top: "30%"}} onClick={toggleBtn}>{visible && <img src={HidePass} width='20px'></img>} {!visible && <img src={ShowPass} width='20px'></img>}</div>
                                    <KeyIcon className='onboarding__input-icon'/>
                                    {errors.password?.message && 
                                        <span className="onboarding__error-msg">{errors.password.message}</span>
                                    }
                                </div>
                                {/* <div className="onboarding__form-row">
                                    <label
                                className='insert-menu__file-upload'
                            >
                                <span>Choose file</span>
                                <input {...register('userAvatar')} type="file" onChange={(e) => handleFileUpload(e)} style={{display: 'none'}}/>
                            </label>
                                    <input
                                        {...register('userAvatar', { required: 'This field cannot be empty' })}
                                        type='text'
                                        className={errors.userAvatar ? 'onboarding__input onboarding__input--error' : 'onboarding__input'}
                                        placeholder='User avatar'
                                    />
                                    <UserIcon className='onboarding__input-icon'/>
                                    {errors.userAvatar?.message && 
                                        <span className="onboarding__error-msg">{errors.userAvatar.message}</span>
                                    }
                                </div> */}
                                <button className='onboarding__submit'>Proceed</button>
                                <div style={{position: 'relative', marginTop: '-10px'}}>
                                {/* {error ? <div className="onboarding__error-msg">{error}</div> : ''} */}
                                </div>
                            </form>
                            <p className="onboarding__notification">
                                <span>Have account?</span>
                            </p>
                            <Link 
                                to='/login'
                                className="onboarding__link"
                            >
                                <span>Sign in</span>
                            </Link>
                        </>
                    }
                    {emailSent &&
                        <>
                            <div className="onboarding__title">
                                <h3 className="onboarding__heading">Verify email</h3>
                                <p className="onboarding__subtitle">Click on the verification link sent to your email address.</p>
                            </div>
                            <div className="onboarding__warning">
                                <WarningIcon className="onboarding__warning-icon"/>
                                <p className="onboarding__warning-text">Check your Spam folder system might have landed email there.</p>
                            </div>

                            <p className="onboarding__notification">
                                <span>Didn’t receive email?</span>
                            </p>
                            <Link 
                                to='#'
                                className="onboarding__link"
                            >
                                <span>Resend</span>
                            </Link>
                        </>
                    }
                </div>
                <div className="onboarding__legal">
                    <a href='https://www.stackslides.com/legal/privacy-policy' style={{color: "#FFFFFF", textDecoration: "none"}}>
                        <span>Privacy Policy</span>
                    </a>
                    <a href='https://www.stackslides.com/legal/terms-of-service' style={{color: "#FFFFFF", textDecoration: "none"}}>
                        <span>Terms &amp; Conditions</span>
                    </a>
                </div>
            </div>
            {/*<ChatIcon className='onboarding__chat' />*/}
        </div>
    );
}
 
export default SignUp;