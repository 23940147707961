import { ReactComponent as ImgIcon } from '../icons/image-light-light.svg';
import { ReactComponent as TextIcon } from '../icons/type-light.svg';
import { ReactComponent as ShapeIcon } from '../icons/shapes.svg';
import { ReactComponent as EmbedIcon } from '../icons/embed.svg';
import { EDIT_SLIDE_PROPERTY } from '../reducers/presentation/actionTypes';
import { ChromePicker } from 'react-color';

const InsertMenu = ({
  addElement,
  setUnsplashModalVisible,
  setEmbedModalVisible,
  slide,
  dispatch,
  screenSize
}) => {
  const handleUpload = async (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    const acceptedImageTypes = ['image/jpeg', 'image/png'];

    if (!acceptedImageTypes.includes(file.type)) return;
    if (file.size > 5 * 1024 * 1024) return;

    const reader = new FileReader();
    reader.onload = function () {
      const img = new Image();
      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const maxDimension = 1024;
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxDimension) {
            height *= maxDimension / width;
            width = maxDimension;
          }
        } else {
          if (height > maxDimension) {
            width *= maxDimension / height;
            height = maxDimension;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        const dataUrl = canvas.toDataURL('image/jpeg', 1); // adjust quality as needed

        // Now 'dataUrl' contains the resized image data, you can use it for displaying or uploading
        const aspectRatio = width / height;

        addElement('img', {
          src: dataUrl,
          width: width > screenSize.width ? screenSize.width : width * 0.8,
          height: width > screenSize.width ? screenSize.width / aspectRatio : height * 0.8
        });
      };

      img.src = reader.result;
    };

    reader.readAsDataURL(file);
  };

  return (
    <div className='insert-menu'>
      <div className='insert-menu__item'>
        <div
          className='insert-menu__bg-color-btn'
          style={{
            backgroundColor: slide.backgroundColor ?? '#fff'
          }}></div>
        <div className='insert-menu__item-dropdown-container insert-menu__item-dropdown-container--color-picker'>
          <div className='color-picker-container'>
            <ChromePicker
              color={slide.backgroundColor ?? '#ffffff'}
              onChange={(color) => {
                dispatch({
                  type: EDIT_SLIDE_PROPERTY,
                  payload: {
                    slideId: slide.id,
                    updates: {
                      backgroundColor: color.hex
                    }
                  }
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className='insert-menu__item'>
        <ImgIcon />
        <div className='insert-menu__item-dropdown-container'>
          <div className='insert-menu__image-dropdown'>
            <div>
              <h5>Select photo</h5>
              <p>Supported foto files: .jpeg, .png Size max: 5MB</p>
              <label className='insert-menu__file-upload'>
                <span>Choose file</span>
                <input
                  type='file'
                  name='image'
                  accept='image/png, image/jpeg'
                  onChange={handleUpload}
                  style={{ display: 'none' }}
                />
              </label>
            </div>

            <div className='insert-menu__unsplash' onClick={() => setUnsplashModalVisible(true)}>
              <span>Unsplash images</span>
            </div>
          </div>
        </div>
      </div>
      <div className='insert-menu__item' onClick={() => addElement('text')}>
        <TextIcon />
      </div>
      <div className='insert-menu__item' onClick={() => addElement('shape')}>
        <ShapeIcon />
      </div>
      {/* <div className="insert-menu__item">
                <VideoIcon />
            </div> */}
      {/* <div className="insert-menu__item">
                <ChartIcon />
            </div>
            <div className="insert-menu__item">
                <AudioIcon />
            </div> */}
      <div className='insert-menu__item' onClick={() => setEmbedModalVisible(true)}>
        <EmbedIcon />
      </div>
    </div>
  );
};

export default InsertMenu;
