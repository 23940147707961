import { createContext, useState, useEffect, useMemo, useLayoutEffect } from 'react';
import Dashboard from './components/Dashboard';
import Slideshow from './components/Slideshow';
import PlaySlideshow from './components/PlaySlideshow';
import Login from './components/auth/Login';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import SignUp from './components/auth/SignUp';
import RecoverPassword from './components/auth/RecoverPassword';
import EmailVerification from './components/auth/EmailVerification';
import ResetPassword from './components/auth/ResetPassword';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './components/PaymentForm';
import { Elements } from '@stripe/react-stripe-js';
import UserAccount from './components/user/UserAccount';
import { ToastContainer } from 'react-toastify';
import AdminDashboard from './components/admin/AdminDashboard';

import 'react-toastify/dist/ReactToastify.css';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export const UserContext = createContext();

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  let user = sessionStorage.getItem('user');
  if (user) user = JSON.parse(user);

  const [screenSize, setScreenSize] = useState({ width: 1920, height: 1080, type: 'desktop' });

  useLayoutEffect(() => {
    if (window.innerWidth < 768) {
      setScreenSize({ width: 360, height: 640, type: 'mobile' });
    } else if (window.innerWidth < 1440) {
      setScreenSize({ width: 768, height: 1024, type: 'tablet' });
    } else if (window.innerWidth < 1920) {
      setScreenSize({ width: 1440, height: 900, type: 'laptop' });
    } else {
      setScreenSize({ width: 1920, height: 1080, type: 'desktop' });
    }
  }, []);

  const [responsiveVisible, setResponsiveVisible] = useState(true);

  useEffect(() => {
    if (!user) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  }, [user, setLoggedIn]);

  function ProtectedRoute({ children }) {
    if (!loggedIn) {
      return <Navigate to='/login' />;
    }
    return children;
  }

  return (
    <div className='App'>
      <UserContext.Provider value={{ user }}>
        <Router>
          <Routes>
            <Route path='/signup' element={<SignUp />} />
            <Route path='/login' element={loggedIn ? <Navigate to='/' /> : <Login />} />
            <Route path='/email-verification/:emailToken' element={<EmailVerification />} />
            <Route path='/forgot-password/:passwordToken' element={<ResetPassword />} />
            <Route path='/recover-password' element={<RecoverPassword />} />
            <Route
              path='/'
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path='/account'
              element={
                <ProtectedRoute>
                  {/*<UserAccount />*/}
                  <Elements stripe={stripePromise}>
                    {/*<PaymentForm />*/}
                    <UserAccount />
                  </Elements>
                </ProtectedRoute>
              }
            />
            <Route
              path='/admin-dashboard'
              element={
                <ProtectedRoute>
                  {/*<UserAccount />*/}
                  <Elements stripe={stripePromise}>
                    {/*<PaymentForm />*/}
                    <AdminDashboard />
                  </Elements>
                </ProtectedRoute>
              }
            />
            <Route
              path='/slideshow/:presentationId'
              element={
                <ProtectedRoute>
                  <Slideshow
                    screenSize={screenSize}
                    setScreenSize={setScreenSize}
                    responsiveVisible={responsiveVisible}
                    setResponsiveVisible={setResponsiveVisible}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path='/play-slideshow/:presentationId'
              element={<PlaySlideshow screenSize={screenSize} setScreenSize={setScreenSize} />}
            />
            {/*<Route path="/payment" element={*/}
            {/*  <Elements stripe={stripePromise}>*/}
            {/*    <PaymentForm />*/}
            {/*  </Elements>*/}
            {/*} />*/}
            {/*{loggedIn ? <Route path="/play-slideshow/:presentationId" element={<PlaySlideshow />} /> : <Route path='/play-slideshow/:presentationId' element={<Login />} />}*/}
          </Routes>
        </Router>
        <ToastContainer
          position='top-right'
          hideProgressBar={true}
          autoClose={false}
          closeOnClick={false}
        />
      </UserContext.Provider>
    </div>
  );
}

export default App;
