export function secondsToDhms(ms) {
	const seconds = Number(ms / 1000);
    var month = Math.floor(seconds / (3600 * 24 * 30));
	var d = Math.floor(seconds / (3600 * 24));
	var h = Math.floor((seconds % (3600 * 24)) / 3600);
	var m = Math.floor((seconds % 3600) / 60);
	var s = Math.floor(seconds % 60);

    var monthDisplay = month > 0 ? month + (month == 1 ? ' month ' : ' months ') : '';
	var dDisplay = d > 0 ? d + (d == 1 ? ' day ' : ' days ') : '';
	var hDisplay = h > 0 ? h + (h == 1 ? ' hour ' : ' hours ') : '';
	var mDisplay = m > 0 ? m + (m == 1 ? ' minute ' : ' minutes ') : '';

	return month > 0 ? (monthDisplay + 'ago') : d > 0 ? (dDisplay + 'ago') : h > 0 ? (hDisplay + 'ago') : m > 0 ? (mDisplay + 'ago') : 'now';
}

export const debounce = (cb, delay) => {
	let timeout

	return (...args) => {
		clearTimeout(timeout)
		timeout = setTimeout(() => {
			cb(...args)
		}, delay);
	}
}