import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../App";
import { Link } from "react-router-dom";
import { ReactComponent as UserIcon } from "../icons/user.svg";
import { ReactComponent as SearchIcon } from "../icons/search.svg";
import { ReactComponent as Logo } from "../icons/logo-vertical-master.svg";
import { ReactComponent as Home } from "../icons/home.svg";
import axios from "axios";
import { SERVER_URL } from "../url.config";

const MainNav = ({ userPage }) => {
    const { user } = useContext(UserContext);
    const navBarDropdownRef = useRef();

    const [avatar, setAvatar] = useState(undefined);
    const [navBarDropdownVisible, setNavBarDropdownVisible] = useState(false);

    const fetchAvatar = async (userId) => {
        try {
            const res = await axios.get(`${SERVER_URL}/avatar/${userId}`);
            if (res && res.data && res.data.data) {
                setAvatar(res.data.data);
            } else setAvatar(null);
        } catch (err) {}
    };

    useEffect(() => {
        if (user) {
            fetchAvatar(user.id);
        }
    }, [user]);

    const handleClickOutsideDropdown = (e) => {
        if (
            navBarDropdownRef.current &&
            !navBarDropdownRef.current.contains(e.target)
        ) {
            setNavBarDropdownVisible(false);
        }
    };

    useEffect(() => {
        if (navBarDropdownVisible) {
            document.addEventListener("click", handleClickOutsideDropdown);
            document.addEventListener(
                "contextmenu",
                handleClickOutsideDropdown
            );

            return () => {
                document.removeEventListener(
                    "click",
                    handleClickOutsideDropdown
                );
                document.removeEventListener(
                    "contextmenu",
                    handleClickOutsideDropdown
                );
            };
        }
    });

    const handleLogout = () => {
        sessionStorage.removeItem("user");
        sessionStorage.removeItem("accessed");
        localStorage.removeItem("selectedStack");
        localStorage.removeItem("selectedProjectId");
        window.location.reload();
    };

    return (
        <header className="dashboard__main-nav">
            <div className="dashboard__main-nav-block">
                <div className="dashboard__main-nav-logo">
                    <Logo className="ss-logo" />
                    {userPage && (
                        <div className="homeLink">
                            <span className="tooltiptext">Back to home</span>
                            <Link
                                to="/"
                                className="dashboard__main-nav-home-link"
                            >
                                <Home />
                            </Link>
                        </div>
                    )}
                </div>
                {user.role == "admin" && (
                    <div>
                        <div className="btn btn--rect-sm btn--blue add-pres-btn">
                            <Link
                                className="p-s-bold"
                                to="/admin-dashboard"
                                style={{
                                    textDecoration: "none",
                                    color: "white",
                                }}
                            >
                                Admin
                            </Link>
                        </div>
                    </div>
                )}

                {/* <div className="dashboard__search">
                    <SearchIcon className="dashboard__search-icon"/>
                    <input
                        type='text'
                        className="dashboard__search-input subtitle-3"
                        placeholder='Search'
                    />
                </div> */}
            </div>
            <div
                className="btn btn--rect-lg"
                onClick={() => setNavBarDropdownVisible(!navBarDropdownVisible)}
                style={{ position: "relative" }}
            >
                <div className="btn__content btn__content--last">
                    <span className="tooltiptext">User&nbsp;account</span>
                    {avatar ? (
                        <img
                            src={avatar}
                            style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                borderRadius: "50%",
                            }}
                        />
                    ) : (
                        <UserIcon />
                    )}
                </div>
                {navBarDropdownVisible && (
                    <div
                        ref={navBarDropdownRef}
                        className="slideshow__header-dropdown"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <Link to="/account" style={{ textDecoration: "none" }}>
                            <span className="p-s">Account</span>
                        </Link>
                        {user.role == "admin" && (
                            <Link
                                to="/admin-dashboard"
                                style={{ textDecoration: "none" }}
                            >
                                <span className="p-s">Admin</span>
                            </Link>
                        )}
                        <span className="p-s" onClick={handleLogout}>
							Logout
						</span>
                    </div>
                )}
            </div>
        </header>
    );
};

export default MainNav;