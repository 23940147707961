import { useRef, useEffect, useState, useContext } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import {
  DELETE_ELEMENT,
  EDIT_ELEMENT_ON_ALL_SCREENS,
  EDIT_ELEMENT
} from '../reducers/presentation/actionTypes';
import { ReactComponent as MoveIcon } from '../icons/move.svg';
import CustomFormatModal from './CustomFormatModal';
import { SHAPETYPES } from '../utils/shapes';

const Element = ({
  element,
  target,
  selectElement,
  setTarget,
  setIsMovable,
  dispatch,
  slideId,
  screenSize,
  setEditingShapeElement,
  selectedElement,
  selectedElements,
  setSelectedElement,
  setSelectedElements,
  shouldShowToolbar,
  setShouldShowToolbar,
  setZoomDisabled,
  editorStyles,
  setEditorStyles,
  updateEditorStyles,
  deleteEditorStyles,
  frame
}) => {
  const editorRef = useRef();
  const ref = useRef(null);
  const [editorVisible, setEditorVisible] = useState(false);
  const [newCustomFormat, setNewCustomFormat] = useState(null);
  const [opacity, setOpacity] = useState(element.textOpacity || undefined);

  const TextBoxRef = useRef(null);

  useEffect(() => {
    const TextBox = TextBoxRef.current;

    // Check if the TextBox exists
    if (!TextBox) {
      return;
    }

    // Create a ResizeObserver
    const resizeObserver = new ResizeObserver((entries) => {
      // Loop through each entry
      for (let entry of entries) {
        const { width, height } = entry.contentRect;
        //setFrame({height: height + 36, width: width})
        // Do something with the dimensions, if needed
      }
    });

    // Start observing the TextBox
    resizeObserver.observe(TextBox);

    // Clean up the observer on component unmount
    return () => {
      resizeObserver.disconnect();
    };
  }, [element.id]);

  const GetHtmlFontSize = (ellement) => {
    try {
      const htmlString = ellement;

      const properties = {};

      const styleRegex = /<[^>]*style="([^"]*)"[^>]*>/g;

      let match;
      while ((match = styleRegex.exec(htmlString)) !== null) {
        const styles = match[1].split(';');
        for (const style of styles) {
          const [key, value] = style.split(':').map((s) => s.trim());
          if (key && value) {
            if (!properties[key]) {
              properties[key] = [];
            }
            properties[key].push(value);
          }
        }
      }
      return properties['font-size'];
    } catch {
      console.error('Well, no!');
    }
  };

  const editElement = (updates) => {
    const updatesFontSize = GetHtmlFontSize(updates.textContent);
    const elementFontSize = GetHtmlFontSize(element.textContent);
    if (updatesFontSize?.join(',') === elementFontSize?.join(',')) {
      dispatch({
        type: EDIT_ELEMENT_ON_ALL_SCREENS,
        payload: {
          slideId,
          screenSize: screenSize.type,
          elementId: element.id,
          updates
        }
      });
    } else {
      dispatch({
        type: EDIT_ELEMENT,
        payload: {
          slideId,
          screenSize: screenSize.type,
          elementId: element.id,
          updates
        }
      });
    }
  };

  const handleClickOutside = (e) => {
    if (
      ref.current &&
      !ref.current.contains(e.target) &&
      target &&
      target.className === `target-${element.id}`
    ) {
      if (element.isNew) {
        editElement({ isNew: null });
      }
      setTarget(null);
      setIsMovable(false);
      setSelectedElement(null);
      setEditorVisible(false);
    }
  };

  useEffect(() => {
    if (target && target.className === `target-${element.id}`) {
      const slide = document.querySelector('.slideshow__preview-container');
      slide.addEventListener('click', handleClickOutside);

      return () => slide.removeEventListener('click', handleClickOutside);
    }
  });

  const [isHolding, setIsHolding] = useState(false);
  const clickTimeoutRef = useRef(null);

  useEffect(() => {
    const slide = document.querySelector('.slideshow__preview-container');

    const handleMouseDown = () => {
      clickTimeoutRef.current = setTimeout(() => {
        setIsHolding(true);
      }, 100);
    };

    const handleMouseUp = () => {
      clearTimeout(clickTimeoutRef.current);

      setIsHolding(false);
    };

    const handleClick = () => {
      if (isHolding) {
        console.log('Held and released!');
      }
    };

    slide.addEventListener('mousedown', handleMouseDown);
    slide.addEventListener('mouseup', handleMouseUp);
    slide.addEventListener('click', handleClick);

    return () => {
      // Cleanup: Remove event listeners when the component unmounts
      slide.removeEventListener('mousedown', handleMouseDown);
      slide.removeEventListener('mouseup', handleMouseUp);
      slide.removeEventListener('click', handleClick);
    };
  }, [isHolding]);

  useEffect(() => {
    if (element.isNew) {
      selectElement(element.id);
    }
  }, [element]);

  const showShapesToolbar = (type) => {
    const targetRect = target.getBoundingClientRect();

    let x = targetRect.x;
    let y = targetRect.y - 75;

    setEditingShapeElement({
      x,
      y,
      slideId,
      screenSize: screenSize.type,
      elementId: element.id,
      type
    });
  };

  useEffect(() => {
    if (target && !selectedElement) {
      setTarget(null);
      setIsMovable(false);
      setSelectedElement(null);
    }
  });

  useEffect(() => {
    if (target && shouldShowToolbar && selectedElement === element.id) {
      showShapesToolbar(element.type);
    }
  }, [target, shouldShowToolbar, selectedElement]);

  const showTextEditor = () => {
    setTarget(null);
    setEditorVisible(true);
    setSelectedElement(null);
    setZoomDisabled(true);
  };

  const handleItemClick = (itemId, ctrlKey) => {
    if (ctrlKey) {
      setSelectedElements((prev) => {
        if (prev.includes(itemId)) {
          return prev.filter((p) => !p.includes(itemId));
        }

        return [...prev, itemId];
      });
    }
  };

  // console.log('element: ', element);
  const elementX = element?.translate ? element.translate[0] : 0;
  const elementY = element?.translate ? element.translate[1] : 0;

  return (
    <>
      {element.type === 'img' ? (
        <img
          alt=''
          ref={ref}
          className={`target-${element.id}`}
          src={element.img}
          style={{
            display: element.hidden ? 'none' : 'block',
            position: 'absolute',
            width: element.width,
            height: element.height,
            top: 0,
            left: 0,
            transform: `translate(${element.translate[0]}px, ${element.translate[1]}px) rotate(${element.rotate}deg)`,
            pointerEvents: 'all',
            opacity: element.imgOpacity
          }}
          onClick={(e) => {
            if (!editorVisible) selectElement(element.id);
            setShouldShowToolbar(true);
          }}
        />
      ) : (
        <>
          <div
            ref={ref}
            className={`target-${element.id}`}
            style={{
              display: element.hidden ? 'none' : 'block',
              position: 'absolute',
              width: element.width,
              height: element.height,
              top: 0,
              left: 0,
              transform: `translate(${elementX}px, ${elementY}px) rotate(${element.rotate}deg)`,
              padding: element.type === 'text' || element.type === 'embed' ? '18px' : '',
              opacity: element.type === 'text' ? opacity : undefined,
              cursor: isHolding ? 'grabbing' : 'grab'
            }}
            onClick={(e) => {
              if (e.ctrlKey) {
                handleItemClick(element.id, e.ctrlKey);
              }
              if (!editorVisible) selectElement(element.id);
            }}>
            {element.type === 'text' && (
              <>
                {editorVisible ? (
                  <Editor
                    init={{
                      inline: true,
                      menubar: false,
                      plugins: [
                        'code link charmap lists wordcount charmap emoticons paste lists advlist autoresize'
                      ],
                      toolbar1:
                        'styleselect customStyles | customOpacity | fontselect fontsizeselect lineheight forecolor bold italic underline alignleft aligncenter alignright | link | customDeleteButton',
                      style_formats: editorStyles,
                      autoresize_bottom_margin: 0,
                      statusbar: false,
                      contextmenu: '',
                      placeholder: 'Text in a field',
                      content_style: 'body { font-family:Roboto,sans-serif; }',
                      font_formats:
                        'Roboto=roboto; Mulish=mulish; PT Sans=pt sans; Montserrat=montserrat; Source Sans Pro=source sans pro; Roboto Condensed=roboto condensed; Oswald=oswald; Slabo 27px=slabo 27px; Lato=lato; Open Sans=open sans;',
                      quickbars_insert_toolbar: false,
                      fontsize_formats: '8px 12px 16px 18px 24px 32px 40px 48px 56px 64px',
                      setup: (editor) => {
                        editor.ui.registry.addButton('customDeleteButton', {
                          icon: '',
                          tooltip: 'Delete',
                          onAction: function (_) {
                            dispatch({
                              type: DELETE_ELEMENT,
                              payload: {
                                slideId,
                                elementId: element.id
                              }
                            });
                            setSelectedElement(null);
                            setEditingShapeElement(null);
                          }
                        });
                        editor.ui.registry.addButton('customStyles', {
                          icon: '',
                          tooltip: 'Manage custom styles',
                          onAction: function (_) {
                            let styles = {};
                            if (editor.formatter.match('bold')) {
                              styles = {
                                ...styles,
                                'font-weight': 'bold'
                              };
                            }
                            if (editor.formatter.match('italic')) {
                              styles = {
                                ...styles,
                                'font-style': 'italic'
                              };
                            }
                            if (editor.formatter.match('underline')) {
                              styles = {
                                ...styles,
                                'text-decoration': 'underline'
                              };
                            }
                            styles = {
                              ...styles,
                              'font-size': editor.queryCommandValue('fontsize'),
                              'line-height': editor.queryCommandValue('lineheight')
                            };
                            setNewCustomFormat({ title: '', styles, addedByUser: true });
                          }
                        });
                        editor.ui.registry.addButton('customOpacity', {
                          tooltip: 'Change Opacity',
                          text: `<span style="color: white;">${
                            opacity < 1 ? opacity * 100 : opacity
                          } %</span>`,
                          onAction: function () {
                            const currentOpacity =
                              parseFloat(editor.getBody().style.opacity) || 100;

                            // Create a custom dialog with a slider
                            const dialogHtml = `        
                            <div>
                                <label style="color: white;">Opacity:</label>
                                <input type="range" id="opacitySlider" min="0" max="100" step="1" value="${
                                  opacity * 100
                                }%" style="width: 100%;">
                                <span id="opacityValue" style="color: white;">${
                                  opacity < 1 ? opacity * 100 : opacity
                                }</span> <span style="color: white;">%</span>
                            </div>
                            `;

                            const dialog = editor.windowManager.open({
                              title: '',
                              body: {
                                type: 'panel',
                                items: [
                                  {
                                    type: 'htmlpanel',
                                    html: dialogHtml
                                  }
                                ],
                                css: {
                                  'background-color': 'black'
                                }
                              },
                              buttons: [
                                {
                                  type: 'cancel',
                                  name: 'cancel',
                                  text: 'Cancel'
                                },
                                {
                                  type: 'submit',
                                  name: 'submit',
                                  text: 'Submit',
                                  primary: true
                                }
                              ],
                              iframe: true,

                              onSubmit: function (api) {
                                const newOpacity = document.getElementById('opacitySlider').value;
                                if (!isNaN(newOpacity) && newOpacity >= 0 && newOpacity <= 100) {
                                  editor.getBody().style.opacity = newOpacity / 100;
                                  const test = newOpacity / 100;
                                  setOpacity(Number(test));
                                  editElement({ textOpacity: test });
                                  api.close();
                                } else {
                                  alert('Please enter a valid opacity value between 0 and 100.');
                                }
                              }
                            });

                            // Update the displayed opacity value as the slider moves
                            const opacitySlider = document.getElementById('opacitySlider');
                            const opacityValue = document.getElementById('opacityValue');
                            opacitySlider.addEventListener('input', function () {
                              opacityValue.textContent = opacitySlider.value;
                            });
                          },
                          onInit: function (event, editor) {
                            // Set the initial opacity from the prop
                            const initialOpacity = element.textOpacity || 100;

                            // Set the default value for opacity
                            editor.getBody().style.opacity = initialOpacity;
                            setOpacity(Number(initialOpacity));
                          },
                          onEditorChange: function (value) {
                            editElement({ textContent: value, textOpacity: opacity });
                          },
                          onSetup: function (buttonApi) {
                            editor.getBody().style.opacity = element.textOpacity;
                            buttonApi.setDisabled(false);
                          }
                        });
                      }
                    }}
                    apiKey='u7jbb6w5u20kakt8quq5et0ubez8qjqmfowskkgnof2jfgnd'
                    value={element.textContent}
                    onEditorChange={(value, editor) =>
                      editElement({ textContent: value, textOpacity: opacity })
                    }
                    onInit={(event, editor) => {
                      editorRef.current = editor;
                      editor.focus();
                      editor.on('blur', (e) => {
                        e.stopImmediatePropagation();
                        setEditorVisible(false);
                        setIsMovable(false);
                        setZoomDisabled(false);
                      });
                      editor.on('ExecCommand', (e) => {
                        if (e.command === 'mceApplyTextcolor') {
                          document.querySelector(
                            '.tox-split-button[aria-label="Text color"] .tox-tbtn.tox-split-button__chevron'
                          ).style.background = e.value;
                        }
                        if (e.command === 'mceRemoveTextcolor') {
                          document.querySelector(
                            '.tox-split-button[aria-label="Text color"] .tox-tbtn.tox-split-button__chevron'
                          ).style.background = '#000000';
                        }
                      });

                      editor.formatter.formatChanged(
                        'forecolor',
                        function (isNew, args) {
                          if (args.node.style.color) {
                            document.querySelector(
                              '.tox-split-button[aria-label="Text color"] .tox-tbtn.tox-split-button__chevron'
                            ).style.background = args.node.style.color;
                          } else {
                            document.querySelector(
                              '.tox-split-button[aria-label="Text color"] .tox-tbtn.tox-split-button__chevron'
                            ).style.background = '#000000';
                          }
                        },
                        true
                      );
                    }}
                    onDblclick={(event) => event.stopPropagation()}
                  />
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `<div id="${element.id}" class="inner-text-element-content" style="width: ${frame?.width}px; height: ${frame?.height}px;">${element.textContent}</div>`
                    }}
                    ref={TextBoxRef}
                    onDoubleClick={(e) => {
                      e.stopPropagation();
                      showTextEditor();
                    }}></div>
                )}
              </>
            )}
            {element.type === 'shape' && (
              <div
                onClick={() => setShouldShowToolbar(true)}
                style={{
                  height: '100%',
                  backgroundColor: selectedElements.includes(element.id)
                    ? 'red'
                    : element.shapeColor,
                  opacity: element.shapeOpacity,
                  border:
                    element.shapeType === 'rectangle' || element.shapeType === 'ellipse'
                      ? element.shapeBorderThickness
                        ? `${element.shapeBorderThickness} ${element.shapeBorderType} ${element.shapeBorderColor}`
                        : 'none'
                      : 'none',
                  borderRadius:
                    element.shapeType === 'rectangle' || element.shapeType === 'ellipse'
                      ? element.shapeType === 'ellipse'
                        ? '50%'
                        : `${element.shapeBorderRadius}%`
                      : '',
                  clipPath:
                    element.shapeType !== 'rectangle' && element.shapeType !== 'ellipse'
                      ? SHAPETYPES[element.shapeType]?.clipPath
                      : ''
                }}></div>
            )}
            {element.type === 'embed' && (
              <div style={{ width: '100%', height: '100%', position: 'relative' }}>
                <div
                  className='element__iframe-container'
                  style={{ width: '100%', height: '100%' }}
                  dangerouslySetInnerHTML={{ __html: element.embedSrc }}></div>
                {selectedElement && selectedElement === element.id && (
                  <>
                    <div className='element__embed-drag-handle'>
                      <MoveIcon />
                    </div>
                    <div className='element__embed-drag-handle'>
                      <MoveIcon />
                    </div>
                    <div className='element__embed-drag-handle'>
                      <MoveIcon />
                    </div>
                    <div className='element__embed-drag-handle'>
                      <MoveIcon />
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </>
      )}
      {newCustomFormat && (
        <CustomFormatModal
          newCustomFormat={newCustomFormat}
          setNewCustomFormat={setNewCustomFormat}
          editorStyles={editorStyles}
          setEditorStyles={setEditorStyles}
          showTextEditor={showTextEditor}
          updateEditorStyles={updateEditorStyles}
          deleteEditorStyles={deleteEditorStyles}
        />
      )}
    </>
  );
};

export default Element;
