import { useState } from 'react'
import Modal from 'react-modal'

const DeleteDialog = ({message,setModalVisible,itemName, handleDelete}) => {
    const [deleteModalVisible,setDeleteModalVisible] = useState(true)
    return (
        <Modal
        isOpen={deleteModalVisible}
        onRequestClose={() => setDeleteModalVisible(false)}
        style={{
            overlay: {
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            },
            content: {
                position: 'relative',
                fontSize: "24px",
                color: "#ffff",
                padding: '24px',
                backgroundColor: '#3A405A',
                borderRadius: '8px',
                border: '1px solid rgba(44, 44, 44, 0.1)',
                boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.12), 0px 16px 32px rgba(0, 0, 0, 0.12)'
            }
        }}
        >
            <div>
                <h3>{message} {itemName}?</h3>
                <div style={{display: "flex",alignItems: "center",}}>
                    <button style={{borderRadius: "8px", border: "none",backgroundColor: "#2499F2", color:"#FFFFFF", boxShadow: "0px 2px 16px rgba(0, 0, 0, 0.08)",fontWeight: "700",fontSize:"20px", marginTop:"50px", letterSpacing:"0,04em", width:"40%", height:"40px", cursor: "pointer"}} onClick={() => {setModalVisible(false); handleDelete()}}>Yes</button>
                    <button style={{borderRadius: "8px", border: "none",backgroundColor: "#2499F2", color:"#FFFFFF", boxShadow: "0px 2px 16px rgba(0, 0, 0, 0.08)",fontWeight: "700",fontSize:"20px", marginTop:"50px", letterSpacing:"0,04em", width:"40%", height:"40px", marginLeft: "20%", cursor: "pointer"}}  onClick={() => setModalVisible(false)}>Cancel</button>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteDialog