import { ReactComponent as SuccessIcon } from '../../icons/not-success.svg';
import { ReactComponent as WarningIcon } from '../../icons/not-warning.svg';
import { ReactComponent as InfoIcon } from '../../icons/not-info.svg';
import { ReactComponent as ErrorIcon } from '../../icons/not-error.svg';
import CloseButton from './CloseButton';

export const notificationIcons = {
    close: <CloseButton />,
    success: <SuccessIcon />,
    info: <InfoIcon />,
    warning: <WarningIcon />,
    error: <ErrorIcon />
}
