export const calculatePositionAndSizeForElement = (elementForCalculations) => {
  const { element, screen } = elementForCalculations;
  const { width, height } = element;

  let elementW = 100;
  let elementH = 100;
  let elementX = 0;
  let elementY = 0;

  if (element?.translate) {
    elementX = element.translate[0];
    elementY = element.translate[1];
  }
  if (width) {
    elementW = width;
  }
  if (height) {
    elementH = height;
  }
  let elementToAdd = {
    desktop: {
      ...element,
      width: 0,
      height: 0,
      translate: [0, 0, 0]
    },
    laptop: {
      ...element,
      width: 0,
      height: 0,
      translate: [0, 0, 0]
    },
    tablet: {
      ...element,
      width: 0,
      height: 0,
      translate: [0, 0, 0]
    },
    mobile: {
      ...element,
      width: 0,
      height: 0,
      translate: [0, 0, 0]
    }
  };

  if (screen === 'desktop') {
    /* MAIN */
    const desktopWidth = Math.round(elementW);
    const desktopHeight = Math.round(elementH);
    const desktopElementX = elementW + elementX > 1920 ? 0 : Math.round(elementX);
    const desktopElementY = elementH + elementY > 1080 ? 0 : Math.round(elementY);

    const laptopWidth = Math.round(elementW * 0.75);
    const laptopHeight = Math.round(elementH * 0.75);
    const laptopElementX = elementW + elementX > 1440 ? 0 : Math.round(elementX * 0.75);
    const laptopElementY = elementH + elementY > 900 ? 0 : Math.round(elementY * 0.75);

    const tabletWidth = Math.round(elementW * 0.4);
    const tabletHeight = Math.round(elementH * 0.4);
    const tabletElementX = elementW + elementX > 768 ? 0 : Math.round(elementX * 0.4);
    const tabletElementY = elementH + elementY > 1024 ? 0 : Math.round(elementY * 0.4);

    const mobileWidth = Math.round(elementW * 0.19);
    const mobileHeight = Math.round(elementH * 0.19);
    const mobileElementX = elementW + elementX > 360 ? 0 : Math.round(elementX * 0.19);
    const mobileElementY = elementH + elementY > 640 ? 0 : Math.round(elementY * 0.19);

    elementToAdd = {
      desktop: {
        ...element,
        width: desktopWidth,
        height: desktopHeight,
        translate: [desktopElementX, desktopElementY, 0]
      },
      laptop: {
        ...element,
        width: laptopWidth,
        height: laptopHeight,
        translate: [laptopElementX, laptopElementY, 0]
      },
      tablet: {
        ...element,
        width: tabletWidth,
        height: tabletHeight,
        translate: [tabletElementX, tabletElementY, 0]
      },
      mobile: {
        ...element,
        width: mobileWidth,
        height: mobileHeight,
        translate: [mobileElementX, mobileElementY, 0]
      }
    };
  }

  if (screen === 'laptop') {
    const desktopWidth = Math.round(elementW * 1.2);
    const desktopHeight = Math.round(elementH * 1.2);
    const desktopElementX = elementW + elementX > 1920 ? 0 : Math.round(elementX * 1.2);
    const desktopElementY = elementH + elementY > 1080 ? 0 : Math.round(elementY * 1.2);

    /* MAIN */
    const laptopWidth = Math.round(elementW);
    const laptopHeight = Math.round(elementH);
    const laptopElementX = elementW + elementX > 1440 ? 0 : Math.round(elementX);
    const laptopElementY = elementH + elementY > 900 ? 0 : Math.round(elementY);

    const tabletWidth = Math.round(elementW * 0.53);
    const tabletHeight = Math.round(elementH * 0.53);
    const tabletElementX = elementW + elementX > 768 ? 0 : Math.round(elementX * 0.53);
    const tabletElementY = elementH + elementY > 1024 ? 0 : Math.round(elementY * 0.53);

    const mobileWidth = Math.round(elementW * 0.25);
    const mobileHeight = Math.round(elementH * 0.25);
    const mobileElementX = elementW + elementX > 360 ? 0 : Math.round(elementX * 0.25);
    const mobileElementY = elementH + elementY > 640 ? 0 : Math.round(elementY * 0.25);

    elementToAdd = {
      desktop: {
        ...element,
        width: desktopWidth,
        height: desktopHeight,
        translate: [desktopElementX, desktopElementY, 0]
      },
      laptop: {
        ...element,
        width: laptopWidth,
        height: laptopHeight,
        translate: [laptopElementX, laptopElementY, 0]
      },
      tablet: {
        ...element,
        width: tabletWidth,
        height: tabletHeight,
        translate: [tabletElementX, tabletElementY, 0]
      },
      mobile: {
        ...element,
        width: mobileWidth,
        height: mobileHeight,
        translate: [mobileElementX, mobileElementY, 0]
      }
    };
  }

  if (screen === 'tablet') {
    const desktopWidth = Math.round(elementW * 1.05);
    const desktopHeight = Math.round(elementH * 1.05);
    const desktopElementX = elementW + elementX > 1920 ? 0 : Math.round(elementX * 1.05);
    const desktopElementY = elementH + elementY > 1080 ? 0 : Math.round(elementY * 1.05);

    const laptopWidth = Math.round(elementW * 0.88);
    const laptopHeight = Math.round(elementH * 0.88);
    const laptopElementX = elementW + elementX > 1440 ? 0 : Math.round(elementX * 0.88);
    const laptopElementY = elementH + elementY > 900 ? 0 : Math.round(elementY * 0.88);

    /* MAIN */
    const tabletWidth = Math.round(elementW);
    const tabletHeight = Math.round(elementH);
    const tabletElementX = elementW + elementX > 768 ? 0 : Math.round(elementX);
    const tabletElementY = elementH + elementY > 1024 ? 0 : Math.round(elementY);

    const mobileWidth = Math.round(elementW * 0.42);
    const mobileHeight = Math.round(elementH * 0.42);
    const mobileElementX = elementW + elementX > 360 ? 0 : Math.round(elementX * 0.42);
    const mobileElementY = elementH + elementY > 640 ? 0 : Math.round(elementY * 0.42);

    elementToAdd = {
      desktop: {
        ...element,
        width: desktopWidth,
        height: desktopHeight,
        translate: [desktopElementX, desktopElementY, 0]
      },
      laptop: {
        ...element,
        width: laptopWidth,
        height: laptopHeight,
        translate: [laptopElementX, laptopElementY, 0]
      },
      tablet: {
        ...element,
        width: tabletWidth,
        height: tabletHeight,
        translate: [tabletElementX, tabletElementY, 0]
      },
      mobile: {
        ...element,
        width: mobileWidth,
        height: mobileHeight,
        translate: [mobileElementX, mobileElementY, 0]
      }
    };
  }

  if (screen === 'mobile') {
    const desktopWidth = Math.round(elementW * 1.69);
    const desktopHeight = Math.round(elementH * 1.69);
    const desktopElementX = elementW + elementX > 1920 ? 0 : Math.round(elementX * 1.69);
    const desktopElementY = elementH + elementY > 1080 ? 0 : Math.round(elementY * 1.69);

    const laptopWidth = Math.round(elementW * 1.41);
    const laptopHeight = Math.round(elementH * 1.41);
    const laptopElementX = elementW + elementX > 1440 ? 0 : Math.round(elementX * 1.41);
    const laptopElementY = elementH + elementY > 900 ? 0 : Math.round(elementY * 1.41);

    const tabletWidth = Math.round(elementW * 1.6);
    const tabletHeight = Math.round(elementH * 1.6);
    const tabletElementX = elementW + elementX > 768 ? 0 : Math.round(elementX * 1.6);
    const tabletElementY = elementH + elementY > 1024 ? 0 : Math.round(elementY * 1.6);

    /* MAIN */
    const mobileWidth = Math.round(elementW);
    const mobileHeight = Math.round(elementH);
    const mobileElementX = elementW + elementX > 360 ? 0 : Math.round(elementX);
    const mobileElementY = elementH + elementY > 640 ? 0 : Math.round(elementY);

    elementToAdd = {
      desktop: {
        ...element,
        width: desktopWidth,
        height: desktopHeight,
        translate: [desktopElementX, desktopElementY, 0]
      },
      laptop: {
        ...element,
        width: laptopWidth,
        height: laptopHeight,
        translate: [laptopElementX, laptopElementY, 0]
      },
      tablet: {
        ...element,
        width: tabletWidth,
        height: tabletHeight,
        translate: [tabletElementX, tabletElementY, 0]
      },
      mobile: {
        ...element,
        width: mobileWidth,
        height: mobileHeight,
        translate: [mobileElementX, mobileElementY, 0]
      }
    };
  }

  return elementToAdd;
};
