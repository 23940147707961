import  { ReactComponent as UploadIcon } from '../../icons/data-upload.svg'
import { ReactComponent as EditIcon } from '../../icons/edit.svg'
import defaultImage from '../../icons/default-image.png'
import { getNames } from 'country-list'
import { useState } from 'react'

const UserProfile = ({ register, handleSubmit, errors, isDirty, updateUserData, passwordChange, user, updateUserAvatar, image, removeAvatar, passwordChangeClicked }) => {
    const countries = getNames()

    const [disabledFirstName, setDisabledFirstName] = useState(true)
    const [disabledLastName, setDisabledLastName] = useState(true)
    const [disabledEmail, setDisabledEmail] = useState(true)
    // const [disabledFirstName, setDisabledFirstName] = useState(true)

    return (  
        <div
            className='user-account__section'
        >
            <div className="list-block">
                <p className="list-block__title">Profile</p>
                <div className="list-block__items">
                  <div className="avatar">
                    <div className="user-data__content">
                                <span className="user-data__label">Profile picture</span>
                                <img src={image ? image : defaultImage} className="user-data__profile-img" />
                        {image &&
                        <div>
                            <button className='onboarding__submit' style={{maxWidth: '30%'}} onClick={removeAvatar}>Remove</button>
                        </div>
                        }
                    </div>

                    <div>
                        <label
                            className='insert-menu__file-upload'
                        >
                            <span> <UploadIcon style={{ color: '' }} className="user-data__icon" /></span>
                            {/* <span>JPG / PNG</span>
                            <span>5MB max</span> */}
                            <input 
                                type="file" 
                                name="image" 
                                accept="image/png, image/jpeg"
                                onChange={(e) => updateUserAvatar(e)} style={{display: 'none'}}
                            />
                        </label>
                    </div>
                  </div>
                    <form 
                        className="user-data"
                        onSubmit={handleSubmit((data) => {
                            updateUserData(data.userData)
                        })}    
                    >
                        {/* <div className="user-data__row">
                            <div className="user-data__content">
                                <span className="user-data__label">Profile picture</span>
                                <img src={user.userAvatar ? user.userAvatar : defaultImage} className="user-data__profile-img" />
                            </div>
                            <UploadIcon className="user-data__icon" />
                        </div> */}
                        <div className="user-data__row">
                            <div className="user-data__content">
                                {/* <span><EditIcon /></span> */}
                                <span className="user-data__label">First Name</span>  
                                <input
                                    {...register('userData.firstName', { required: 'This field cannot be empty' })}
                                    type='text'
                                    className={errors.userData?.firstName ? 'user-data__field user-data__field--error' : 'user-data__field'}
                                    placeholder='First name'
                                    disabled={disabledFirstName}
                                />
                                {errors?.userData?.firstName?.message && 
                                    <span className="user-data__error-msg">{errors.userData.firstName.message}</span>
                                }
                                {/* <span className="user-data__field">{userData?.firstName}</span> */}
                            </div>
                            <EditIcon style={{ color: !disabledFirstName ? '#2499F2' : '' }}className="user-data__icon" onClick={() => {
                                if(disabledFirstName) {
                                    setDisabledFirstName(false)
                                } else if (!disabledFirstName) {
                                    setDisabledFirstName(true)
                                }
                            }} />
                        </div>
                        <div className="user-data__row">
                            <div className="user-data__content">
                                <span className="user-data__label">Last Name</span>
                                <input
                                    {...register('userData.lastName', { required: 'This field cannot be empty' })}
                                    type='text'
                                    className={errors.userData?.lastName ? 'user-data__field user-data__field--error' : 'user-data__field'}
                                    placeholder='Last name'
                                    disabled={disabledLastName}
                                />
                                {errors?.userData?.lastName?.message && 
                                    <span className="user-data__error-msg">{errors.userData.lastName.message}</span>
                                }
                                {/* <span className="user-data__field">{userData?.lastName}</span> */}
                            </div>
                            <EditIcon style={{ color: !disabledLastName ? '#2499F2' : '' }} className="user-data__icon" onClick={() => {
                                if(disabledLastName) {
                                    setDisabledLastName(false)
                                } else if (!disabledLastName) {
                                    setDisabledLastName(true)
                                }
                            }}/>
                        </div>
                        <div className="user-data__row">
                            <div className="user-data__content">
                                <span className="user-data__label">Email address</span>
                                <input
                                    {...register('userData.email', { 
                                        required: 'This field cannot be empty',
                                        pattern: {
                                            value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            message: 'Please enter a valid email'
                                        }
                                    })}
                                    type='text'
                                    className={errors.userData?.email ? 'user-data__field user-data__field--error' : 'user-data__field'}
                                    placeholder='email'
                                    disabled={disabledEmail}
                                />
                                {errors?.userData?.email?.message && 
                                    <span className="user-data__error-msg">{errors.userData.email.message}</span>
                                }
                                {/* <span className="user-data__field">{userData?.email}</span> */}
                            </div>
                            <EditIcon style={{ color: !disabledEmail ? '#2499F2' : '' }}className="user-data__icon" onClick={()=> {
                                if(disabledEmail) {
                                    setDisabledEmail(false)
                                } else if (!disabledEmail) {
                                    setDisabledEmail(true)
                                }
                                
                                }}/>
                        </div>
                        <div className="user-data__row">
                            <div className="user-data__content" >
                                <span className="user-data__label">Country</span>
                                <select
                                    {...register('userData.country', { required: 'This field cannot be empty' })}
                                    type='text'
                                    className={errors.userData?.country ? 'user-data__field user-data__field--error' : 'user-data__field'}
                                    placeholder='Country'
                                    
                                >
                                    <option value="" hidden>Country</option>
                                    {countries?.length > 0 && countries.map(country => (
                                        <option value={country} key={country}>{country}</option>
                                    ))}
                                </select>
                                {errors?.userData?.country?.message && 
                                    <span className="user-data__error-msg">{errors.userData.country.message}</span>
                                }
                                {/* <span className="user-data__field">{userData?.country}</span> */}
                            </div>
                            {/* <EditIcon className="user-data__icon" /> */}
                        </div>
                        {isDirty &&  <button className='onboarding__submit'>Submit changes</button>}
                    </form>
                    {!isDirty && <button className='onboarding__submit' style={{maxWidth: '30%', backgroundColor: passwordChangeClicked ? '#B0B3BD': '' }} onClick={passwordChange} disabled={passwordChangeClicked}>Change your password</button>}
                </div>
            </div>
        </div>
    );
}
 
export default UserProfile;