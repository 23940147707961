import { calculatePositionAndSizeForElement } from '../../utils/calculatePositionForElement';
import {
  SET_PRESENTATION,
  EDIT_PRESENTATION,
  EDIT_ELEMENT,
  ADD_ELEMENT,
  DELETE_ELEMENT,
  ADD_SLIDE,
  SET_LAYERS,
  EDIT_SLIDE_PROPERTY,
  DELETE_SLIDE,
  EDIT_ELEMENT_ON_ALL_SCREENS,
  UNDO,
  REDO
} from './actionTypes';

const presentationReducer = (state, action) => {
  switch (action.type) {
    case SET_PRESENTATION:
      return {
        presentation: action.payload,
        presentationHistory: [],
        presentationFuture: []
      };
    case EDIT_PRESENTATION:
      return {
        presentationHistory: [],
        presentationFuture: [],
        presentation: {
          ...state.presentation,
          data: {
            ...state.presentation.data,
            ...action.payload.updates
          }
        }
      };
    case EDIT_ELEMENT:
      return {
        presentationHistory: [...state.presentationHistory, state.presentation],
        presentationFuture: [],
        presentation: {
          ...state.presentation,
          data: {
            ...state.presentation.data,
            slides: state.presentation.data.slides.map((slide) => {
              if (slide.id === action.payload.slideId) {
                return {
                  ...slide,
                  [`${action.payload.screenSize}`]: slide[`${action.payload.screenSize}`].map(
                    (element) => {
                      if (element.id === action.payload.elementId) {
                        return {
                          ...element,
                          ...action.payload.updates
                        };
                      } else {
                        return element;
                      }
                    }
                  )
                };
              } else {
                return slide;
              }
            })
          }
        }
      };
    case EDIT_ELEMENT_ON_ALL_SCREENS:
      return {
        presentationHistory: [...state.presentationHistory, state.presentation],
        presentationFuture: [],
        presentation: {
          ...state.presentation,
          data: {
            ...state.presentation.data,
            slides: state.presentation.data.slides.map((slide) => {
              if (slide.id === action.payload.slideId) {
                return {
                  ...slide,
                  desktop: slide.desktop.map((element) => {
                    if (element.id === action.payload.elementId) {
                      return {
                        ...element,
                        ...action.payload.updates
                      };
                    } else {
                      return element;
                    }
                  }),
                  laptop: slide.laptop.map((element) => {
                    if (element.id === action.payload.elementId) {
                      return {
                        ...element,
                        ...action.payload.updates
                      };
                    } else {
                      return element;
                    }
                  }),
                  tablet: slide.tablet.map((element) => {
                    if (element.id === action.payload.elementId) {
                      return {
                        ...element,
                        ...action.payload.updates
                      };
                    } else {
                      return element;
                    }
                  }),
                  mobile: slide.mobile.map((element) => {
                    if (element.id === action.payload.elementId) {
                      return {
                        ...element,
                        ...action.payload.updates
                      };
                    } else {
                      return element;
                    }
                  })
                };
              } else {
                return slide;
              }
            })
          }
        }
      };
    case ADD_ELEMENT:
      return {
        presentationHistory: [...state.presentationHistory, state.presentation],
        presentationFuture: [],
        presentation: {
          ...state.presentation,
          data: {
            ...state.presentation.data,
            slides: state.presentation.data.slides.map((slide) => {
              if (slide.id === action.payload.slideId) {
                const elementForCalculations = {
                  element: action.payload.element,
                  screen: action.payload.screenSize
                };
                const elementToAdd = calculatePositionAndSizeForElement(elementForCalculations);

                if (action.payload.element.type !== 'text') {
                  const newSlide = {
                    ...slide,
                    desktop: [
                      ...slide.desktop,
                      {
                        ...action.payload.element,
                        ...elementToAdd.desktop,
                        isNew: null
                      }
                    ],
                    laptop: [
                      ...slide.laptop,
                      {
                        ...action.payload.element,
                        ...elementToAdd.laptop,
                        isNew: null
                      }
                    ],
                    tablet: [
                      ...slide.tablet,
                      {
                        ...action.payload.element,
                        ...elementToAdd.tablet,
                        isNew: null
                      }
                    ],
                    mobile: [
                      ...slide.mobile,
                      {
                        ...action.payload.element,
                        ...elementToAdd.mobile,
                        isNew: null
                      }
                    ]
                  };
                  return newSlide;
                } else {
                  const newSlide = {
                    ...slide,
                    desktop: [
                      ...slide.desktop,
                      {
                        ...action.payload.element,
                        isNew: null
                      }
                    ],
                    laptop: [
                      ...slide.laptop,
                      {
                        ...action.payload.element,
                        isNew: null
                      }
                    ],
                    tablet: [
                      ...slide.tablet,
                      {
                        ...action.payload.element,
                        isNew: null
                      }
                    ],
                    mobile: [
                      ...slide.mobile,
                      {
                        ...action.payload.element,
                        isNew: null
                      }
                    ]
                  };
                  return newSlide;
                }
              } else {
                return slide;
              }
            })
          }
        }
      };

    case DELETE_ELEMENT:
      return {
        presentationHistory: [...state.presentationHistory, state.presentation],
        presentationFuture: [],
        presentation: {
          ...state.presentation,
          data: {
            ...state.presentation.data,
            slides: state.presentation.data.slides.map((slide) => {
              if (slide.id === action.payload.slideId) {
                return {
                  ...slide,
                  desktop: slide.desktop.filter(
                    (element) => element.id !== action.payload.elementId
                  ),
                  laptop: slide.laptop.filter((element) => element.id !== action.payload.elementId),
                  tablet: slide.tablet.filter((element) => element.id !== action.payload.elementId),
                  mobile: slide.mobile.filter((element) => element.id !== action.payload.elementId)
                };
              } else {
                return slide;
              }
            })
          }
        }
      };

    case ADD_SLIDE:
      return {
        presentationHistory: [...state.presentationHistory, state.presentation],
        presentationFuture: [],
        presentation: {
          ...state.presentation,
          data: {
            ...state.presentation.data,
            slides: [...state.presentation.data.slides, action.payload.slide]
          }
        }
      };
    case SET_LAYERS:
      return {
        presentationHistory: [...state.presentationHistory, state.presentation],
        presentationFuture: [],
        presentation: {
          ...state.presentation,
          data: {
            ...state.presentation.data,
            slides: state.presentation.data.slides.map((slide) => {
              if (slide.id === action.payload.slideId) {
                return {
                  ...slide,
                  [`${action.payload.screenSize}`]: action.payload.layers
                };
              } else {
                return slide;
              }
            })
          }
        }
      };
    case EDIT_SLIDE_PROPERTY:
      return {
        presentationHistory: [...state.presentationHistory, state.presentation],
        presentationFuture: [],
        presentation: {
          ...state.presentation,
          data: {
            ...state.presentation.data,
            slides: state.presentation.data.slides.map((slide) => {
              if (slide.id === action.payload.slideId) {
                return {
                  ...slide,
                  ...action.payload.updates
                };
              } else {
                return slide;
              }
            })
          }
        }
      };
    case DELETE_SLIDE: {
      return {
        presentationHistory: [...state.presentationHistory, state.presentation],
        presentationFuture: [],
        presentation: {
          ...state.presentation,
          data: {
            ...state.presentation.data,
            slides: state.presentation.data.slides.filter(
              (slide) => slide.id !== action.payload.slideId
            )
          }
        }
      };
    }
    case UNDO:
      const oldHistory = [...state.presentationHistory];
      const newPresentForUndo = oldHistory.pop();

      return {
        presentationHistory: oldHistory,
        presentationFuture: [state.presentation, ...state.presentationFuture],
        presentation: newPresentForUndo
      };
    case REDO:
      const oldFuture = [...state.presentationFuture];
      const newPresentForRedo = oldFuture.shift();

      return {
        presentationHistory: [...state.presentationHistory, state.presentation],
        presentationFuture: oldFuture,
        presentation: newPresentForRedo
      };
    default:
      return state;
  }
};

export default presentationReducer;
