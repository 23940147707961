import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { SERVER_URL } from '../../url.config'
import  { ReactComponent as Logo } from '../../icons/logo-horizontal master.svg'
import  { ReactComponent as KeyIcon } from '../../icons/key-1.svg'
import HidePass from '../../icons/hide-pass.png'
import ShowPass from '../../icons/show-pass.png'
import { useState } from 'react';

const ResetPassword = () => {
    const [visible, setVisibility] = useState(false)
	const toggleBtn = () => {
		setVisibility(prevState => !prevState);
	}

    const navigate = useNavigate()
    const { passwordToken } = useParams()

    const {
		register,
		handleSubmit,
		formState: { errors }
	} = useForm()

    const sendNewPassword = async (data) => {
        try {
            await axios.post(`${SERVER_URL}/auth/reset-password`, { data: {...data, passwordToken} })
          // const user = {
          //   firstName: res.data.firstName,
          //   lastName: res.data.lastName,
          //   token: res.data.user,
          //   email: res.data.email,
          //   id: res.data.userId,
          //   stripeCustomerId: res.data.stripeCustomerId,
          //   role: res.data.role,
          // }
            sessionStorage.removeItem('user');
			// sessionStorage.setItem('user', JSON.stringify(user))
            navigate('/', { replace: true })
			// window.location.reload();
        } catch (err) {
        }
    }

    return (  
        <div className="onboarding">
            <div className="onboarding__form-wrap">
				<div className="onboarding__form-box">
                    <Logo className='onboarding__logo' />
                    <div className="onboarding__title">
                        <h3 className="onboarding__heading">Reset password</h3>
                        <p className="onboarding__subtitle">Enter your new password</p>
                    </div>
                    <form 
                        className="onboarding__form"
                        onSubmit={handleSubmit((data) => {
                            sendNewPassword(data)
                        })}
                    >
                        <div className="onboarding__form-row">
                            <input
                                {...register('password', { 
                                    required: 'This field cannot be empty', 
                                    minLength: {
                                        value: 6,
                                        message: 'Minimum length is 6 characters'
                                    }
                                })}
                                type={visible ? "text" : "password"}
                                className='onboarding__input'
                                placeholder='Password'
                            />
                            <div style={{position:'absolute', right:"5%", top: "30%"}} onClick={toggleBtn}>{visible && <img src={HidePass} width='20px'></img>} {!visible && <img src={ShowPass} width='20px'></img>}</div>
                            <KeyIcon className='onboarding__input-icon'/>
                            {errors.password?.message && 
                                <span className="onboarding__error-msg">{errors.password.message}</span>
                            }
                        </div>
                        <button className='onboarding__submit'>Proceed</button>
                    </form>
                </div>
            </div>
        </div>
    );
}
 
export default ResetPassword;